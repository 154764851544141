<template>
  <div class="user">
    <div class="photo">
      <img :src="avatar" alt="avatar" />
    </div>
    <div class="user-info">
      <a
        data-toggle="collapse"
        :aria-expanded="!isClosed"
        @click.stop="toggleMenu"
        @click.capture="clicked"
      >
        <span v-if="$route.meta.rtlActive">
          {{ rtlTitle }}
          <b class="caret"></b>
        </span>
        <span v-else>
          {{ title }}
          <b class="caret"></b>
        </span>
      </a>

      <collapse-transition>
        <div v-show="!isClosed">
          <ul class="nav">
            <slot>
              <li>
                <a href="#/pages/user">
                  <span class="sidebar-mini">MP</span>
                  <span class="sidebar-normal">Min Profil</span>
                </a>
              </li>
              <li>
                <a href="#" @click="logOut">
                  <span class="sidebar-mini">LU</span>
                  <span class="sidebar-normal">Logga Ut</span>
                </a>
              </li>
            </slot>
          </ul>
        </div>
      </collapse-transition>
    </div>
  </div>
</template>
<script>
import { CollapseTransition } from "vue2-transitions";

export default {
  components: {
    CollapseTransition,
  },
  props: {
    avatar: {
      type: String,
      default: "./img/faces/marc.jpg"
    },
  },
  computed: {
    title: function() {
      return this.$store.getters.firstName + " " + this.$store.getters.lastName;
    },
    rtlTitle: function() {
      return this.title;
    }
  },
  data() {
    return {
      isClosed: true,
    };
  },
  methods: {
    clicked: function (e) {
      e.preventDefault();
    },
    toggleMenu: function () {
      this.isClosed = !this.isClosed;
    },
    logOut() {
      this.$store.getters.keyCloak.logout();
    },
  },
};
</script>
<style>
.collapsed {
  transition: opacity 1s;
}
</style>
