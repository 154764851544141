<template>
  <div>
    <md-datepicker
      v-model="date"
      :md-model-type="String"
      :md-disabled-dates="disabledPickerDates"
      v-if="datepicker && !disabled"
      @input="updateDate"
    >
      <label>{{ label }}</label>
    </md-datepicker>
    <md-field
      :class="[
        { 'md-valid': !errors.has('date') && touched.date },
        { 'md-form-group': true },
        { 'md-error': errors.has('date') }
      ]"
      v-else
    >
      <md-icon>today</md-icon>
      <label>{{ label }}</label>
      <md-input
        :disabled="disabled"
        v-model="date"
        data-vv-name="date"
        type="text"
        name="date"
        v-validate="modelValidations.date"
        @input="updateDate"
      ></md-input>
      <slide-y-down-transition>
        <md-icon class="error" v-show="errors.has('date')">close</md-icon>
      </slide-y-down-transition>
      <slide-y-down-transition>
        <md-icon class="success" v-show="!errors.has('date') && touched.date">
          done</md-icon
        >
      </slide-y-down-transition>
    </md-field>
  </div>
</template>
<script>
import { SlideYDownTransition } from "vue2-transitions";
import moment from "moment";
export default {
  components: {
    SlideYDownTransition
  },
  props: {
    label: {
      type: String,
      required: true
    },
    initialDate: {
      required: true
    },
    disabled: {
      type: Boolean,
      required: false
    },
    datepicker: {
      type: Boolean,
      required: false
    },
    disabledPickerDates: {
      type: Function,
      required: false
    }
  },
  data() {
    return {
      date: this.initialDate,
      touched: {
        date: false
      },
      modelValidations: {
        date: {
          required: true,
          date: true
        }
      }
    };
  },
  created() {
    this.$validator.extend("date", {
      getMessage: field => `${field} is not a valid date.`,
      validate: value =>
        new Promise(resolve => {
          resolve({
            valid: value && this.isValidDate(value)
          });
        })
    });
  },
  watch: {
    date() {
      this.touched.date = true;
    },
    initialDate() {
      this.date = this.initialDate;
    }
  },
  methods: {
    isValidDate: function(d) {
      return moment.utc(d, "YYYY-MM-DD", true).isValid();
    },
    updateDate: function() {
      this.$emit("update:initialDate", this.date);
    }
  }
};
</script>
