var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper",class:[
    { 'nav-open': _vm.$sidebar.showSidebar },
    { rtl: _vm.$route.meta.rtlActive },
  ]},[_c('notifications'),_c('side-bar',{attrs:{"active-color":_vm.sidebarBackground,"background-image":_vm.sidebarBackgroundImage,"data-background-color":_vm.sidebarBackgroundColor}},[_c('user-menu'),_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{ name: 'Dashboard', icon: 'dashboard', path: '/dashboard' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Arrangemang', icon: 'event' },"v.if":"isAuthorizedToReadTour"}},[_c('sidebar-item',{attrs:{"link":{ name: 'Arrangemang', path: '/tour/list' }}}),(_vm.isAuthorizedToCreateTour)?_c('sidebar-item',{attrs:{"link":{ name: 'Planering', path: '/tour/plan' }}}):_vm._e()],1),(_vm.isAuthorizedToReadUsers)?_c('sidebar-item',{attrs:{"link":{ name: 'Medlem', icon: 'person' }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Ansökningar', path: '/member/application' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Sök Medlem', path: '/member/search' }}})],1):_vm._e(),(
          _vm.isAuthorizedToReadOrganizationLetter ||
            _vm.isAuthorizedToSendOrganizationLetter
        )?_c('sidebar-item',{attrs:{"link":{
          name: 'Föreningsbrev',
          icon: 'email',
          path: '/email/organization'
        }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
          name: 'Karta',
          icon: 'map',
          path: '/map/property'
        }}})],1)],2),_c('div',{staticClass:"main-panel"},[_c('top-navbar'),_c('div',{class:{ content: !_vm.$route.meta.hideContent },on:{"click":_vm.toggleSidebar}},[_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }