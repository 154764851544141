<template>
  <md-table class="arrangement-info-table">
    <md-table-row>
      <md-table-cell>Registrerade</md-table-cell>
      <md-table-cell>{{ requestedSlots }} st</md-table-cell>
    </md-table-row>
    <md-table-row>
      <md-table-cell>Reserverade</md-table-cell>
      <md-table-cell>{{ reservedSlots }} st</md-table-cell>
    </md-table-row>
    <md-table-row>
      <md-table-cell>Betalda</md-table-cell>
      <md-table-cell>{{ paidSlots }} st</md-table-cell>
    </md-table-row>
  </md-table>
</template>
<script>
export default {
  props: {
    requestedSlots: {
      type: Number,
      required: true
    },
    reservedSlots: {
      type: Number,
      required: true
    },
    paidSlots: {
      type: Number,
      required: true
    }
  }
};
</script>
<style lang="scss" scoped>
.arrangement-info-table {
  .md-table-row:hover,
  .md-table-row:focus {
    background: transparent !important;
  }

  .md-table-row:first-child .md-table-cell {
    border: 0;
  }
  .md-table-cell:nth-child(3) .md-table-cell-container {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
