<template>
  <div>
    <div class="md-layout">
      <div class="md-layout-item">
        <md-button
          class="md-success"
          @click.native="saveUser"
          :disabled="loading"
          v-if="isAuthorizedToWrite"
        >
          Spara
        </md-button>
      </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item md-medium-size-100">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>person</md-icon>
            </div>
          </md-card-header>

          <md-card-content :hidden="!loading">
            <div class="md-layout">
              <div class="md-layout-item text-center">
                <md-progress-spinner
                  md-mode="indeterminate"
                  :md-diameter="spinnerDiameter"
                  :md-stroke="spinnerStroke"
                ></md-progress-spinner>
              </div>
            </div>
          </md-card-content>
          <md-card-content :hidden="loading">
            <form class="form-horizontal" action="" method="post">
              <div class="md-layout">
                <label
                  class="md-layout-item md-size-15 md-xsmall-size-30 md-form-label"
                >
                  Förnamn
                </label>
                <div class="md-layout-item">
                  <md-field>
                    <md-input v-model="firstname" type="text"></md-input>
                  </md-field>
                </div>
              </div>
              <div class="md-layout">
                <label
                  class="md-layout-item md-size-15 md-xsmall-size-30 md-form-label"
                >
                  Efternamn
                </label>
                <div class="md-layout-item">
                  <md-field>
                    <md-input v-model="lastname" type="text"></md-input>
                  </md-field>
                </div>
              </div>
              <div class="md-layout">
                <label
                  class="md-layout-item md-size-15 md-xsmall-size-30 md-form-label"
                >
                  Födelsedag
                </label>
                <div class="md-layout-item">
                  <md-field>
                    <md-input v-model="birthday" type="text"></md-input>
                  </md-field>
                </div>
              </div>
              <div class="md-layout">
                <label
                  class="md-layout-item md-size-15 md-xsmall-size-30 md-form-label"
                >
                  E-post
                </label>
                <div class="md-layout-item">
                  <md-field>
                    <md-input v-model="email" type="email"></md-input>
                  </md-field>
                </div>
              </div>
            </form>
          </md-card-content>
        </md-card>
      </div>
      <div class="md-layout-item md-small-size-100">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>home</md-icon>
            </div>
          </md-card-header>

          <md-card-content :hidden="!loading">
            <div class="md-layout">
              <div class="md-layout-item text-center">
                <md-progress-spinner
                  md-mode="indeterminate"
                  :md-diameter="spinnerDiameter"
                  :md-stroke="spinnerStroke"
                ></md-progress-spinner>
              </div>
            </div>
          </md-card-content>
          <md-card-content :hidden="loading">
            <form class="form-horizontal" action="" method="post">
              <div class="md-layout">
                <label
                  class="md-layout-item md-size-15 md-xsmall-size-30 md-form-label"
                >
                  Land
                </label>
                <div class="md-layout-item">
                  <md-field>
                    <md-select v-model="country" name="country" id="country">
                      <md-option value="">Ej valt</md-option>
                      <md-option value="DK">Danmark</md-option>
                      <md-option value="FI">Finland</md-option>
                      <md-option value="NO">Norge</md-option>
                      <md-option value="SE">Sverige</md-option>
                      <md-option value="DE">Tyskland</md-option>
                    </md-select>
                  </md-field>
                </div>
              </div>
              <div class="md-layout">
                <label
                  class="md-layout-item md-size-15 md-xsmall-size-30 md-form-label"
                >
                  Län
                </label>
                <div class="md-layout-item">
                  <md-field>
                    <md-select v-model="county" name="county" id="county">
                      <md-option value="">Ej valt</md-option>
                      <md-option value="K">Blekinge</md-option>
                      <md-option value="W">Dalarna</md-option>
                      <md-option value="I">Gotland</md-option>
                      <md-option value="X">Gävleborg</md-option>
                      <md-option value="N">Halland</md-option>
                      <md-option value="Z">Jämtland</md-option>
                      <md-option value="F">Jönköping</md-option>
                      <md-option value="H">Kalmar</md-option>
                      <md-option value="G">Kronoberg</md-option>
                      <md-option value="BD">Norrbotten</md-option>
                      <md-option value="M">Skåne</md-option>
                      <md-option value="AB">Stockholm</md-option>
                      <md-option value="D">Södermanland</md-option>
                      <md-option value="C">Uppsala</md-option>
                      <md-option value="S">Värmland</md-option>
                      <md-option value="AC">Västerbotten</md-option>
                      <md-option value="Y">Västernorrland</md-option>
                      <md-option value="U">Västmanland</md-option>
                      <md-option value="O">Västra Götaland</md-option>
                      <md-option value="T">Örebro</md-option>
                      <md-option value="E">Östergötland</md-option>
                    </md-select>
                  </md-field>
                </div>
              </div>
            </form>
          </md-card-content>
        </md-card>
      </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item md-size-50 md-medium-size-100">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>assignment</md-icon>
            </div>
          </md-card-header>

          <md-card-content :hidden="!loading">
            <div class="md-layout">
              <div class="md-layout-item text-center">
                <md-progress-spinner
                  md-mode="indeterminate"
                  :md-diameter="spinnerDiameter"
                  :md-stroke="spinnerStroke"
                ></md-progress-spinner>
              </div>
            </div>
          </md-card-content>
          <md-card-content :hidden="loading">
            <form class="form-horizontal" action="" method="post">
              <div class="md-layout">
                <label
                  class="md-layout-item md-size-15 md-xsmall-size-30 md-form-label"
                >
                  Motivering
                </label>
                <div class="md-layout-item">
                  <md-field>
                    <md-textarea v-model="motivation" disabled></md-textarea>
                  </md-field>
                </div>
              </div>
              <div class="md-layout">
                <label
                  class="md-layout-item md-size-15 md-xsmall-size-30 md-form-label"
                >
                  Status
                </label>
                <div class="md-layout-item">
                  <div class="md-layout-item">
                    <md-field>
                      <md-select
                        v-model="accepted"
                        name="accepted"
                        id="accepted"
                      >
                        <md-option value="AWAITING">Väntande</md-option>
                        <md-option value="ACCEPTED">Godkänd</md-option>
                        <md-option value="REJECTED">Avvisad</md-option>
                      </md-select>
                    </md-field>
                  </div>
                </div>
              </div>
              <div class="md-layout" v-if="accepted === 'REJECTED'">
                <label
                  class="md-layout-item md-size-15 md-xsmall-size-30 md-form-label"
                >
                  Motivering för avvisning
                </label>
                <div class="md-layout-item">
                  <md-field>
                    <md-textarea v-model="rejectJustification"></md-textarea>
                  </md-field>
                </div>
              </div>
              <div class="md-layout">
                <label
                  class="md-layout-item md-size-15 md-xsmall-size-30 md-form-label"
                >
                  Poäng
                </label>
                <div class="md-layout-item">
                  <md-field>
                    <md-input
                      v-model="points"
                      type="number"
                      min="0"
                      max="100"
                    ></md-input>
                  </md-field>
                </div>
              </div>
              <div class="md-layout">
                <label
                  class="md-layout-item md-size-15 md-xsmall-size-30 md-form-label"
                >
                  IP
                </label>
                <div class="md-layout-item">
                  <md-field>
                    <md-input v-model="ipv4" type="text"></md-input>
                  </md-field>
                </div>
              </div>
              <div class="md-layout">
                <label
                  class="md-layout-item md-size-15 md-xsmall-size-30 md-form-label"
                >
                  Senast inloggad
                </label>
                <div class="md-layout-item">
                  <md-field>
                    <md-input
                      v-model="lastLogin"
                      type="text"
                      disabled
                    ></md-input>
                  </md-field>
                </div>
              </div>
            </form>
          </md-card-content>
        </md-card>
      </div>
    </div>
    <div class="md-layout" v-if="isAuthorizedToReadUserMail">
      <div class="md-layout-item md-size-100">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>mail</md-icon>
            </div>
          </md-card-header>
          <md-card-content>
            <member-mail
              :memberId="$route.params.id"
              @mailSelected="mailSelected"
            ></member-mail>
          </md-card-content>
        </md-card>
      </div>
    </div>
    <member-mail-modal
      ref="memberMailModal"
      v-if="isAuthorizedToReadUserMail"
    />
  </div>
</template>
<script>
import MemberMail from "./MemberMail";
import MemberMailModal from "./MemberMailModal";
export default {
  components: {
    MemberMail,
    MemberMailModal
  },
  mounted: function() {
    if (this.isAuthorizedToRead) {
      this.loadUser();
    } else {
      this.setError("Du har inte behörighet för denna sida!");
    }
  },
  data() {
    return {
      loading: true,
      spinnerDiameter: 60,
      spinnerStroke: 6,
      firstname: "",
      lastname: "",
      email: "",
      birthday: "",
      accepted: 0,
      motivation: "",
      country: "",
      county: "",
      points: 0,
      rejectJustification: "",
      ipv4: null,
      lastLogin: ""
    };
  },
  computed: {
    isAuthorizedToRead: function() {
      return this.$store.getters.roles.includes("user_read");
    },
    isAuthorizedToWrite: function() {
      return this.$store.getters.roles.includes("user_write");
    },
    isAuthorizedToReadUserMail: function() {
      return this.$store.getters.mailRoles.includes("read_user");
    }
  },
  methods: {
    updateUserData: function(response) {
      if (response.data) {
        let data = response.data;
        this.firstname = data.firstName;
        this.lastname = data.lastName;
        this.email = data.email;
        this.birthday = data.birthDate;
        this.motivation = data.motivation;
        this.country = data.country;
        this.county = data.county;
        this.points = data.points;
        this.rejectJustification = data.rejectJustification;
        this.ipv4 = data.ipv4;
        this.lastLogin = data.lastLogin;

        if (data.status == 0) {
          this.accepted = "AWAITING";
        } else if (data.status == 1) {
          this.accepted = "ACCEPTED";
        } else if (data.status == -1) {
          this.accepted = "REJECTED";
        }
      }
    },
    saveUser: function() {
      this.loading = true;
      this.axios
        .post("internal/subscribers/" + this.$route.params.id, {
          firstName: this.firstname,
          lastName: this.lastname,
          email: this.email,
          birthDate: this.birthday,
          status:
            this.accepted == "ACCEPTED"
              ? 1
              : this.accepted == "AWAITING"
              ? 0
              : -1,
          motivation: this.motivation,
          country: this.country,
          county: this.county,
          points: this.points,
          rejectJustification: this.rejectJustification
        })
        .then(response => {
          this.updateUserData(response);
          this.loading = false;
          this.setSuccess("Medlem har uppdaterats!");
        })
        .catch(error => {
          if (error.response.data.firstname) {
            this.setError("Felaktigt förnamn");
          } else if (error.response.data.lastname) {
            this.setError("Felaktigt efternamn");
          } else if (error.response.data.email) {
            this.setError("Felaktig e-post");
          } else if (error.response.data.birthday) {
            this.setError("Felaktig födelsedag");
          } else if (error.response.data.accepted) {
            this.setError("Felaktig status");
          } else if (error.response.data.motivation) {
            this.setError("Felaktig motivation");
          } else if (error.response.data.country) {
            this.setError("Felaktigt land");
          } else if (error.response.data.county) {
            this.setError("Felaktigt län");
          } else if (error.response.data.points) {
            this.setError("Felaktiga poäng");
          } else if (error.response.data.rejectJustification) {
            this.setError("Felaktig motivering för avvisning");
          } else {
            this.setError("Något gick fel");
          }
          this.loading = false;
        });
    },
    loadUser: function() {
      this.loading = true;
      this.axios
        .get("internal/subscribers/" + this.$route.params.id)
        .then(response => {
          this.updateUserData(response);
          this.loading = false;
        })
        .catch(() => {
          this.setError("Något gick fel!");
          this.loading = false;
        });
    },
    mailSelected: function(mail) {
      this.$refs.memberMailModal.showDialog(mail.subject, mail.content);
    },
    setError: function(text) {
      this.$notify({
        message: text,
        icon: "error",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "danger",
        timeout: 5000
      });
    },
    setSuccess: function(text) {
      this.$notify({
        message: text,
        icon: "check_circle",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "success",
        timeout: 5000
      });
    }
  }
};
</script>
<style scoped>
button.md-button {
  float: right;
}
</style>
