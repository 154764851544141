var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{staticClass:"form-horizontal",attrs:{"action":"","method":"post"}},[_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item"},[_c('md-field',{class:[
          { 'md-error': _vm.errors.has('rafflingDateOpen') },
          {
            'md-valid':
              !_vm.errors.has('rafflingDateOpen') && _vm.touched.rafflingDateOpen
          }
        ]},[_c('label',[_vm._v("Startdatum")]),_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.modelValidations.rafflingDateOpen),expression:"modelValidations.rafflingDateOpen"}],attrs:{"data-vv-name":"rafflingDateOpen","type":"text","required":"","disabled":!_vm.isAuthorizedToWrite},on:{"input":_vm.updateRafflingDateOpen},model:{value:(_vm.rafflingDateOpen),callback:function ($$v) {_vm.rafflingDateOpen=$$v},expression:"rafflingDateOpen"}}),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('rafflingDateOpen')),expression:"errors.has('rafflingDateOpen')"}],staticClass:"error"},[_vm._v(" close")])],1),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(
              !_vm.errors.has('rafflingDateOpen') && _vm.touched.rafflingDateOpen
            ),expression:"\n              !errors.has('rafflingDateOpen') && touched.rafflingDateOpen\n            "}],staticClass:"success"},[_vm._v(" done")])],1)],1)],1)]),_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item"},[_c('md-field',{class:[
          { 'md-error': _vm.errors.has('rafflingDateClose') },
          {
            'md-valid':
              !_vm.errors.has('rafflingDateClose') && _vm.touched.rafflingDateClose
          }
        ]},[_c('label',[_vm._v("Slutdatum")]),_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.modelValidations.rafflingDateClose),expression:"modelValidations.rafflingDateClose"}],attrs:{"data-vv-name":"rafflingDateClose","type":"text","required":"","disabled":!_vm.isAuthorizedToWrite},on:{"input":_vm.updateRafflingDateClose},model:{value:(_vm.rafflingDateClose),callback:function ($$v) {_vm.rafflingDateClose=$$v},expression:"rafflingDateClose"}}),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('rafflingDateClose')),expression:"errors.has('rafflingDateClose')"}],staticClass:"error"},[_vm._v("close")])],1),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(
              !_vm.errors.has('rafflingDateClose') && _vm.touched.rafflingDateClose
            ),expression:"\n              !errors.has('rafflingDateClose') && touched.rafflingDateClose\n            "}],staticClass:"success"},[_vm._v(" done")])],1)],1)],1)]),_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item"},[_c('p',[_vm._v(" Startdatum rekommenderas vara minst 7 dagar efter publicering av arrangemanget för att lämna tid för anmälan. Slutdatum rekommenderas vara 2 dagar innan arrangemanget, d.v.s. "+_vm._s(_vm.recommendedRafflingDateClose)+". ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }