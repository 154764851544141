<template>
  <modal @close="modalHide">
    <template slot="header">
      <h4 class="modal-title">{{ firstname }} {{ lastname }}</h4>
      <md-button
        class="md-simple md-just-icon md-round modal-default-button"
        @click="modalHide"
      >
        <md-icon>clear</md-icon>
      </md-button>
    </template>

    <template slot="body">
      <div v-show="loadingModal">
        <p>
          <md-progress-spinner
            md-mode="indeterminate"
            :md-diameter="spinnerDiameter"
            :md-stroke="spinnerStroke"
          ></md-progress-spinner>
        </p>
      </div>
      <div v-show="!loadingModal">
        <md-table v-if="!ssn">
          <md-table-row>
            <md-table-cell>Födelsedag</md-table-cell>
            <md-table-cell
              >{{ birthday }} ({{ birthday | yearsAgo }} år)</md-table-cell
            >
          </md-table-row>
          <!--<md-table-row>
            <md-table-cell>E-post</md-table-cell>
            <md-table-cell
              >{{ email }}
              <md-icon v-if="emailValid == 'OK'" class="success"
                >check_circle
              </md-icon>
              <md-icon v-else-if="emailValid == 'NOK'" class="danger"
                >clear
              </md-icon>
              <md-progress-spinner
                v-else
                md-mode="indeterminate"
                :md-diameter="15"
                :md-stroke="2"
              ></md-progress-spinner>
            </md-table-cell>
          </md-table-row>-->
        </md-table>
        <md-table v-else>
          <md-table-row>
            <md-table-cell>Födelsedag</md-table-cell>
            <md-table-cell>{{ ssn.date }} ({{ ssn.age }} år)</md-table-cell>
          </md-table-row>
          <md-table-row>
            <md-table-cell>Personnummer</md-table-cell>
            <md-table-cell
              >{{ ssn.normalised }}
              <md-icon v-if="ssn.valid" class="success">check_circle</md-icon>
              <md-icon v-else class="danger">clear</md-icon>
            </md-table-cell>
          </md-table-row>
          <md-table-row>
            <md-table-cell>Kön</md-table-cell>
            <md-table-cell>{{ ssn.gender }}</md-table-cell>
          </md-table-row>
          <md-table-row>
            <md-table-cell>E-post</md-table-cell>
            <md-table-cell
              >{{ email }}
              <!--<md-icon v-if="emailValid == 'OK'" class="success"
                >check_circle
              </md-icon>
              <md-icon v-else-if="emailValid == 'NOK'" class="danger"
                >clear
              </md-icon>
              <md-icon v-else>loop</md-icon>-->
            </md-table-cell>
          </md-table-row>
          <md-table-row v-if="ipv4">
            <md-table-cell>IP</md-table-cell>
            <md-table-cell>{{ ipv4 }}</md-table-cell>
          </md-table-row>
          <md-table-row>
            <md-table-cell>Land</md-table-cell>
            <md-table-cell>{{ country }}</md-table-cell>
          </md-table-row>
          <md-table-row>
            <md-table-cell>Län</md-table-cell>
            <md-table-cell>{{ county }}</md-table-cell>
          </md-table-row>
        </md-table>
        <br />
        <p><b>Motivering:</b> {{ motivation }}</p>
        <p>
          <md-button
            class="md-just-icon md-facebook"
            :href="facebookUrl"
            target="_blank"
          >
            <i class="fab fa-facebook"></i>
          </md-button>
          &nbsp;
          <md-button
            class="md-just-icon md-success"
            :href="merInfo"
            target="_blank"
          >
            <md-icon>search</md-icon>
          </md-button>
        </p>
      </div>
    </template>

    <template slot="footer">
      <md-button class="md-danger md-simple" @click="modalHide"
        >Stäng</md-button
      >
    </template>
  </modal>
</template>
<script>
import { Modal } from "@/components";
import moment from "moment";
const { getCountryTools } = require("verifiera");
export default {
  components: {
    Modal
  },
  props: {
    id: {
      type: Number,
      default: -1
    }
  },
  data() {
    return {
      loadingModal: true,
      spinnerDiameter: 60,
      spinnerStroke: 6,
      countries: {
        DK: "Danmark",
        FI: "Finland",
        NO: "Norge",
        SE: "Sverige",
        DE: "Tyskland"
      },
      counties: {
        K: "Blekinge",
        W: "Dalarna",
        I: "Gotland",
        X: "Gävleborg",
        N: "Halland",
        Z: "Jämtland",
        F: "Jönköping",
        H: "Kalmar",
        G: "Kronoberg",
        BD: "Norrbotten",
        M: "Skåne",
        AB: "Stockholm",
        D: "Södermanland",
        C: "Uppsala",
        S: "Värmland",
        AC: "Västerbotten",
        Y: "Västernorrland",
        U: "Västmanland",
        O: "Västra Götaland",
        T: "Örebro",
        E: "Östergötland"
      },
      firstname: "",
      lastname: "",
      email: "",
      emailValid: "LOADING",
      birthday: false,
      ssn: false,
      motivation: "",
      ipv4: null,
      country: "",
      county: "",
      merInfo: "",
      facebookUrl: ""
    };
  },
  filters: {
    yearsAgo: function(value) {
      return moment().diff(moment.utc(value), "years");
    }
  },
  mounted: function() {
    this.modalShow();
  },
  methods: {
    moment: function() {
      return moment;
    },
    setSsn: function(val) {
      if (moment(val, "MM/DD/YYYY", true).isValid()) {
        this.birthday = moment.utc(val, "MM/DD/YYYY").format("YYYY-MM-DD");
      } else {
        const tempSsn = getCountryTools(val);
        this.ssn = {
          valid: tempSsn.validate(),
          normalised: tempSsn.getCensored(),
          gender: tempSsn.getGender() == "m" ? "Man" : "Kvinna",
          date: tempSsn.getBirthday(),
          age: tempSsn.getAge()
        };
        if (!this.ssn.valid) {
          this.ssn.normalised = val;
          this.ssn.gender = "?";
          this.ssn.date = val;
          this.ssn.age = "?";
        }
      }
    },
    modalHide: function() {
      this.$emit("hide");
    },
    modalShow: function() {
      this.loadingModal = true;
      this.axios
        .get("internal/subscribers/" + this.id)
        .then(response => {
          let data = response.data;
          this.firstname = data.firstName;
          this.lastname = data.lastName;
          this.email = data.email;
          //this.ssn = personnummer.parse(data.birthday);
          //this.birthday = data.birthday;
          this.motivation = data.motivation;
          this.country = data.country
            ? this.countries[data.country]
            : "";
          this.county = data.county
            ? this.counties[data.county]
            : "";
          this.ipv4 = data.ipv4;
          this.merInfo =
            "https://www.merinfo.se/search?who=" + data.birthDate;
          this.facebookUrl =
            "https://www.facebook.com/search/top/?q=" +
            data.firstName +
            ", " +
            data.lastName;
          this.setSsn(data.birthDate);
          //this.validateEmail();
          this.loadingModal = false;
        })
        .catch(() => {
          this.setError("Något gick fel");
          this.modalHide();
          this.loadingModal = false;
        });
    },
    validateEmail: function() {
      this.axios
        .post("https://dashboard.svbefs.se/services/mail/api/mail/validate", {
          email: this.email
        })
        .then(response => {
          if (response.data.result == "DELIVERABLE") {
            this.emailValid = "OK";
          } else {
            this.emailValid = "NOK";
          }
        });
    },
    setError: function(text) {
      this.$notify({
        message: text,
        icon: "error",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "danger",
        timeout: 5000
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.success {
  color: #4caf50 !important;
}
.danger {
  color: #f44336 !important;
}
</style>
