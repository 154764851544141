<template>
  <div>
    <div class="md-layout">
      <label class="md-layout-item md-form-label">
        JSON-lista med koordinater
      </label>
      <div class="md-layout-item">
        <md-field>
          <md-textarea v-model="jsonText"></md-textarea>
        </md-field>
      </div>
      <div class="md-layout-item">
        <md-field>
          <label>Index</label>
          <md-input v-model="index" type="number"></md-input>
        </md-field>
      </div>
    </div>
    <div class="md-layout" v-if="isCurrentEntrySet">
      <div class="md-layout-item">Nuvarande: {{ currentEntry.name }}</div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item">
        <md-button class="md-success" @click="read">
          Läs in
        </md-button>
      </div>
      <div class="md-layout-item">
        <md-button class="md-success" @click="next" :disabled="!canDoNext">
          Nästa
        </md-button>
      </div>
      <div class="md-layout-item">
        <md-button
          class="md-success"
          @click="create"
          :disabled="!isCurrentEntrySet"
        >
          Skapa
        </md-button>
      </div>
      <div class="md-layout-item">
        <md-button class="md-warning" @click="reset">
          Återställ
        </md-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      jsonText: "",
      json: [],
      currentEntry: {},
      index: 0
    };
  },
  methods: {
    isJson(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
    read() {
      let json = [];
      try {
        json = JSON.parse(this.jsonText);
      } catch (e) {
        this.$errorNotice("Kunde inte läsa JSON");
        return;
      }
      this.json = json;
      this.setEntry();
    },
    next() {
      this.index++;
      this.setEntry();
    },
    create() {
      this.$emit("create");
    },
    setEntry() {
      this.currentEntry = this.json[this.index];
      this.$emit("change", this.currentEntry.lat, this.currentEntry.lng);
    },
    reset() {
      this.index = 0;
      this.jsonText = "";
    }
  },
  computed: {
    isCurrentEntrySet() {
      return Object.keys(this.currentEntry).length > 0;
    },
    canDoNext() {
      return this.index < this.json.length - 1;
    }
  }
};
</script>
<style lang="scss" scoped></style>
