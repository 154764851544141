<template>
  <div>
    <div class="md-layout">
      <div class="md-layout-item">
        <md-checkbox v-model="showSecretCopy">Visa dolda</md-checkbox>
      </div>
    </div>
    <div class="md-layout marginTop">
      <div class="md-layout-item">
        <md-field>
          <label>Nyckel</label>
          <md-input v-model="encryptionKeyCopy" type="password"></md-input>
        </md-field>
      </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item">
        <md-button
          class="md-success"
          :disabled="loading"
          @click.native="reload"
        >
          Ladda om
        </md-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    encryptionKey: {
      type: String,
      default: ""
    },
    showSecret: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      encryptionKeyCopy: this.encryptionKey,
      showSecretCopy: this.showSecret
    };
  },
  watch: {
    encryptionKeyCopy() {
      this.$emit("update:encryptionKey", this.encryptionKeyCopy);
    },
    showSecretCopy() {
      this.$emit("update:showSecret", this.showSecretCopy);
    }
  },
  methods: {
    reload() {
      this.$emit("reload");
    }
  }
};
</script>
<style lang="scss" scoped></style>
