<template>
  <div>
    <div class="md-layout" v-if="disableStep">
      <div class="md-layout-item">
        <p>
          <b class="text-danger">Låst steg!</b> Du behöver fylla i övriga steg
          för att låsa upp detta.
        </p>
      </div>
    </div>
    <div class="md-layout" v-blur="disableStep">
      <div class="md-layout-item">
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <p>
              Medlemmar i Svenska Befästningssällskapet ska frågas om de vill ha
              följa med på arrangemanget. Svar ska skickas med e-post till
              huvudarrangören. Efter max 3 dagar sätts antalet medföljande som
              avgör antalet bokningsbara platser och priset, därefter kan inte
              rabatterat pris garanteras. Datum för förfrågan anges per
              automatik när förfrågan skickas.
            </p>
          </div>
          <div class="md-layout-item md-size-60 md-small-size-100">
            <date-field
              :initialDate.sync="date"
              label="Datum för förfrågan"
              :disabled="true"
            />
          </div>
          <div class="md-layout-item md-size-40 md-small-size-100">
            <p>Efter {{ endDate }} fastställs antal medföljande</p>
          </div>
          <div
            class="md-layout-item md-size-60 md-small-size-100"
            v-blur="!isRequestSent"
          >
            <text-field
              :initialTextval.sync="sumPeople"
              label="Antal medföljande (interna)"
              icon="people"
              :validationConditions="sumPeopleValidation"
              :disabled="disableStep || !isRequestSent"
            />
          </div>
        </div>
      </div>
      <div class="md-layout-item md-size-100">
        <md-button
          class="md-success"
          :disabled="isRequestSent || disableStep"
          @click="sendRequest()"
        >
          Skicka förfrågan
        </md-button>
      </div>
      <div class="md-layout-item md-size-100">
        <md-progress-bar
          :class="completionClass"
          :md-value="completionScore"
          :disabled="disableStep"
        ></md-progress-bar>
      </div>
    </div>
  </div>
</template>
<script>
import DateField from "@/pages/Dashboard/Components/DateField.vue";
import TextField from "@/pages/Dashboard/Components/TextField.vue";
import moment from "moment";
export default {
  components: {
    DateField,
    TextField
  },
  props: {
    initialDate: {
      required: true
    },
    initialSumPeople: {
      type: String,
      required: true
    },
    disableStep: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      date: this.initialDate,
      sumPeople: this.initialSumPeople,
      sumPeopleValidation: {
        required: true,
        numeric: true,
        min_value: 0
      }
    };
  },
  watch: {
    date() {
      this.updateDate();
    },
    sumPeople() {
      this.updateSumPeople();
    }
  },
  methods: {
    isValidDate: function(d) {
      return moment.utc(d, "YYYY-MM-DD", true).isValid();
    },
    updateDate: function() {
      this.$emit("update:initialDate", this.date);
    },
    updateSumPeople: function() {
      this.$emit("update:initialSumPeople", this.sumPeople);
    },
    sendRequest: function() {
      this.date = moment.utc().format("YYYY-MM-DD");
      this.setInfo(
        "Tänk på att du behöver spara planeringen för att förfrågan ska skickas iväg"
      );
    },
    setInfo: function(text) {
      this.$notify({
        message: text,
        icon: "info",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "primary",
        timeout: 10000
      });
    }
  },
  computed: {
    endDate: function() {
      if (!this.isValidDate(this.date)) {
        return " ...";
      }
      return moment
        .utc(this.date)
        .add(3, "days")
        .format("YYYY-MM-DD");
    },
    completionScore: function() {
      let score = 0;
      if (this.date) {
        score += 100 / 2;
      }
      if (this.sumPeople) {
        score += 100 / 2;
      }
      this.$emit("score", score);
      return score;
    },
    completionClass: function() {
      if (this.completionScore < 50) {
        return "md-danger";
      }
      if (this.completionScore < 100) {
        return "md-warning";
      }
      return "md-success";
    },
    isRequestSent: function() {
      return this.date != null && this.date.length > 0;
    }
  }
};
</script>
