<template>
  <div>
    <div :hidden="!loading">
      <div class="md-layout">
        <div class="md-layout-item text-center">
          <md-progress-spinner
            md-mode="indeterminate"
            :md-diameter="spinnerDiameter"
            :md-stroke="spinnerStroke"
          ></md-progress-spinner>
        </div>
      </div>
    </div>
    <div :hidden="loading">
      <div class="md-layout">
        <div class="md-layout-item">
          <md-field>
            <label>Ämne</label>
            <md-input v-model="mailSubject" type="text" required></md-input>
          </md-field>
        </div>
      </div>
      <div class="md-layout">
        <div class="md-layout-item">
          <vue-editor
            v-model="mailHtmlContent"
            :editorToolbar="mailCustomToolbar"
          ></vue-editor>
        </div>
      </div>
      <div class="md-layout">
        <div class="md-layout-item">
          <p>
            Obs! <b>%recipient.firstname%</b> ersätts av systemet med
            mottagarens namn.
          </p>
        </div>
      </div>
      <div class="md-layout">
        <div class="md-layout-item">
          <md-checkbox v-model="mailConfirmed">Bekräfta Utskick</md-checkbox>
        </div>
      </div>
      <div class="md-layout">
        <div class="md-layout-item">
          <md-button
            class="md-success"
            :disabled="!mailConfirmed"
            @click.native="sendMail"
          >
            Skicka
          </md-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { VueEditor } from "vue2-editor";
const { convert } = require('html-to-text');
export default {
  components: {
    VueEditor
  },
  data() {
    return {
      spinnerDiameter: 60,
      spinnerStroke: 6,
      loading: false,
      mailConfirmed: false,
      mailSubject: "Information",
      mailHtmlContent:
        '<p>Hej %recipient.firstname%,</p><p><br></p><p>Det börjar nu bli dags ...</p><p><br></p><p>Med vänliga hälsningar, </p><p>Organisationen Fortifikation-Väst</p><p><br></p><p>Hemsida: <a href="www.fortifikationvast.se">www.fortifikationvast.se</a></p><p>Facebook: <a href="www.facebook.com/fortifikationvast">www.facebook.com/fortifikationvast</a></p><p>Instagram: <a href="www.instagram.com/fortifikationvast.se">www.instagram.com/fortifikationvast.se</a></p>',
      mailCustomToolbar: [
        ["bold", "italic", "underline", "link"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ color: [] }],
        ["clean"]
      ]
    };
  },
  methods: {
    mailTextContent: function() {
      return convert(this.mailHtmlContent, {
        wordwrap: 130
      });
    },
    sendMail: function() {
      this.loading = true;
      this.axios
        .post(
          "https://dashboard.svbefs.se/services/mail/api/mail/batch/global",
          {
            subject: this.mailSubject,
            html: this.mailHtmlContent,
            text: this.mailTextContent()
          }
        )
        .then(() => {
          this.mailConfirmed = false;
          this.loading = false;
          this.setSuccess("Mejlet har skickats!");
          this.$emit("mailSent");
        })
        .catch(() => {
          this.mailConfirmed = false;
          this.loading = false;
          this.setError("Kunde inte skicka mejlet");
        });
    },
    setError: function(text) {
      this.$notify({
        message: text,
        icon: "error",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "danger",
        timeout: 5000
      });
    },
    setSuccess: function(text) {
      this.$notify({
        message: text,
        icon: "check_circle",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "success",
        timeout: 5000
      });
    }
  }
};
</script>
