<template>
  <div>
    <div class="md-layout" v-if="isAuthorizedToRead">
      <div class="md-layout-item md-size-100">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>mail</md-icon>
            </div>
          </md-card-header>
          <md-card-content>
            <organization-letter-list
              ref="organizationLetterList"
              @mailSelected="mailSelected"
            ></organization-letter-list>
          </md-card-content>
        </md-card>
      </div>
    </div>
    <div class="md-layout" v-if="isAuthorizedToSend">
      <div class="md-layout-item md-size-100">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>create</md-icon>
            </div>
          </md-card-header>
          <md-card-content>
            <organization-letter-send
              ref="organizationLetterSend"
              @mailSent="mailSent"
            ></organization-letter-send>
          </md-card-content>
        </md-card>
      </div>
    </div>
    <organization-letter-modal
      ref="organizationLetterModal"
      v-if="isAuthorizedToRead"
    />
  </div>
</template>
<script>
import OrganizationLetterModal from "./OrganizationLetter/OrganizationLetterModal";
import OrganizationLetterSend from "./OrganizationLetter/OrganizationLetterSend";
import OrganizationLetterList from "./OrganizationLetter/OrganizationLetterList";
export default {
  components: {
    OrganizationLetterModal,
    OrganizationLetterSend,
    OrganizationLetterList
  },
  computed: {
    isAuthorizedToRead: function() {
      return this.$store.getters.mailRoles.includes("read_global");
    },
    isAuthorizedToSend: function() {
      return this.$store.getters.mailRoles.includes("send_global");
    }
  },
  methods: {
    mailSent: function() {
      this.$refs.organizationLetterList.loadData();
    },
    mailSelected: function(mail) {
      this.$refs.organizationLetterModal.showDialog(
        mail.subject,
        mail.contentHtml
      );
    }
  }
};
</script>
