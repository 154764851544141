<template>
  <md-dialog
    :md-active="show"
    :md-close-on-esc="true"
    :md-click-outside-to-close="true"
  >
    <md-dialog-title>Synka mejl</md-dialog-title>
    <md-dialog-content :hidden="!loading">
      <div class="md-layout">
        <div class="md-layout-item text-center">
          <md-progress-spinner
            md-mode="indeterminate"
            :md-diameter="spinnerDiameter"
            :md-stroke="spinnerStroke"
          ></md-progress-spinner>
        </div>
      </div>
    </md-dialog-content>
    <md-dialog-content :hidden="loading">
      <div class="md-layout text-center">
        <div class="md-layout-item">
          <p>
            Sykning innebär att mejlet kommer skickas till de som ännu inte fått
            mejlet men skulle fått det om mejlet skickades vid tiden för
            synkningen. Det är användbart om listan för mottagare utökats efter
            att mejlet skickats. Observera att alla mejl inte är relevanta för
            synkning, t.ex. om de är tidberoende.
          </p>
        </div>
      </div>
      <div class="md-layout text-center">
        <div class="md-layout-item">
          <md-checkbox v-model="unlocked">Aktivera knapp</md-checkbox>
        </div>
      </div>
      <div class="md-layout text-center">
        <div class="md-layout-item">
          <md-button class="md-warning" :disabled="!unlocked" @click="syncMail"
            >Synka mejl</md-button
          >
        </div>
      </div>
    </md-dialog-content>
    <md-dialog-actions>
      <md-button class="md-danger md-simple" @click="hideDialog()"
        >Stäng</md-button
      >
    </md-dialog-actions>
  </md-dialog>
</template>
<script>
export default {
  data: function() {
    return {
      show: false,
      arrangement: {},
      mail: {},
      unlocked: false,
      loading: false,
      spinnerDiameter: 40,
      spinnerStroke: 4
    };
  },
  methods: {
    showDialog: function(arrangement, mail) {
      this.arrangement = arrangement;
      this.mail = mail;
      this.show = true;
    },
    hideDialog: function() {
      this.show = false;
    },
    syncMail: function() {
      this.loading = true;
      this.axios
        .post(
          "https://dashboard.svbefs.se/services/mail/api/mail/batch/arrangement/" +
            this.arrangement.id +
            "/mail/" +
            this.mail.id +
            "/validate"
        )
        .then(() => {
          this.unlocked = false;
          this.loading = false;
          this.setSuccess("Mejlet har synkats!");
          this.hideDialog();
        })
        .catch(() => {
          this.unlocked = false;
          this.loading = false;
          this.setError("Kunde inte synka mejlet");
          this.$emit("mailSynced", this.mail.id);
        });
    },
    setError: function(text) {
      this.$notify({
        message: text,
        icon: "error",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "danger",
        timeout: 5000
      });
    },
    setSuccess: function(text) {
      this.$notify({
        message: text,
        icon: "check_circle",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "success",
        timeout: 5000
      });
    }
  }
};
</script>
