<template>
  <div>
    <div :hidden="!loading">
      <div class="md-layout">
        <div class="md-layout-item text-center">
          <md-progress-spinner
            md-mode="indeterminate"
            :md-diameter="spinnerDiameter"
            :md-stroke="spinnerStroke"
          ></md-progress-spinner>
        </div>
      </div>
    </div>
    <div :hidden="loading">
      <md-table :hidden="data.length < 1">
        <md-table-row>
          <md-table-head class="md-small-hide">Datum</md-table-head>
          <md-table-head>Kategori</md-table-head>
          <md-table-head>Ämne</md-table-head>
          <md-table-head>Status</md-table-head>
          <md-table-head class="text-right"></md-table-head>
        </md-table-row>
        <md-table-row v-for="item in data" v-bind:key="item.id">
          <md-table-cell class="md-small-hide">
            {{ item.createdOn | formatDate }}
          </md-table-cell>
          <md-table-cell>
            {{ item.arrangementMailType | formatCategory }}
          </md-table-cell>
          <md-table-cell>{{ item.subject }}</md-table-cell>
          <md-table-cell>
            <span v-if="item.status == 'UNKNOWN'">
              <md-icon class="spin">sync</md-icon>
              <md-tooltip md-direction="right">
                Hämtar status ...
              </md-tooltip>
            </span>
            <span v-else-if="item.status == 'OK'">
              <md-icon>done</md-icon>
              <md-tooltip md-direction="right">
                Alla har fått mejlet
              </md-tooltip>
            </span>
            <span v-else>
              <md-icon>block</md-icon>
              <md-tooltip md-direction="right">
                Alla har inte fått mejlet
              </md-tooltip>
            </span>
          </md-table-cell>
          <md-table-cell class="text-right">
            <span
              v-if="
                isAuthorizedToWrite &&
                  item.status != 'OK' &&
                  item.status != 'UNKNOWN'
              "
            >
              <md-button
                class="md-just-icon md-warning"
                @click="mailValidateSelected(item.id)"
                ><md-icon>sync</md-icon></md-button
              >
              <md-tooltip md-direction="right">
                Synka mejl
              </md-tooltip>
            </span>
            &nbsp;
            <span>
              <md-button
                class="md-just-icon md-success"
                @click="mailSelected(item.id)"
                ><md-icon>search</md-icon></md-button
              >
              <md-tooltip md-direction="right">
                Visa mejl
              </md-tooltip>
            </span>
          </md-table-cell>
        </md-table-row>
      </md-table>
      <p :hidden="data.length > 0">Kunde inte hitta några brev.</p>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  props: {
    arrangementId: {
      type: Number,
      required: true
    },
    isAuthorizedToWrite: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      data: [],
      spinnerDiameter: 60,
      spinnerStroke: 6,
      loading: true
    };
  },
  watch: {
    loading: function(value) {
      if (!value) {
        this.data.forEach(mail =>
          this.loadStatuses(mail.id, this.arrangementId)
        );
      }
    }
  },
  methods: {
    show: function() {
      this.loadData();
    },
    loadData: function() {
      this.loading = true;
      this.axios
        .get(
          "https://dashboard.svbefs.se/services/mail/api/mail/batch/arrangement/" +
            this.arrangementId
        )
        .then(response => {
          let parsedData = [];
          response.data.mail.forEach(function(item) {
            parsedData.push({
              id: item.id,
              createdOn: item.createdOn,
              subject: item.subject,
              contentHtml: item.contentHtml,
              arrangementMailType: item.arrangementMailType,
              status: "UNKNOWN"
            });
          });
          this.data = parsedData;
          this.loading = false;
        })
        .catch(() => {
          this.setError("Något gick fel");
          this.loading = false;
        });
    },
    loadStatuses: function(mailId, arrangementId) {
      this.data.filter(mail => mail.id == mailId)[0].status = "UNKNOWN"; // Reset status
      this.axios
        .get(
          "https://dashboard.svbefs.se/services/mail/api/mail/batch/arrangement/" +
            arrangementId +
            "/mail/" +
            mailId +
            "/status"
        )
        .then(response => {
          let hasAllReceived = response.data.hasAllReceived;
          this.data.filter(
            mail => mail.id == response.data.id
          )[0].status = hasAllReceived ? "OK" : "NOK";
        });
    },
    moment: function() {
      return moment;
    },
    setError: function(text) {
      this.$notify({
        message: text,
        icon: "error",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "danger",
        timeout: 5000
      });
    },
    mailSelected: function(id) {
      this.$emit("mailSelected", this.data.filter(item => item.id == id)[0]);
    },
    mailValidateSelected: function(id) {
      this.$emit(
        "mailValidateSelected",
        this.data.filter(item => item.id == id)[0]
      );
    }
  },
  filters: {
    formatDate: function(value) {
      return moment.utc(value).format("YYYY-MM-DD HH:MM");
    },
    formatCategory: function(value) {
      switch (value) {
        case "REGISTERED":
          return "Registrerade";
        case "RESERVED":
          return "Reserverade";
        case "NOT_RESERVED":
          return "Ej Reserverade";
        case "PAID":
          return "Betalda";
        case "NOT_PAID":
          return "Ej Betalda";
        default:
          return "Okänd";
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spin {
  animation-name: spin;
  animation-duration: 1500ms;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
}
</style>
