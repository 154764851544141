<template>
  <div>
    <div class="md-layout">
      <div class="md-layout-item md-size-60 md-small-size-100">
        <date-field
          :initialDate.sync="date"
          label="Arrangemanget skapat på dashboard"
          :datepicker="true"
        />
      </div>
      <div class="md-layout-item md-size-40 md-small-size-100">
        <p>Fylls i av huvudarrangör.</p>
      </div>
      <div
        class="md-layout-item md-size-60 md-small-size-100"
        v-blur="!isDateSet"
      >
        <date-field
          :initialDate.sync="datePublished"
          label="Arrangemang godkänt och publicerat"
          :datepicker="true"
          :disabled="!isDateSet || !isAuthorizedToApprove"
        />
      </div>
      <div class="md-layout-item md-size-40 md-small-size-100">
        <p>Fylls i av arrangörsansvarig eller annan styrelsemedlem.</p>
      </div>
      <div
        class="md-layout-item md-size-60 md-small-size-100"
        v-blur="!isPublishedDateSet"
      >
        <date-field
          :initialDate.sync="dateNewsletter"
          label="Mejl om arrangemanget skickat till prenumeranterna"
          :datepicker="true"
          :disabled="!isPublishedDateSet || !isAuthorizedToApprove"
        />
      </div>
      <div class="md-layout-item md-size-40 md-small-size-100">
        <p>
          Utskick görs av arrangörsansvarig eller annan styrelsemedlem. Bör
          helst ske innan: {{ newsletterDate }}
        </p>
      </div>
      <div class="md-layout-item md-size-100">
        <md-progress-bar
          :class="completionClass"
          :md-value="completionScore"
        ></md-progress-bar>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import DateField from "@/pages/Dashboard/Components/DateField.vue";
export default {
  components: {
    DateField
  },
  props: {
    arrangementDate: {
      required: true
    },
    initialDateCreated: {
      required: true
    },
    initialDatePublished: {
      required: true
    },
    initialDateNewsletter: {
      required: true
    }
  },
  data() {
    return {
      date: this.initialDateCreated,
      datePublished: this.initialDatePublished,
      dateNewsletter: this.initialDateNewsletter
    };
  },
  methods: {
    isValidDate: function(d) {
      return moment.utc(d, "YYYY-MM-DD", true).isValid();
    },
    updateDate: function() {
      this.$emit("update:initialDateCreated", this.date);
    },
    updateDatePublished: function() {
      this.$emit("update:initialDatePublished", this.datePublished);
    },
    updateDateNewsletter: function() {
      this.$emit("update:initialDateNewsletter", this.dateNewsletter);
    }
  },
  watch: {
    date() {
      this.updateDate();
    },
    datePublished() {
      this.updateDatePublished();
    },
    dateNewsletter() {
      this.updateDateNewsletter();
    }
  },
  computed: {
    isAuthorizedToApprove: function() {
      return this.$store.getters.roles.includes("arrangement_write");
    },
    newsletterDate: function() {
      if (!this.isValidDate(this.arrangementDate)) {
        return " ...";
      }
      return moment
        .utc(this.arrangementDate)
        .subtract(21, "days")
        .format("YYYY-MM-DD");
    },
    completionScore: function() {
      let score = 0;
      if (this.date) {
        score += 100 / 3;
      }
      if (this.datePublished) {
        score += 100 / 3;
      }
      if (this.dateNewsletter) {
        score += 100 / 3;
      }
      this.$emit("score", score);
      return score;
    },
    completionClass: function() {
      if (this.completionScore < 50) {
        return "md-danger";
      }
      if (this.completionScore < 100) {
        return "md-warning";
      }
      return "md-success";
    },
    isDateSet: function() {
      return this.date != null && this.date.length > 0;
    },
    isPublishedDateSet: function() {
      return this.datePublished != null && this.datePublished.length > 0;
    }
  }
};
</script>
