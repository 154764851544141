<template>
  <slide-x-left-transition>
    <div class="mapsidebar">
      <div :hidden="show">
        <div class="mapsidebarContent">
          <div class="mapsidebaricon-wrapper" @click="createProperty()">
            <md-icon>add_location</md-icon>
          </div>
        </div>
        <div class="mapsidebarContent marginTop">
          <div class="mapsidebaricon-wrapper" @click="openShowPrimarySidebar()">
            <md-icon>search</md-icon>
          </div>
        </div>
        <div
          class="mapsidebarContent marginTop"
          v-if="isAuthorizedToImportByJson"
        >
          <div class="mapsidebaricon-wrapper" @click="openImportSidebar()">
            <md-icon>construction</md-icon>
          </div>
        </div>
        <div
          class="mapsidebarContent marginTop"
          v-if="isAuthorizedToImportByJson"
        >
          <div class="mapsidebaricon-wrapper" @click="openSecretSidebar()">
            <md-icon>lock</md-icon>
          </div>
        </div>
      </div>
      <div :hidden="!show" class="mapsidebarContentWrapper">
        <div class="mapsidebarContent">
          <div class="mapsidebaricon-wrapper" @click="close()">
            <md-icon>close</md-icon>
          </div>
          <div :hidden="!showPrimarySidebar">
            <slot name="showPrimary" />
          </div>
          <div :hidden="!showShowPropertySidebar">
            <slot name="showProperty" />
          </div>
          <div :hidden="!showImportSidebar">
            <slot name="import" />
          </div>
          <div :hidden="!showSecretSidebar">
            <slot name="secret" />
          </div>
          <div :hidden="!showLoadingSidebar">
            <slot name="loading">
              <div class="md-layout">
                <div class="md-layout-item text-center">
                  <md-progress-spinner
                    md-mode="indeterminate"
                    :md-diameter="60"
                    :md-stroke="6"
                  ></md-progress-spinner>
                </div>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </slide-x-left-transition>
</template>
<script>
import { SlideXLeftTransition } from "vue2-transitions";
export default {
  components: {
    SlideXLeftTransition
  },
  data() {
    return {
      show: true,
      showPrimarySidebar: false,
      showShowPropertySidebar: false,
      showImportSidebar: false,
      showSecretSidebar: false,
      showLoadingSidebar: true
    };
  },
  methods: {
    closeInner() {
      this.showPrimarySidebar = false;
      this.showShowPropertySidebar = false;
      this.showImportSidebar = false;
      this.showSecretSidebar = false;
      this.showLoadingSidebar = false;
    },
    close() {
      this.show = false;
      this.closeInner();
      this.$emit("closed");
    },
    open() {
      this.show = true;
      this.$emit("opened");
    },
    openShowPrimarySidebar() {
      this.closeInner();
      this.showPrimarySidebar = true;
      this.open();
    },
    openShowPropertySidebar() {
      this.closeInner();
      this.showShowPropertySidebar = true;
      this.open();
    },
    openImportSidebar() {
      this.closeInner();
      this.showImportSidebar = true;
      this.open();
    },
    openSecretSidebar() {
      this.closeInner();
      this.showSecretSidebar = true;
      this.open();
    },
    openLoadingSidebar() {
      this.closeInner();
      this.showLoadingSidebar = true;
      this.open();
    },
    createProperty() {
      this.$emit("createProperty");
    }
  },
  computed: {
    isAuthorizedToImportByJson: function() {
      return this.$store.getters.mapRoles.includes("import_json");
    }
  }
};
</script>
<style lang="scss" scoped>
.mapsidebar {
  background-color: #fff;
  margin-top: 70px;
  padding-top: 40px;
  padding-bottom: 40px;
}
.mapsidebarContentWrapper {
  width: 350px;
}
.mapsidebarContent {
  display: flex;
  flex-flow: column wrap;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.mapsidebaricon-wrapper {
  font-size: 36px;
  color: #000;
  cursor: pointer;
  align-self: end;
}
</style>
