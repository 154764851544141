import DashboardLayout from "@/pages/Dashboard/Layout/DashboardLayout.vue";
import AuthLayout from "@/pages/Dashboard/Pages/AuthLayout.vue";

// Dashboard pages
import Dashboard from "@/pages/Dashboard/Dashboard.vue";

// Email pages
import OrganizationLetter from "@/pages/Dashboard/Email/OrganizationLetter.vue";

// Member pages
import Member from "@/pages/Dashboard/Member/Member.vue";
import MemberList from "@/pages/Dashboard/Member/MemberList.vue";
import MemberAwaitingList from "@/pages/Dashboard/Member/MemberAwaitingList.vue";

// Tour pages
import EditTour from "@/pages/Dashboard/Tour/EditTour.vue";
import CreateTour from "@/pages/Dashboard/Tour/CreateTour.vue";
import PlanTour from "@/pages/Dashboard/Tour/PlanTour.vue";
import PlanTourList from "@/pages/Dashboard/Tour/PlanTourList.vue";
import TourList from "@/pages/Dashboard/Tour/TourList.vue";
import TourApplications from "@/pages/Dashboard/Tour/TourApplications.vue";
import TourMail from "@/pages/Dashboard/Tour/TourMail.vue";
import TourSetup from "@/pages/Dashboard/Tour/TourSetup.vue";

// Map pages
import PropertyMap from "@/pages/Dashboard/Map/PropertyMap.vue";

// Pages
import User from "@/pages/Dashboard/Pages/UserProfile.vue";
import Pricing from "@/pages/Dashboard/Pages/Pricing.vue";
import TimeLine from "@/pages/Dashboard/Pages/TimeLinePage.vue";
import RtlSupport from "@/pages/Dashboard/Pages/RtlSupport.vue";
import Login from "@/pages/Dashboard/Pages/Login.vue";
import Register from "@/pages/Dashboard/Pages/Register.vue";
import Lock from "@/pages/Dashboard/Pages/Lock.vue";

// Components pages
import Buttons from "@/pages/Dashboard/Components/Buttons.vue";
import GridSystem from "@/pages/Dashboard/Components/GridSystem.vue";
import Panels from "@/pages/Dashboard/Components/Panels.vue";
import SweetAlert from "@/pages/Dashboard/Components/SweetAlert.vue";
import Notifications from "@/pages/Dashboard/Components/Notifications.vue";
import Icons from "@/pages/Dashboard/Components/Icons.vue";
import Typography from "@/pages/Dashboard/Components/Typography.vue";

// Forms pages
import RegularForms from "@/pages/Dashboard/Forms/RegularForms.vue";
import ExtendedForms from "@/pages/Dashboard/Forms/ExtendedForms.vue";
import ValidationForms from "@/pages/Dashboard/Forms/ValidationForms.vue";
import Wizard from "@/pages/Dashboard/Forms/Wizard.vue";

// TableList pages
import RegularTables from "@/pages/Dashboard/Tables/RegularTables.vue";
import ExtendedTables from "@/pages/Dashboard/Tables/ExtendedTables.vue";
import PaginatedTables from "@/pages/Dashboard/Tables/PaginatedTables.vue";

// Maps pages
import GoogleMaps from "@/pages/Dashboard/Maps/GoogleMaps.vue";
import FullScreenMap from "@/pages/Dashboard/Maps/FullScreenMap.vue";
import VectorMaps from "@/pages/Dashboard/Maps/VectorMaps.vue";

// Calendar
import Calendar from "@/pages/Dashboard/Calendar.vue";
// Charts
import Charts from "@/pages/Dashboard/Charts.vue";
import Widgets from "@/pages/Dashboard/Widgets.vue";

let emailsMenu = {
  path: "/email",
  component: DashboardLayout,
  redirect: "/email/organization",
  name: "Email",
  children: [
    {
      path: "organization",
      name: "Föreningsbrev",
      components: { default: OrganizationLetter }
    }
  ]
};
let memberMenu = {
  path: "/member",
  component: DashboardLayout,
  redirect: "/member/search",
  name: "Member",
  children: [
    {
      path: "search",
      name: "Sök Medlem",
      components: { default: MemberList }
    },
    {
      path: "application",
      name: "Ansökningar",
      components: { default: MemberAwaitingList }
    },
    {
      path: ":id",
      name: "Medlem",
      components: { default: Member }
    }
  ]
};
let tourMenu = {
  path: "/tour",
  component: DashboardLayout,
  redirect: "/tour/list",
  name: "Tour",
  children: [
    {
      path: "list",
      name: "Lista Arrangemang",
      components: { default: TourList }
    },
    {
      path: "create",
      name: "Skapa Arrangemang",
      components: { default: CreateTour }
    },
    {
      path: "plan",
      name: "Planera Arrangemang",
      components: { default: PlanTourList }
    },
    {
      path: "plan/create",
      name: "Skapa Planering",
      components: { default: PlanTour }
    },
    {
      path: "plan/:id",
      name: "Planering",
      components: { default: PlanTour }
    },
    {
      path: ":id",
      name: "Arrangemang",
      components: { default: EditTour }
    },
    {
      path: ":id/applications",
      name: "Lista Ansökningar",
      components: { default: TourApplications }
    },
    {
      path: ":id/setup",
      name: "Arrangemanguppsättning",
      components: { default: TourSetup }
    },
    {
      path: ":id/mail",
      name: "Arrangemangsbrev",
      components: { default: TourMail }
    }
  ]
};

let mapMenu = {
  path: "/map",
  component: DashboardLayout,
  name: "Karta",
  redirect: "/map/property",
  children: [
    {
      path: "property",
      name: "Föreningskarta",
      meta: {
        hideContent: true,
        hideFooter: true,
        navbarAbsolute: true
      },
      components: { default: PropertyMap }
    }
  ]
};

let componentsMenu = {
  path: "/components",
  component: DashboardLayout,
  redirect: "/components/buttons",
  name: "Components",
  children: [
    {
      path: "buttons",
      name: "Buttons",
      components: { default: Buttons },
    },
    {
      path: "grid-system",
      name: "Grid System",
      components: { default: GridSystem },
    },
    {
      path: "panels",
      name: "Panels",
      components: { default: Panels },
    },
    {
      path: "sweet-alert",
      name: "Sweet Alert",
      components: { default: SweetAlert },
    },
    {
      path: "notifications",
      name: "Notifications",
      components: { default: Notifications },
    },
    {
      path: "icons",
      name: "Icons",
      components: { default: Icons },
    },
    {
      path: "typography",
      name: "Typography",
      components: { default: Typography },
    },
  ],
};
let formsMenu = {
  path: "/forms",
  component: DashboardLayout,
  redirect: "/forms/regular",
  name: "Forms",
  children: [
    {
      path: "regular",
      name: "Regular Forms",
      components: { default: RegularForms },
    },
    {
      path: "extended",
      name: "Extended Forms",
      components: { default: ExtendedForms },
    },
    {
      path: "validation",
      name: "Validation Forms",
      components: { default: ValidationForms },
    },
    {
      path: "wizard",
      name: "Wizard",
      components: { default: Wizard },
    },
  ],
};

let tablesMenu = {
  path: "/table-list",
  component: DashboardLayout,
  redirect: "/table-list/regular",
  name: "Tables",
  children: [
    {
      path: "regular",
      name: "Regular Tables",
      components: { default: RegularTables },
    },
    {
      path: "extended",
      name: "Extended Tables",
      components: { default: ExtendedTables },
    },
    {
      path: "paginated",
      name: "Pagianted Tables",
      components: { default: PaginatedTables },
    },
  ],
};

let mapsMenu = {
  path: "/maps",
  component: DashboardLayout,
  name: "Maps",
  redirect: "/maps/google",
  children: [
    {
      path: "google",
      name: "Google Maps",
      components: { default: GoogleMaps },
    },
    {
      path: "full-screen",
      name: "Full Screen Map",
      meta: {
        hideContent: true,
        hideFooter: true,
        navbarAbsolute: true,
      },
      components: { default: FullScreenMap },
    },
    {
      path: "vector-map",
      name: "Vector Map",
      components: { default: VectorMaps },
    },
  ],
};

let pagesMenu = {
  path: "/pages",
  component: DashboardLayout,
  name: "Pages",
  redirect: "/pages/user",
  children: [
    {
      path: "user",
      name: "User Page",
      components: { default: User },
    },
    {
      path: "timeline",
      name: "Timeline Page",
      components: { default: TimeLine },
    },
    {
      path: "rtl",
      name: "وحة القيادة",
      meta: {
        rtlActive: true,
      },
      components: { default: RtlSupport },
    },
  ],
};

let authPages = {
  path: "/",
  component: AuthLayout,
  name: "Authentication",
  children: [
    {
      path: "/login",
      name: "Login",
      component: Login,
    },
    {
      path: "/register",
      name: "Register",
      component: Register,
    },
    {
      path: "/pricing",
      name: "Pricing",
      component: Pricing,
    },
    {
      path: "/lock",
      name: "Lock",
      component: Lock,
    },
  ],
};

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    name: "Home",
  },
  tourMenu,
  emailsMenu,
  memberMenu,
  mapMenu,
  componentsMenu,
  formsMenu,
  tablesMenu,
  mapsMenu,
  pagesMenu,
  authPages,
  {
    path: "/",
    component: DashboardLayout,
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        components: { default: Dashboard },
      },
      {
        path: "calendar",
        name: "Calendar",
        components: { default: Calendar },
      },
      {
        path: "charts",
        name: "Charts",
        components: { default: Charts },
      },
      {
        path: "widgets",
        name: "Widgets",
        components: { default: Widgets },
      },
    ],
  },
];

export default routes;
