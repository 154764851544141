<template>
  <md-table class="arrangement-info-table">
    <md-table-row>
      <md-table-cell>Titel</md-table-cell>
      <md-table-cell>{{ title }}</md-table-cell>
    </md-table-row>
    <md-table-row>
      <md-table-cell>Platser</md-table-cell>
      <md-table-cell>{{ slots }} st</md-table-cell>
    </md-table-row>
    <md-table-row>
      <md-table-cell>Avgift</md-table-cell>
      <md-table-cell>{{ fee }} kr</md-table-cell>
    </md-table-row>
  </md-table>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    slots: {
      type: Number,
      required: true
    },
    fee: {
      type: Number,
      required: true
    }
  }
};
</script>
<style lang="scss" scoped>
.arrangement-info-table {
  .md-table-row:hover,
  .md-table-row:focus {
    background: transparent !important;
  }

  .md-table-row:first-child .md-table-cell {
    border: 0;
  }
  .md-table-cell:nth-child(3) .md-table-cell-container {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
