<template>
  <div>
    <div class="md-layout" v-if="disableStep">
      <div class="md-layout-item">
        <p>
          <b class="text-danger">Låst steg!</b> Du behöver fylla i övriga steg
          för att låsa upp detta.
        </p>
      </div>
    </div>
    <div class="md-layout" v-blur="disableStep">
      <div class="md-layout-item md-size-60 md-small-size-100">
        <date-field
          :initialDate.sync="dateSentForApproval"
          label="Datum då PDF skickades för godkännande"
          :datepicker="true"
          :disabled="disableStep"
        />
      </div>
      <div class="md-layout-item md-size-40 md-small-size-100">
        <p>
          PDF skickas till alla arrangörer och styrelsen.
        </p>
      </div>
      <div
        class="md-layout-item md-size-60 md-small-size-100"
        v-blur="!isPdfSentForApproval"
      >
        <date-field
          :initialDate.sync="dateApprovalChairman"
          label="PDF godkänd av ordförande"
          :datepicker="true"
          :disabled="
            disableStep || !isPdfSentForApproval || !isAuthorizedToApprove
          "
        />
      </div>
      <div class="md-layout-item md-size-40 md-small-size-100">
        <p>Fylls i av arrangörsansvarig eller annan styrelsemedlem.</p>
      </div>
      <div
        class="md-layout-item md-size-60 md-small-size-100"
        v-blur="!isPdfSentForApproval"
      >
        <date-field
          :initialDate.sync="dateApprovalArrangementResponsible"
          label="PDF godkänd av arrangörsansvarig"
          :datepicker="true"
          :disabled="
            disableStep || !isPdfSentForApproval || !isAuthorizedToApprove
          "
        />
      </div>
      <div class="md-layout-item md-size-40 md-small-size-100">
        <p>Fylls i av arrangörsansvarig eller annan styrelsemedlem.</p>
      </div>
      <div
        class="md-layout-item md-size-60 md-small-size-100"
        v-blur="!isPdfApproved"
      >
        <text-field
          :initialTextval.sync="link"
          label="Länk till PDF"
          icon="link"
          :validationConditions="linkValidation"
          :disabled="disableStep || !isPdfApproved"
        />
      </div>
      <div
        class="md-layout-item md-size-40 md-small-size-100"
        v-blur="!isPdfLinkCreated"
      >
        <p v-if="isLinkSet">Länk sparades {{ dateLinkCreated }}</p>
        <p v-else>Ifylls av serveradministratör.</p>
      </div>
      <div
        class="md-layout-item md-size-60 md-small-size-100"
        v-blur="!isPdfLinkCreated"
      >
        <date-field
          :initialDate.sync="dateSent"
          label="PDF skickades till prenumeranterna"
          :datepicker="true"
          :disabled="disableStep || !isPdfLinkCreated"
        />
      </div>
      <div class="md-layout-item md-size-40 md-small-size-100">
        <p>Skickas av huvudarrangören.</p>
      </div>
      <div class="md-layout-item md-size-100">
        <md-progress-bar
          :class="completionClass"
          :md-value="completionScore"
        ></md-progress-bar>
      </div>
    </div>
  </div>
</template>
<script>
import DateField from "@/pages/Dashboard/Components/DateField.vue";
import TextField from "@/pages/Dashboard/Components/TextField.vue";
import moment from "moment";
export default {
  components: {
    DateField,
    TextField
  },
  props: {
    initialDateSentForApproval: {
      required: true
    },
    initialDateApprovalChairman: {
      required: true
    },
    initialDateArrangementResponsible: {
      required: true
    },
    initialLink: {
      type: String,
      required: true
    },
    initialDateLinkCreated: {
      required: true
    },
    initialDateLinkSent: {
      required: true
    },
    disableStep: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      dateSentForApproval: this.initialDateSentForApproval,
      dateApprovalChairman: this.initialDateApprovalChairman,
      dateApprovalArrangementResponsible: this
        .initialDateArrangementResponsible,
      link: this.initialLink,
      dateLinkCreated: this.initialDateLinkCreated,
      dateSent: this.initialDateLinkSent,
      linkValidation: {
        required: true,
        min: 4
      }
    };
  },
  methods: {
    updateDateSentForApproval: function() {
      this.$emit("update:initialDateSentForApproval", this.dateSentForApproval);
    },
    updateDateApprovalChairman: function() {
      this.$emit(
        "update:initialDateApprovalChairman",
        this.dateApprovalChairman
      );
    },
    updateDateApprovalArrangementResponsible: function() {
      this.$emit(
        "update:initialDateArrangementResponsible",
        this.dateApprovalArrangementResponsible
      );
    },
    updateLink: function() {
      this.dateLinkCreated = moment.utc().format("YYYY-MM-DD");
      this.$emit("update:initialDateLinkCreated", this.dateLinkCreated);
      this.$emit("update:initialLink", this.link);
    },
    updateDateSent: function() {
      this.$emit("update:initialDateLinkSent", this.dateSent);
    }
  },
  watch: {
    dateSentForApproval() {
      this.updateDateSentForApproval();
    },
    dateApprovalChairman() {
      this.updateDateApprovalChairman();
    },
    dateApprovalArrangementResponsible() {
      this.updateDateApprovalArrangementResponsible();
    },
    link() {
      this.updateLink();
    },
    dateSent() {
      this.updateDateSent();
    }
  },
  computed: {
    isAuthorizedToApprove: function() {
      return this.$store.getters.roles.includes("arrangement_write");
    },
    completionScore: function() {
      let score = 0;
      if (this.dateSentForApproval) {
        score += 100 / 5;
      }
      if (this.dateApprovalChairman) {
        score += 100 / 5;
      }
      if (this.dateApprovalArrangementResponsible) {
        score += 100 / 5;
      }
      if (this.dateLinkCreated) {
        score += 100 / 5;
      }
      if (this.dateSent) {
        score += 100 / 5;
      }
      this.$emit("score", score);
      return score;
    },
    completionClass: function() {
      if (this.completionScore < 50) {
        return "md-danger";
      }
      if (this.completionScore < 100) {
        return "md-warning";
      }
      return "md-success";
    },
    isPdfSentForApproval: function() {
      return this.dateSentForApproval;
    },
    isPdfApproved: function() {
      return (
        this.isPdfSentForApproval &&
        this.dateApprovalChairman &&
        this.dateApprovalArrangementResponsible
      );
    },
    isPdfLinkCreated: function() {
      return this.isPdfApproved && this.link;
    },
    isLinkSet: function() {
      return this.link != null && this.link.length > 0;
    }
  }
};
</script>
