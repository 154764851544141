import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    keyCloak: {},
    userName: "",
    firstName: "",
    lastName: "",
    roles: "",
    mailRoles: "",
    arrangementPlannerRoles: "",
    mapRoles: ""
  },
  mutations: {
    setKeyCloak(state, keyCloak) {
      state.keyCloak = keyCloak;
    },
    setUserName(state, userName) {
      state.userName = userName;
    },
    setFirstName(state, firstName) {
      state.firstName = firstName;
    },
    setLastName(state, lastName) {
      state.lastName = lastName;
    },
    setRoles(state, roles) {
      state.roles = roles;
    },
    setMailRoles(state, mailRoles) {
      state.mailRoles = mailRoles;
    },
    setArrangementPlannerRoles(state, arrangementPlannerRoles) {
      state.arrangementPlannerRoles = arrangementPlannerRoles;
    },
    setMapRoles(state, mapRoles) {
      state.mapRoles = mapRoles;
    }
  },
  getters: {
    keyCloak: state => state.keyCloak,
    userName: state => state.userName,
    firstName: state => state.firstName,
    lastName: state => state.lastName,
    roles: state => state.roles,
    mailRoles: state => state.mailRoles,
    arrangementPlannerRoles: state => state.arrangementPlannerRoles,
    mapRoles: state => state.mapRoles
  }
});
