var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item text-center"},[_c('md-progress-spinner',{attrs:{"md-mode":"indeterminate","md-diameter":_vm.spinnerDiameter,"md-stroke":_vm.spinnerStroke}})],1)]),_c('form',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],staticClass:"form-horizontal",attrs:{"action":"","method":"post"}},[_c('div',{staticClass:"md-layout pb"},[_c('div',{staticClass:"md-layout-item"},[_c('md-field',{class:[
            { 'md-error': _vm.errors.has('headOrganizer') },
            {
              'md-valid':
                !_vm.errors.has('headOrganizer') && _vm.touched.headOrganizer
            }
          ]},[_c('label',{attrs:{"for":"additionalOrganizers"}},[_vm._v("Huvudarrangör")]),_c('md-select',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.modelValidations.headOrganizer),expression:"modelValidations.headOrganizer"}],attrs:{"value":_vm.headOrganizer,"name":"additionalOrganizers","id":"additionalOrganizers","disabled":!_vm.isAuthorizedToWrite || !_vm.isAuthorizedToChangeHeadOrganizer,"data-vv-name":"headOrganizer","required":""},on:{"md-selected":_vm.updatedHeadOrganizer}},_vm._l((_vm.allOrganizers),function(organizer){return _c('md-option',{key:organizer,attrs:{"value":organizer}},[_vm._v(_vm._s(organizer))])}),1),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('headOrganizer')),expression:"errors.has('headOrganizer')"}],staticClass:"error"},[_vm._v("close")])],1),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.errors.has('headOrganizer') && _vm.touched.headOrganizer),expression:"!errors.has('headOrganizer') && touched.headOrganizer"}],staticClass:"success"},[_vm._v("done")])],1)],1)],1)]),_c('div',{staticClass:"md-layout pb"},[_c('div',{staticClass:"md-layout-item"},[_c('md-field',[_c('label',{attrs:{"for":"additionalOrganizers"}},[_vm._v("Ytterligare arrangörer")]),_c('md-select',{attrs:{"value":_vm.organizers,"name":"additionalOrganizers","id":"additionalOrganizers","multiple":"","disabled":!_vm.isAuthorizedToWrite || !_vm.isAuthorizedToChangeOrganizer},on:{"md-selected":_vm.updateAdditionalOrganizer}},_vm._l((_vm.availableAdditionalOrganizers),function(organizer){return _c('md-option',{key:organizer,attrs:{"value":organizer}},[_vm._v(_vm._s(organizer))])}),1)],1)],1)])]),_vm._m(0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item"},[_c('p',[_vm._v(" Huvudarrangören bär det huvudsakliga ansvaret för arrangemanget. Det är bara huvudarrangören som kan redigera arrangörer. Information om huvudarrangören och ev. övriga arrangörer anges dokumentet till alla deltagare. ")])])])
}]

export { render, staticRenderFns }