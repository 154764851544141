var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{staticClass:"form-horizontal",attrs:{"action":"","method":"post"}},[_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item"},[_c('md-field',{class:[
          { 'md-error': _vm.errors.has('title') },
          { 'md-valid': !_vm.errors.has('title') && _vm.touched.title }
        ]},[_c('label',[_vm._v("Titel")]),_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.modelValidations.title),expression:"modelValidations.title"}],attrs:{"value":_vm.title,"data-vv-name":"title","type":"text","required":""},on:{"input":_vm.updateTitle}}),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('title')),expression:"errors.has('title')"}],staticClass:"error"},[_vm._v("close")])],1),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.errors.has('title') && _vm.touched.title),expression:"!errors.has('title') && touched.title"}],staticClass:"success"},[_vm._v("done")])],1)],1)],1),_c('div',{staticClass:"md-layout-item"},[_c('md-field',{class:[
          { 'md-error': _vm.errors.has('date') },
          { 'md-valid': !_vm.errors.has('date') && _vm.touched.date }
        ]},[_c('label',[_vm._v("Datum")]),_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.modelValidations.date),expression:"modelValidations.date"}],attrs:{"data-vv-name":"date","type":"text","required":""},on:{"input":_vm.updateDate},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('date')),expression:"errors.has('date')"}],staticClass:"error"},[_vm._v("close")])],1),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.errors.has('date') && _vm.touched.date),expression:"!errors.has('date') && touched.date"}],staticClass:"success"},[_vm._v("done")])],1)],1)],1),_c('div',{staticClass:"md-layout-item"},[_c('md-field',{class:[
          { 'md-error': _vm.errors.has('time') },
          {
            'md-valid': !_vm.errors.has('time') && _vm.touched.time
          }
        ]},[_c('label',[_vm._v("Tid")]),_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.modelValidations.time),expression:"modelValidations.time"}],attrs:{"data-vv-name":"time","type":"text","required":""},on:{"input":_vm.updateTime},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}}),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('time')),expression:"errors.has('time')"}],staticClass:"error"},[_vm._v("close")])],1),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.errors.has('time') && _vm.touched.time),expression:"!errors.has('time') && touched.time"}],staticClass:"success"},[_vm._v("done")])],1)],1)],1)]),_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item"},[_c('md-field',{class:[
          { 'md-error': _vm.errors.has('location') },
          {
            'md-valid': !_vm.errors.has('location') && _vm.touched.location
          }
        ]},[_c('label',[_vm._v("Plats")]),_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.modelValidations.location),expression:"modelValidations.location"}],attrs:{"value":_vm.location,"data-vv-name":"location","type":"text","required":""},on:{"input":_vm.updateLocation}}),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('location')),expression:"errors.has('location')"}],staticClass:"error"},[_vm._v("close")])],1),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.errors.has('location') && _vm.touched.location),expression:"!errors.has('location') && touched.location"}],staticClass:"success"},[_vm._v("done")])],1)],1)],1),_c('div',{staticClass:"md-layout-item"},[_c('md-field',{class:[
          { 'md-error': _vm.errors.has('slots') },
          { 'md-valid': !_vm.errors.has('slots') && _vm.touched.slots }
        ]},[_c('label',[_vm._v("Personer")]),_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.modelValidations.slots),expression:"modelValidations.slots"}],attrs:{"data-vv-name":"slots","type":"number","required":""},on:{"input":_vm.updateSlots},model:{value:(_vm.slots),callback:function ($$v) {_vm.slots=$$v},expression:"slots"}}),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('slots')),expression:"errors.has('slots')"}],staticClass:"error"},[_vm._v("close")])],1),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.errors.has('slots') && _vm.touched.slots),expression:"!errors.has('slots') && touched.slots"}],staticClass:"success"},[_vm._v("done")])],1)],1)],1),_c('div',{staticClass:"md-layout-item"},[_c('md-field',{class:[
          { 'md-error': _vm.errors.has('fee') },
          { 'md-valid': !_vm.errors.has('fee') && _vm.touched.fee }
        ]},[_c('label',[_vm._v("Avgift")]),_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.modelValidations.fee),expression:"modelValidations.fee"}],attrs:{"data-vv-name":"fee","type":"number","required":""},on:{"input":_vm.updateFee},model:{value:(_vm.fee),callback:function ($$v) {_vm.fee=$$v},expression:"fee"}}),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('fee')),expression:"errors.has('fee')"}],staticClass:"error"},[_vm._v("close")])],1),_c('slide-y-down-transition',[_c('md-icon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.errors.has('fee') && _vm.touched.fee),expression:"!errors.has('fee') && touched.fee"}],staticClass:"success"},[_vm._v("done")])],1)],1)],1)]),_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-size-33"},[_c('md-field',[_c('label',[_vm._v("Status")]),_c('md-select',{attrs:{"required":"","disabled":!_vm.isAuthorizedToChangeStatus},on:{"md-selected":_vm.updateStatus},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_c('md-option',{attrs:{"value":"utkast"}},[_vm._v("Utkast")]),_c('md-option',{attrs:{"value":"planerad"}},[_vm._v("Planerad")]),_c('md-option',{attrs:{"value":"inställd"}},[_vm._v("Inställd")]),_c('md-option',{attrs:{"value":"avslutad"}},[_vm._v("Avslutad")])],1)],1)],1)]),_vm._m(0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item"},[_c('p',[_vm._v(" Titeln bör vara beskrivande men kort och inte innehålla ord som t.ex. arrangemang, visning, eller dylikt. Platsen ska anges geografiskt ungefärlig och på en högre nivå, t.ex. stad eller landskap. För priset föreligger en ekonomisk kalkyl. Enbart styrelsen kan flytta arrangemang från status utkast. ")])])])
}]

export { render, staticRenderFns }