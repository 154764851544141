<template>
  <div>
    <md-field
      :class="[
        { 'md-valid': !errors.has('textval') && touched.textval },
        { 'md-form-group': true },
        { 'md-error': errors.has('textval') }
      ]"
    >
      <md-icon>{{ icon }}</md-icon>
      <label>{{ label }}</label>
      <md-input
        :disabled="disabled"
        v-model="textval"
        data-vv-name="textval"
        type="text"
        name="textval"
        v-validate="validationConditions"
        @input="updateTextval"
      ></md-input>
      <slide-y-down-transition>
        <md-icon class="error" v-show="errors.has('textval')">close</md-icon>
      </slide-y-down-transition>
      <slide-y-down-transition>
        <md-icon
          class="success"
          v-show="!errors.has('textval') && touched.textval"
        >
          done</md-icon
        >
      </slide-y-down-transition>
    </md-field>
  </div>
</template>
<script>
import { SlideYDownTransition } from "vue2-transitions";
export default {
  components: {
    SlideYDownTransition
  },
  props: {
    label: {
      type: String,
      required: true
    },
    initialTextval: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false
    },
    icon: {
      type: String,
      required: false,
      default: () => {
        return "edit";
      }
    },
    validationConditions: {
      type: Object,
      required: false,
      default: () => {
        return {
          required: true,
          numeric: true,
          min_value: 5
        };
      }
    }
  },
  data() {
    return {
      textval: this.initialTextval,
      touched: {
        textval: false
      }
    };
  },
  watch: {
    textval() {
      this.touched.textval = true;
    }
  },
  methods: {
    updateTextval: function() {
      this.$emit("update:initialTextval", this.textval);
    }
  }
};
</script>
