<template>
  <div id="mapWrapper" v-if="isAuthorizedToRead">
    <GmapMap
      ref="gmap"
      id="map"
      :center="{ lat: centerLat, lng: centerLng }"
      :zoom="7"
    >
      <GmapMarker
        :key="index"
        v-for="(marker, index) in listOfMarkers"
        :position="marker.position"
        :title="marker.name"
        :clickable="true"
        :draggable="marker.draggable"
        :icon="marker.icon"
        :animation="marker.draggable ? 1 : 0"
        @click="setSelectedMarker(marker)"
        @dragend="dragend"
      ></GmapMarker>
    </GmapMap>
    <sidebar
      ref="sidebar"
      @closed="closeSidebar"
      @createProperty="createProperty"
    >
      <template v-slot:showProperty>
        <div v-if="isMarkerSelected">
          <show-property
            :id="selectedMarkerProperty.id"
            :secret="selectedMarkerProperty.secret"
            :designation="selectedMarkerProperty.designation"
            :region="selectedMarkerProperty.region"
            :area="selectedMarkerProperty.l_area"
            :lregion="selectedMarkerProperty.l_region"
            :block="selectedMarkerProperty.l_block"
            :unit="selectedMarkerProperty.l_unit"
            :name="selectedMarkerProperty.name"
            :description="selectedMarkerProperty.description"
            :existing="selectedMarkerProperty.existing"
            :currentowner="selectedMarkerProperty.currentowner"
            :locations="selectedMarkerLocations"
            :lat="selectedMarkerPosition.lat"
            :lng="selectedMarkerPosition.lng"
            :hasEncryptionKey="encryptionKey.length > 0"
            @update="updateProperty"
            @edit="setEdit"
            @addLocation="createLocation"
            @deleteLocation="deleteLocation"
            @deleteProperty="deleteProperty"
            @center="setCenter"
            ref="showProperty"
          />
        </div>
      </template>
      <template v-slot:showPrimary>
        <div class="md-layout">
          <div class="md-layout-item">
            <md-field>
              <label>Decimalkoordinat</label>
              <md-input v-model="navigate" type="text"></md-input>
            </md-field>
            <md-button
              class="md-success"
              :disabled="!isNavigateCoordinate"
              @click="navigateTo"
            >
              Navigera
            </md-button>
          </div>
        </div>
        <div class="md-layout marginTop">
          <div class="md-layout-item">
            <md-field>
              <label>Söksträng</label>
              <md-input v-model="searchText" type="text"></md-input>
            </md-field>
            <md-button
              class="md-success"
              :disabled="searchText.length < 1"
              @click="addMarkers"
            >
              Sök i kartan
            </md-button>
            <md-button class="md-warning" @click="resetSearchText">
              Återställ
            </md-button>
          </div>
        </div>
        <div class="md-layout" v-if="isAuthorizedToManageDuplicates">
          <div class="md-layout-item">
            <md-checkbox v-model="showDuplicates">Visa duplikat</md-checkbox>
          </div>
        </div>
      </template>
      <template v-slot:import>
        <import-json @change="setCenter" @create="createProperty" />
      </template>
      <template v-slot:secret v-if="isAuthorizedToReadEncrypted">
        <secret-sidebar
          :encryptionKey.sync="encryptionKey"
          :showSecret.sync="showSecret"
          :loading="loading"
          @reload="getProperties"
        />
      </template>
    </sidebar>
  </div>
</template>
<script>
import ShowProperty from "./PropertyMap/ShowProperty";
import Sidebar from "./PropertyMap/Sidebar";
import ImportJson from "./PropertyMap/ImportJson";
import SecretSidebar from "./PropertyMap/SecretSidebar";
export default {
  components: {
    ShowProperty,
    Sidebar,
    ImportJson,
    SecretSidebar
  },
  data() {
    return {
      loading: true,
      showSecret: false,
      showDuplicates: false,
      navigate: "",
      searchText: "",
      encryptionKey: "",
      selectedMarkerBeforeEdit: {},
      selectedMarker: {},
      selectedMarkerProperty: {},
      google: {},
      properties: {},
      listOfMarkers: [],
      centerLat: 57.7059013,
      centerLng: 11.9873124,
      iconRedDot: require("@/assets/gmaps/red-dot.png"),
      iconGreenDot: require("@/assets/gmaps/green-dot.png"),
      iconPurpleDot: require("@/assets/gmaps/purple-dot.png")
    };
  },
  methods: {
    getProperties() {
      this.loading = true;
      let data = {};
      if (this.encryptionKey && this.encryptionKey.length > 0) {
        data = {
          encryptionKey: this.encryptionKey
        };
      }
      this.axios
        .post("https://dashboard.svbefs.se/services/map/api/property", data)
        .then(response => {
          this.loadMap(response.data);
          this.loading = false;
        })
        .catch(e => {
          this.$errorNotice("Något gick fel");
          this.loading = false;
          if (process.env.NODE_ENV !== "production") {
            console.log(e);
          }
        });
    },
    loadMap(properties) {
      properties.forEach(property => {
        this.properties[property.id] = property;
      });
      this.addMarkers();
    },
    filterSecret(properties) {
      return properties.filter(property => !property.secret);
    },
    filterDuplicates(properties) {
      let filtered = [];
      properties.forEach(property => {
        let containDuplicates = false;
        if (property.location) {
          let locationKeys = [];
          property.location.forEach(location => {
            const key = location.north_wgs + "," + location.north_wgs;
            if (locationKeys.includes(key)) {
              containDuplicates = true;
            }
            locationKeys.push(key);
          });
        }
        if (containDuplicates) {
          filtered.push(property);
        }
      });
      return filtered;
    },
    filterSearchString(properties, searchStrings) {
      let filtered = [];
      properties.forEach(property => {
        searchStrings.forEach(searchString => {
          if (
            property.name &&
            property.name.toLowerCase().includes(searchString)
          ) {
            filtered.push(property);
          }
          if (
            property.description &&
            property.description.toLowerCase().includes(searchString)
          ) {
            filtered.push(property);
          }
        });
      });
      return filtered;
    },
    addMarkers() {
      let filtered = Object.values(this.properties);

      if (!this.showSecret) {
        filtered = this.filterSecret(filtered);
      }

      if (this.showDuplicates) {
        filtered = this.filterDuplicates(filtered);
      }

      const trimmedSearchText = this.searchText.trim().toLowerCase();
      if (trimmedSearchText.length > 0) {
        let searchStrings = [];
        if (trimmedSearchText.includes(" ")) {
          searchStrings = trimmedSearchText.split(" ");
        } else {
          searchStrings.push(trimmedSearchText);
        }
        filtered = this.filterSearchString(filtered, searchStrings);
      }

      const filteredKeys = filtered.map(property => String(property.id));

      // Remove all markers
      this.listOfMarkers = [];

      const markerList = [];
      // Add markers that should be visible
      filteredKeys.forEach(key => {
        const property = this.properties[key];
        property.location.forEach(location =>
          markerList.push(this.createMarker(property, location, false))
        );
      });
      this.listOfMarkers = markerList;
    },
    getGoogleMapsLink(lat, lng) {
      return "https://www.google.com/maps/place/" + lat + "," + lng;
    },
    dragend(v) {
      if (Object.keys(this.selectedMarker).length > 0) {
        this.selectedMarker.position.lat = v.latLng.lat();
        this.selectedMarker.position.lng = v.latLng.lng();
      }
    },
    updateProperty(data) {
      let property = this.selectedMarkerProperty;
      const oldProperty = JSON.parse(
        JSON.stringify(this.properties[property.id])
      );

      // Update property
      property.designation = data.designation;
      property.region = data.region;
      property.l_area = data.l_area;
      property.l_region = data.l_region;
      property.l_block = data.l_block;
      property.l_unit = data.l_unit;
      property.name = data.name;
      property.description = data.description;
      property.existing = data.existing;
      property.currentowner = data.currentowner;
      property.secret = data.secret;

      // Update location if marker has moved
      property.location
        .filter(location => location.id == this.selectedMarker.locationId)
        .forEach(location => {
          (location.north_wgs = this.selectedMarker.position.lat),
            (location.east_wgs = this.selectedMarker.position.lng);
        });
      this.properties[property.id] = property;
      this.save(oldProperty, property);
      this.selectedMarker.icon = this.createIcon(property, false);
    },
    createMarker(property, location, draggable) {
      return {
        locationId: location.id,
        propertyId: property.id,
        name: location.name,
        position: {
          lat: parseFloat(location.north_wgs),
          lng: parseFloat(location.east_wgs)
        },
        icon: this.createIcon(property, draggable),
        draggable: false
      };
    },
    removeMarkerFromMap(locationId) {
      let markersCpy = JSON.parse(JSON.stringify(this.listOfMarkers));
      markersCpy = markersCpy.filter(marker => marker.locationId != locationId);
      this.listOfMarkers = markersCpy;
    },
    setSelectedMarker(marker) {
      this.selectedMarker = marker;
      if (Object.keys(marker).length > 0) {
        this.selectedMarkerProperty = this.properties[marker.propertyId];
        if (this.isMarkerSelected) {
          this.$refs.sidebar.openShowPropertySidebar();
        }
      } else {
        this.selectedMarkerProperty = {};
        this.$refs.sidebar.close();
      }
    },
    setEdit(editing) {
      if (Object.keys(this.selectedMarker).length > 0) {
        this.selectedMarker.icon = this.createIcon(
          this.properties[this.selectedMarker.propertyId],
          editing
        );
        this.selectedMarker.draggable = editing;
      }
    },
    closeSidebar() {
      if (Object.keys(this.selectedMarker).length > 0) {
        this.setEdit(false);
        this.setSelectedMarker({});
      }
    },
    isSecretChanged(oldProperty, property) {
      return oldProperty.secret != property.secret;
    },
    isDataChanged(oldProperty, property) {
      const excludedVariables = ["id", "secret", "location"];
      const variablesToCheck = Object.keys(oldProperty).filter(
        key => !excludedVariables.includes(key)
      );
      return (
        variablesToCheck.filter(
          variable => oldProperty[variable] != property[variable]
        ).length > 0
      );
    },
    getLocationMap(property) {
      let locationMap = {};
      property.location.forEach(
        location => (locationMap[location.id] = location)
      );
      return locationMap;
    },
    getChangedLocations(oldPropertyLocationMap, propertyLocationMap) {
      const oldPropertyLocationMapKeys = Object.keys(oldPropertyLocationMap);
      const propertyLocationMapKeys = Object.keys(propertyLocationMap);
      return oldPropertyLocationMapKeys
        .filter(
          oldPropertyLocationId =>
            propertyLocationMapKeys.includes(oldPropertyLocationId) &&
            (oldPropertyLocationMap[oldPropertyLocationId].north_wgs !=
              propertyLocationMap[oldPropertyLocationId].north_wgs ||
              oldPropertyLocationMap[oldPropertyLocationId].east_wgs !=
                propertyLocationMap[oldPropertyLocationId].east_wgs)
        )
        .map(locationId => propertyLocationMap[locationId]);
    },
    saveSecret(property) {
      const hideData = {
        hide: property.secret,
        encryptionKey: this.encryptionKey
      };
      return this.axios.post(
        "https://dashboard.svbefs.se/services/map/api/property/" +
          property.id +
          "/hide",
        hideData
      );
    },
    saveData(property, secretChanged) {
      const updateData = {
        property: property,
        encryptionKey:
          property.secret || secretChanged ? this.encryptionKey : ""
      };
      return this.axios.post(
        "https://dashboard.svbefs.se/services/map/api/property/" + property.id,
        updateData
      );
    },
    createProperty() {
      this.loading = true;
      const data = {
        property: {
          designation: "",
          region: "",
          l_area: "",
          l_region: "",
          l_block: "",
          l_unit: "",
          name: "Ny markör",
          description: "",
          existing: true
        },
        encryptionKey: ""
      };
      this.axios
        .post("https://dashboard.svbefs.se/services/map/api/property/new", data)
        .then(response => {
          const property = response.data;
          this.properties[property.id] = property;
          this.createLocationOnProperty(property);
        })
        .catch(e => {
          this.$errorNotice("Något gick fel");
          this.loading = false;
          if (process.env.NODE_ENV !== "production") {
            console.log(e);
          }
        });
    },
    createIcon(property, draggable) {
      let url = this.iconRedDot;
      if (draggable) {
        url = this.iconGreenDot;
      } else if (property.secret) {
        url = this.iconPurpleDot;
      }
      return {
        url: url
      };
    },
    createLocation() {
      this.createLocationOnProperty(this.selectedMarkerProperty);
    },
    getUserLocation() {
      return new Promise(resolve => {
        let lat = 57.7059013;
        let lng = 11.9873124;
        this.$getLocation({
          timeout: 5000
        })
          .then(coordinates => {
            if (coordinates && coordinates.lat && coordinates.lng) {
              lat = parseFloat(coordinates.lat);
              lng = parseFloat(coordinates.lng);
            }
            resolve({ lat, lng });
          })
          .catch(() => {
            resolve({ lat, lng });
          });
      });
    },
    getCenterLocation() {
      return new Promise(resolve => {
        this.$refs.gmap.$mapPromise.then(map => {
          resolve(map.getCenter());
        });
      });
    },
    createLocationOnProperty(property) {
      this.loading = true;
      const centerPromise = this.getCenterLocation();
      centerPromise.then(center => {
        const data = {
          propertyLocation: {
            north_wgs: center.lat(),
            east_wgs: center.lng()
          },
          encryptionKey: property.secret ? this.encryptionKey : ""
        };
        this.axios
          .post(
            "https://dashboard.svbefs.se/services/map/api/property/" +
              property.id +
              "/location",
            data
          )
          .then(response => {
            const location = response.data;

            if (!property.location) {
              property.location = [];
            }
            property.location.push(location);
            this.properties[property.id] = property;
            this.selectedMarkerProperty = property;

            const markerList = this.listOfMarkers;
            const marker = this.createMarker(property, location, false);
            markerList.push(marker);
            this.setSelectedMarker(marker);
            this.listOfMarkers = markerList;
            this.$successNotice("Sparat");
            this.loading = false;
          })
          .catch(e => {
            this.$errorNotice("Något gick fel");
            this.loading = false;
            if (process.env.NODE_ENV !== "production") {
              console.log(e);
            }
          });
      });
    },
    deleteLocation(locationId) {
      this.loading = true;
      let property = this.selectedMarkerProperty;
      if (this.properties[property.id].location.length < 2) {
        this.$errorNotice("Kartan behöver uppdateras");
        return;
      }
      this.axios
        .delete(
          "https://dashboard.svbefs.se/services/map/api/location/" + locationId
        )
        .then(() => {
          this.properties[property.id].location = this.properties[
            property.id
          ].location.filter(location => location.id != locationId);
          this.removeMarkerFromMap(locationId);
          if (this.selectedMarker.locationId == locationId) {
            this.setSelectedMarker({});
          }
          this.$successNotice("Sparat");
          this.loading = false;
        })
        .catch(e => {
          this.$errorNotice("Något gick fel");
          this.loading = false;
          if (process.env.NODE_ENV !== "production") {
            console.log(e);
          }
        });
    },
    deleteProperty(propertyId) {
      this.loading = true;
      this.axios
        .delete(
          "https://dashboard.svbefs.se/services/map/api/property/" + propertyId
        )
        .then(() => {
          const property = this.properties[propertyId];
          property.location.forEach(location => {
            this.removeMarkerFromMap(location.id);
          });
          delete this.properties[property.id];
          this.setSelectedMarker({});
          this.$successNotice("Sparat");
          this.loading = false;
        })
        .catch(e => {
          this.$errorNotice("Något gick fel");
          this.loading = false;
          if (process.env.NODE_ENV !== "production") {
            console.log(e);
          }
        });
    },
    editLocations(property, locations, secretChanged) {
      let requests = [];
      locations.forEach(location => {
        const updateData = {
          propertyLocation: {
            north_wgs: location.north_wgs,
            east_wgs: location.east_wgs
          },
          encryptionKey:
            property.secret || secretChanged ? this.encryptionKey : ""
        };
        requests.push(
          this.axios.post(
            "https://dashboard.svbefs.se/services/map/api/property/" +
              property.id +
              "/location/" +
              location.id,
            updateData
          )
        );
      });
      return this.axios.all(requests);
    },
    save(oldProperty, property) {
      this.loading = true;
      const secretChanged = this.isSecretChanged(oldProperty, property);
      let secretPromise;
      if (secretChanged) {
        if (process.env.NODE_ENV !== "production") {
          console.log("Secret changed");
        }
        secretPromise = this.saveSecret(property);
      } else {
        secretPromise = Promise.resolve(true);
      }

      const dataChanged = this.isDataChanged(oldProperty, property);
      let updatePromise;
      if (dataChanged) {
        if (process.env.NODE_ENV !== "production") {
          console.log("Data changed");
        }
        updatePromise = this.saveData(property, secretChanged);
      } else {
        updatePromise = Promise.resolve(true);
      }

      const oldPropertyLocationMap = this.getLocationMap(oldProperty);
      const propertyLocationMap = this.getLocationMap(property);
      const changedLocations = this.getChangedLocations(
        oldPropertyLocationMap,
        propertyLocationMap
      );
      let locationEditPromise;
      if (changedLocations.length > 0) {
        if (process.env.NODE_ENV !== "production") {
          console.log("Location changed");
        }
        locationEditPromise = this.editLocations(
          property,
          changedLocations,
          secretChanged
        );
      } else {
        locationEditPromise = Promise.resolve(true);
      }

      Promise.all([secretPromise, updatePromise, locationEditPromise])
        .then(() => {
          this.$successNotice("Sparat");
          this.loading = false;
          this.$refs.showProperty.setEditFalse();
        })
        .catch(e => {
          this.$errorNotice("Något gick fel");
          this.loading = false;
          if (process.env.NODE_ENV !== "production") {
            console.log(e);
          }
        });
    },
    setCenter(lat, lng) {
      this.centerLat = parseFloat(lat);
      this.centerLng = parseFloat(lng);
    },
    navigateTo() {
      if (!this.navigate.includes(",")) {
        return;
      }
      const substrings = this.navigate.split(",");
      this.setCenter(substrings[0], substrings[1]);
    },
    resetSearchText() {
      this.searchText = "";
      this.addMarkers();
    }
  },
  watch: {
    loading() {
      if (this.loading) {
        this.$refs.sidebar.openLoadingSidebar();
      } else if (this.isMarkerSelected) {
        this.$refs.sidebar.openShowPropertySidebar();
      } else {
        this.$refs.sidebar.close();
      }
    },
    showSecret() {
      this.addMarkers();
    },
    showDuplicates() {
      this.addMarkers();
    }
  },
  computed: {
    isAuthorizedToRead: function() {
      return this.$store.getters.mapRoles.includes("read");
    },
    isAuthorizedToReadEncrypted: function() {
      return this.$store.getters.mapRoles.includes("read_encrypted");
    },
    isAuthorizedToManageDuplicates: function() {
      return this.$store.getters.mapRoles.includes("manage_duplicates");
    },
    isMarkerSelected() {
      return Object.keys(this.selectedMarker).length > 0;
    },
    googleMapsLink() {
      if (this.isMarkerSelected) {
        return this.getGoogleMapsLink(
          this.selectedMarker.position.lat,
          this.selectedMarker.position.lng
        );
      }
      return "";
    },
    selectedMarkerLocations() {
      if (!this.selectedMarkerProperty) {
        return [];
      }
      return this.selectedMarkerProperty.location;
    },
    selectedMarkerPosition() {
      return {
        lat: this.selectedMarker.position.lat,
        lng: this.selectedMarker.position.lng
      };
    },
    isNavigateCoordinate() {
      const regex = /\d+.\d+,(\s+)?\d+.\d+/;
      return regex.test(this.navigate);
    }
  },
  mounted() {
    if (this.isAuthorizedToRead) {
      this.getUserLocation().then(center => {
        this.setCenter(center.lat, center.lng);
      });
      this.getProperties();
    }
  }
};
</script>
<style lang="scss" scoped>
#mapWrapper {
  width: 100%;
  height: calc(100vh - 70px);
  display: flex;
  flex-flow: row wrap;
  #map {
    flex-grow: 1;
    width: auto;
  }
}
.marginTop {
  margin-top: 15px;
}
</style>
