<template>
  <div>
    <md-table v-model="dataShadow" class="user-statistics-table">
      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell md-label="">
          <div class="flag">
            <img :src="item.flag" />
          </div>
        </md-table-cell>
        <md-table-cell>{{ item.county }}</md-table-cell>
        <md-table-cell>{{ item.amount }} st</md-table-cell>
        <md-table-cell>{{ item.percent }} %</md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>

<script>
export default {
  name: "user-statistics-table",
  props: {
    tableData: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      dataShadow: this.tableData
    }
  }
};
</script>
<style lang="scss" scoped>
.user-statistics-table {
  .md-table-row:hover,
  .md-table-row:focus {
    background: transparent !important;
  }

  .md-table-row:first-child .md-table-cell {
    border: 0;
  }
  .md-table-cell:nth-child(3) .md-table-cell-container {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
