<template>
  <div>
    <div class="md-layout" v-if="disableStep">
      <div class="md-layout-item">
        <p>
          <b class="text-danger">Låst steg!</b> Du behöver fylla i övriga steg
          för att låsa upp detta.
        </p>
      </div>
    </div>
    <div class="md-layout" v-blur="disableStep">
      <div class="md-layout-item md-size-60 md-small-size-100">
        <p>
          Kontrollera att inga nya anmälningar och betalningar kommit in. I
          sådant fall behöver mejlet med PDF till prenumeranterna synkas.
        </p>
      </div>
      <div class="md-layout-item md-size-60 md-small-size-100">
        <date-field
          :initialDate.sync="dateTransfer"
          label="Kassör överför intäkter från PayPal till SEB"
          :datepicker="true"
          :disabled="disableStep || !isAuthorizedToApprove"
        />
      </div>
      <div class="md-layout-item md-size-40 md-small-size-100">
        <p>Fylls i av styrelsemedlem.</p>
      </div>
      <div class="md-layout-item md-size-60 md-small-size-100">
        <md-checkbox v-model="printCheckList" :disabled="disableStep">
          Checklista har skrivits ut
        </md-checkbox>
      </div>
      <div class="md-layout-item md-size-40 md-small-size-100">
        <p>
          Huvudarrangören ansvarar för att checklista finns på plats och i rätt
          antal.
        </p>
      </div>
      <div class="md-layout-item md-size-100">
        <md-progress-bar
          :class="completionClass"
          :md-value="completionScore"
        ></md-progress-bar>
      </div>
    </div>
  </div>
</template>
<script>
import DateField from "@/pages/Dashboard/Components/DateField.vue";
export default {
  components: {
    DateField
  },
  props: {
    initialDateTransfer: {
      required: true
    },
    initialPrintCheckList: {
      type: Boolean,
      required: true
    },
    disableStep: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      dateTransfer: this.initialDateTransfer,
      printCheckList: this.initialPrintCheckList
    };
  },
  methods: {
    updateDateTransfer: function() {
      this.$emit("update:initialDateTransfer", this.dateTransfer);
    },
    updatePrintCheckList: function() {
      this.$emit("update:initialPrintCheckList", this.printCheckList);
    }
  },
  watch: {
    dateTransfer() {
      this.updateDateTransfer();
    },
    printCheckList() {
      this.updatePrintCheckList();
    }
  },
  computed: {
    isAuthorizedToApprove: function() {
      return this.$store.getters.roles.includes("arrangement_write");
    },
    completionScore: function() {
      let score = 0;
      if (this.dateTransfer) {
        score += 100 / 2;
      }
      if (this.printCheckList) {
        score += 100 / 2;
      }
      this.$emit("score", score);
      return score;
    },
    completionClass: function() {
      if (this.completionScore < 50) {
        return "md-danger";
      }
      if (this.completionScore < 100) {
        return "md-warning";
      }
      return "md-success";
    }
  }
};
</script>
