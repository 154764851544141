<template>
  <div>
    <p v-if="data.length < 1">Inga ansökningar att visa.</p>
    <md-table v-else>
      <md-table-row>
        <md-table-head class="md-xsmall-hide">Grupp</md-table-head>
        <md-table-head>ID</md-table-head>
        <md-table-head>Namn</md-table-head>
        <md-table-head>Status</md-table-head>
        <md-table-head class="md-medium-hide">Utomstående</md-table-head>
        <md-table-head class="md-small-hide">Reserverade</md-table-head>
        <md-table-head class="md-xsmall-hide">Betalat</md-table-head>
        <md-table-head class="md-medium-hide">Skapad</md-table-head>
        <md-table-head class="text-right"></md-table-head>
      </md-table-row>
      <md-table-row v-for="item in data" v-bind:key="item.id">
        <md-table-cell class="md-xsmall-hide">
          {{ item.group }}
        </md-table-cell>
        <md-table-cell>
          {{ item.id }}
        </md-table-cell>
        <md-table-cell>
          {{ item.firstname | capitalize }}
          {{ item.lastname | capitalize }}
        </md-table-cell>
        <md-table-cell>
          <span>
            <md-icon>{{ getStatusIcon(item) }}</md-icon>
            <md-tooltip md-direction="right">{{
              getStatusHelp(item)
            }}</md-tooltip>
          </span>
        </md-table-cell>
        <md-table-cell class="md-medium-hide">
          {{ item.notMembers ? item.notMembers + " st" : "" }}
        </md-table-cell>
        <md-table-cell class="md-small-hide">
          {{ item.reservedSlots ? item.reservedSlots + " st" : "" }}
        </md-table-cell>
        <md-table-cell class="md-xsmall-hide">
          {{ item.total ? item.total + " kr" : "" }}
        </md-table-cell>
        <md-table-cell class="md-medium-hide">
          {{ item.created | formatDate }}
        </md-table-cell>
        <md-table-cell class="text-right">
          <md-button
            class="md-just-icon md-warning"
            @click="editApplication(item)"
            ><md-icon>edit</md-icon></md-button
          >
        </md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>
<script>
import moment from "moment";
export default {
  props: {
    data: {
      type: Array,
      required: true
    },
    fee: {
      type: Number,
      required: true
    }
  },
  methods: {
    getStatusIcon: function(item) {
      if (this.isAllSlotsPaid(item)) {
        return "done";
      } else if (item.reservedSlots > 0 && !this.isAllReservedSlotsPaid(item)) {
        return "money_off";
      } else if (item.confirmed) {
        return "timer";
      } else {
        return "timer_off";
      }
    },
    getStatusHelp: function(item) {
      if (this.isAllSlotsPaid(item)) {
        return "Alla platser är betalda";
      } else if (item.reservedSlots > 0 && !this.isAllReservedSlotsPaid(item)) {
        return "Reserverade platser är inte betalda";
      } else if (item.confirmed) {
        return "Väntar på reserverade platser";
      } else {
        return "Ansökan är inte bekräftad";
      }
    },
    isAllSlotsPaid: function(item) {
      return item.total >= (item.notMembers + 1) * this.fee;
    },
    isAllReservedSlotsPaid: function(item) {
      return item.total >= item.reservedSlots * this.fee;
    },
    moment: function() {
      return moment;
    },
    editApplication: function(item) {
      this.$emit("editApplication", item);
    }
  },
  filters: {
    capitalize: function(value) {
      if (!value) return "";
      let retVal = "";
      value
        .toString()
        .toLowerCase()
        .split(" ")
        .forEach(v => {
          if (retVal.length > 0) {
            retVal += " ";
          }
          retVal += v.charAt(0).toUpperCase() + v.slice(1);
        });
      return retVal;
    },
    formatDate: function(value) {
      let baseTimestamp = moment.utc("2019-11-13 20:00", "YYYY-MM-DD HH:mm");
      let parsed = moment.utc(value, "YYYY-MM-DD HH:mm");
      if (parsed.isBefore(baseTimestamp)) {
        return "";
      } else {
        return moment.utc(value).format("D/M HH:mm");
      }
    }
  }
};
</script>
