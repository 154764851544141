<template>
  <div class="md-layout" id="tourSetup">
    <div class="md-layout-item md-size-100" :hidden="!error">
      <div class="alert alert-danger">
        <span>{{ errorMessage }}</span>
      </div>
    </div>

    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>event</md-icon>
          </div>
        </md-card-header>
        <md-card-content>
          <md-button
            v-for="day in days"
            v-bind:key="day"
            class="md-primary"
            :class="[{ 'md-simple': day !== selectedDay }]"
            @click="selectDay(day)"
          >
            {{ day }}
          </md-button>
          <md-button class="md-primary md-just-icon md-round md-simple"
            ><md-icon>add</md-icon></md-button
          >
          <md-table v-model="firstTabs" id="phases-table">
            <md-table-row slot="md-table-row" slot-scope="{ item, index }">
              <md-table-cell>
                {{ item.timeStart }} - {{ item.timeEnd }}</md-table-cell
              >
              <md-table-cell>1 timme</md-table-cell>
              <md-table-cell>Visningsobjekt {{ index + 1 }}</md-table-cell>
              <md-table-cell>{{ item.facility }}</md-table-cell>
              <md-table-cell>
                <md-button class="md-just-icon md-simple md-primary">
                  <md-icon>edit</md-icon>
                  <md-tooltip md-direction="top">Edit</md-tooltip>
                </md-button>
                <md-button class="md-just-icon md-simple md-danger">
                  <md-icon>close</md-icon>
                  <md-tooltip md-direction="top">Close</md-tooltip>
                </md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>
          <md-button class="md-danger md-simple">Radera dag</md-button>
          <md-button class="md-success md-simple">Nytt delmoment</md-button>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      days: ["2019-09-19", "2019-09-20"],
      selectedDay: "2019-09-20",
      data: [],
      error: false,
      errorMessage: "Något gick fel",
      spinnerDiameter: 60,
      spinnerStroke: 6,
      loading: true,
      firstTabs: [
        {
          timeStart: "11:00",
          timeEnd: "12:00",
          facility: "Anläggning 1"
        },
        {
          timeStart: "12:00",
          timeEnd: "13:00",
          facility: "Anläggning 2"
        },
        {
          timeStart: "13:00",
          timeEnd: "14:00",
          facility: "Anläggning 3"
        }
      ]
    };
  },
  mounted: function() {
    this.loadData();
  },
  computed: {
    isAuthorizedToReadApplications: function() {
      return this.$store.getters.roles.includes("applications_read");
    },
    isAuthorizedToRead: function() {
      return this.$store.getters.roles.includes("arrangement_read");
    },
    isAuthorizedToWrite: function() {
      return this.$store.getters.roles.includes("arrangement_write");
    }
  },
  methods: {
    loadData: function() {
      if (!this.isAuthorizedToRead) {
        this.error = true;
        this.errorMessage = "Du har inte behörighet för denna sida!";
        return;
      }
      this.error = false;
      this.loading = true;
      this.axios
        .get("arrangement")
        .then(response => {
          let parsedData = [];
          response.data.forEach(function(item) {
            parsedData.push({
              id: item.id,
              title: item.title,
              location: item.location,
              status: item.status,
              date: item.date,
              organizer: item.organizer,
              organizers: item.organizers
            });
          });
          this.data = parsedData;
          this.loading = false;
        })
        .catch(() => {
          this.error = true;
          this.loading = false;
        });
    },
    selectDay: function(day) {
      this.selectedDay = day;
    }
  },
  filters: {
    capitalize: function(value) {
      if (!value) return "";
      value = value.toString().toLowerCase();
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
  }
};
</script>
<style lang="scss" scoped>
//.md-table /deep/ .md-table-cell:last-child {
.md-table .md-table-cell:last-child {
  text-align: right;
}
#phases-table {
  margin-top: 0.5em;
}
</style>
<style lang="scss">
#tourSetup {
  table thead {
    display: none;
  }
}
</style>
