<template>
  <div>
    <div v-if="edit">
      <div class="md-layout">
        <div class="md-layout-item">
          <div class="md-layout">
            <div class="md-layout-item text-center" :hidden="!loading">
              <md-progress-spinner
                md-mode="indeterminate"
                :md-diameter="60"
                :md-stroke="6"
              ></md-progress-spinner>
            </div>
          </div>
          <div
            class="md-layout"
            v-if="isAuthorizedToReadEncrypted && hasEncryptionKey"
          >
            <div class="md-layout-item">
              <md-checkbox v-model="secretCpy">
                Dold
              </md-checkbox>
            </div>
          </div>
          <div class="md-layout">
            <div class="md-layout-item">
              <md-field>
                <label>Namn</label>
                <md-input v-model="nameCpy" type="text"></md-input>
              </md-field>
            </div>
          </div>
          <div class="md-layout">
            <div class="md-layout-item">
              <md-field>
                <label>Beskrivning</label>
                <md-textarea v-model="descriptionCpy"></md-textarea>
              </md-field>
            </div>
          </div>
          <div class="md-layout">
            <div class="md-layout-item">
              <md-field>
                <label>Region</label>
                <md-input v-model="regionCpy" type="text"></md-input>
              </md-field>
            </div>
          </div>
          <div class="md-layout">
            <div class="md-layout-item">
              <md-field>
                <label>Registerområde</label>
                <md-input v-model="areaCpy" type="text"></md-input>
              </md-field>
            </div>
          </div>
          <div class="md-layout">
            <div class="md-layout-item">
              <md-field>
                <label>Traktnamn</label>
                <md-input v-model="lRegionCpy" type="text"></md-input>
              </md-field>
            </div>
          </div>
          <div class="md-layout">
            <div class="md-layout-item">
              <md-field>
                <label>Block</label>
                <md-input v-model="blockCpy" type="text"></md-input>
              </md-field>
            </div>
          </div>
          <div class="md-layout">
            <div class="md-layout-item">
              <md-field>
                <label>Enhet</label>
                <md-input v-model="unitCpy" type="text"></md-input>
              </md-field>
            </div>
          </div>
          <div class="md-layout edit-button">
            <div class="md-layout-item">
              <md-button class="md-success" @click="save" :disabled="loading">
                Spara
              </md-button>
            </div>
            <div class="md-layout-item">
              <md-button
                class="md-warning"
                @click="toggleEdit"
                :disabled="loading"
              >
                Avbryt
              </md-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="md-layout">
        <div class="md-layout-item">
          <h5 v-if="designation && designation.length > 0">
            {{ designation }}
          </h5>
          <h5 v-if="nameCpy && nameCpy.length > 0">
            {{ nameCpy }}
          </h5>
          <h6
            v-if="descriptionCpy && descriptionCpy.length > 0"
            class="nomargin prelinewhitespace"
          >
            {{ descriptionCpy }}
          </h6>
          <div :hidden="!isPropertyInfoSet">
            <br />
            <h6 class="nomargin">
              Existerar:
              {{ existingCpy ? "Ja" : "Nej" }}
            </h6>
            <h6 class="nomargin">
              FortV ägare 2014:
              {{ currentownerCpy ? "Ja" : "Nej" }}
            </h6>
          </div>
          <br />
          <h6 class="nomargin">
            <span class="pointer" @click="setCenter(lat, lng)">
              {{ lat | limitDecimals }},
              {{ lng | limitDecimals }}
            </span>
            <a :href="getGoogleMapsLink(lat, lng)" target="_blank">(G-Maps)</a>
          </h6>
          <div v-if="locations.length > 1">
            <br />
            <h6 class="nomargin">Positioner:</h6>
            <h6
              v-for="(location, index) in locations"
              :key="index"
              class="nomargin"
            >
              <confirm-icon
                @triggered="showDeleteWarning"
                @confirmed="deleteLocation(location.id)"
              />
              <span
                class="pointer"
                @click="setCenter(location.north_wgs, location.east_wgs)"
              >
                {{ location.north_wgs | limitDecimals }},
                {{ location.east_wgs | limitDecimals }}
              </span>
              <a
                :href="getGoogleMapsLink(location.north_wgs, location.east_wgs)"
                target="_blank"
                >(G-Maps)</a
              >
            </h6>
          </div>
        </div>
      </div>
      <div class="md-layout edit-button">
        <div class="md-layout-item">
          <confirm-icon
            @triggered="showDeleteWarning"
            @confirmed="deleteProperty(id)"
          />
          Ta bort fastighet
        </div>
      </div>
      <div class="md-layout edit-button">
        <div class="md-layout-item">
          <md-button class="md-warning" @click="toggleEdit">
            Redigera
          </md-button>
        </div>
        <div class="md-layout-item">
          <md-button class="md-success" @click="addLocation">
            Lägg till position
          </md-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ConfirmIcon from "../../Components/ConfirmIcon";
export default {
  components: {
    ConfirmIcon
  },
  props: {
    id: {
      type: Number,
      default: 0
    },
    secret: {
      type: Boolean,
      default: false
    },
    designation: {
      type: String,
      default: ""
    },
    area: {
      type: String,
      default: ""
    },
    region: {
      type: String,
      default: ""
    },
    lregion: {
      type: String,
      default: ""
    },
    block: {
      type: String,
      default: ""
    },
    unit: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    description: {
      type: String,
      default: ""
    },
    existing: {
      type: Boolean,
      default: false
    },
    currentowner: {
      type: Boolean,
      default: false
    },
    locations: {
      type: Array,
      default: () => []
    },
    lat: {
      type: Number,
      default: 0
    },
    lng: {
      type: Number,
      default: 0
    },
    hasEncryptionKey: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      edit: false,
      loading: false,
      secretCpy: this.secret,
      areaCpy: this.area,
      regionCpy: this.region,
      lRegionCpy: this.lregion,
      blockCpy: this.block,
      unitCpy: this.unit,
      nameCpy: this.name,
      descriptionCpy: this.description,
      existingCpy: this.existing,
      currentownerCpy: this.currentowner
    };
  },
  watch: {
    secret() {
      this.secretCpy = this.secret;
    },
    area() {
      this.areaCpy = this.area;
    },
    region() {
      this.regionCpy = this.region;
    },
    lregion() {
      this.lRegionCpy = this.lregion;
    },
    block() {
      this.blockCpy = this.block;
    },
    unit() {
      this.unitCpy = this.unit;
    },
    name() {
      this.nameCpy = this.name;
    },
    description() {
      this.descriptionCpy = this.description;
    },
    existing() {
      this.existingCpy = this.existing;
    },
    currentowner() {
      this.currentownerCpy = this.currentowner;
    }
  },
  computed: {
    googleMapsLink() {
      return this.getGoogleMapsLink(this.lat, this.lng);
    },
    isAuthorizedToReadEncrypted: function() {
      return this.$store.getters.mapRoles.includes("read_encrypted");
    },
    isPropertyInfoSet() {
      return (
        (this.areaCpy && this.areaCpy.length > 0) ||
        (this.regionCpy && this.regionCpy.length > 0) ||
        (this.lRegionCpy && this.lRegionCpy.length > 0) ||
        (this.blockCpy && this.blockCpy.length > 0) ||
        (this.unitCpy && this.unitCpy.length > 0)
      );
    }
  },
  methods: {
    setCenter(lat, lng) {
      this.$emit("center", lat, lng);
    },
    getGoogleMapsLink(lat, lng) {
      return "https://www.google.com/maps/place/" + lat + "," + lng;
    },
    setEditFalse() {
      this.edit = false;
    },
    toggleEdit() {
      this.edit = !this.edit;
      this.$emit("edit", this.edit);
      if (this.edit) {
        this.$infoNotice(
          "Uppdatera positionen genom att flytta den gröna markören på kartan"
        );
      }
    },
    addLocation() {
      this.$emit("addLocation");
    },
    save() {
      this.$emit("update", {
        designation:
          this.areaCpy +
          " " +
          this.lRegionCpy +
          " " +
          this.blockCpy +
          " " +
          this.unitCpy,
        region: this.regionCpy,
        l_area: this.areaCpy,
        l_region: this.lRegionCpy,
        l_block: this.blockCpy,
        l_unit: this.unitCpy,
        name: this.nameCpy,
        description: this.descriptionCpy,
        existing: this.existingCpy,
        currentowner: this.currentownerCpy,
        secret: this.secretCpy
      });
    },
    showDeleteWarning() {
      this.$infoNotice("Klicka igen för att ta bort");
    },
    deleteLocation(locationId) {
      this.$emit("deleteLocation", locationId);
    },
    deleteProperty(id) {
      this.$emit("deleteProperty", id);
    }
  },
  filters: {
    limitDecimals(value) {
      if (!value || isNaN(value)) {
        return "";
      }
      return Number(value).toFixed(6);
    }
  }
};</script
><style lang="scss">
.edit-button {
  margin-top: 1em;
}
.pointer {
  cursor: pointer;
}
.nomargin {
  margin: 0 0 0 0;
}
.prelinewhitespace {
  white-space: pre-line;
}
</style>
