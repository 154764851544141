<template>
  <form class="form-horizontal" action="" method="post">
    <div class="md-layout">
      <div class="md-layout-item">
        <md-field
          :class="[
            { 'md-error': errors.has('rafflingDateOpen') },
            {
              'md-valid':
                !errors.has('rafflingDateOpen') && touched.rafflingDateOpen
            }
          ]"
        >
          <label>Startdatum</label>
          <md-input
            v-model="rafflingDateOpen"
            data-vv-name="rafflingDateOpen"
            type="text"
            v-validate="modelValidations.rafflingDateOpen"
            required
            @input="updateRafflingDateOpen"
            :disabled="!isAuthorizedToWrite"
          >
          </md-input>
          <slide-y-down-transition>
            <md-icon class="error" v-show="errors.has('rafflingDateOpen')">
              close</md-icon
            >
          </slide-y-down-transition>
          <slide-y-down-transition>
            <md-icon
              class="success"
              v-show="
                !errors.has('rafflingDateOpen') && touched.rafflingDateOpen
              "
            >
              done</md-icon
            >
          </slide-y-down-transition>
        </md-field>
      </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item">
        <md-field
          :class="[
            { 'md-error': errors.has('rafflingDateClose') },
            {
              'md-valid':
                !errors.has('rafflingDateClose') && touched.rafflingDateClose
            }
          ]"
        >
          <label>Slutdatum</label>
          <md-input
            v-model="rafflingDateClose"
            data-vv-name="rafflingDateClose"
            type="text"
            v-validate="modelValidations.rafflingDateClose"
            required
            @input="updateRafflingDateClose"
            :disabled="!isAuthorizedToWrite"
          >
          </md-input>
          <slide-y-down-transition>
            <md-icon class="error" v-show="errors.has('rafflingDateClose')"
              >close</md-icon
            >
          </slide-y-down-transition>
          <slide-y-down-transition>
            <md-icon
              class="success"
              v-show="
                !errors.has('rafflingDateClose') && touched.rafflingDateClose
              "
            >
              done</md-icon
            >
          </slide-y-down-transition>
        </md-field>
      </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item">
        <p>
          Startdatum rekommenderas vara minst 7 dagar efter publicering av
          arrangemanget för att lämna tid för anmälan. Slutdatum rekommenderas
          vara 2 dagar innan arrangemanget, d.v.s.
          {{ recommendedRafflingDateClose }}.
        </p>
      </div>
    </div>
  </form>
</template>
<script>
import { SlideYDownTransition } from "vue2-transitions";
import moment from "moment";
export default {
  components: {
    SlideYDownTransition
  },
  props: {
    arrangementDate: {
      type: String,
      required: true
    },
    intitialRafflingDateOpen: {
      type: String,
      required: true
    },
    intitialRafflingDateClose: {
      type: String,
      required: true
    },
    isAuthorizedToWrite: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      rafflingDateOpen: this.intitialRafflingDateOpen,
      rafflingDateClose: this.intitialRafflingDateClose,
      touched: {
        rafflingDateOpen: false,
        rafflingDateClose: false
      },
      modelValidations: {
        rafflingDateOpen: {
          required: true,
          date: true
        },
        rafflingDateClose: {
          required: true,
          rafflingDateClose: true
        }
      }
    };
  },
  created() {
    this.$validator.extend("date", {
      getMessage: field => `${field} is not a valid date.`,
      validate: value =>
        new Promise(resolve => {
          resolve({
            valid: value && this.isValidDate(value)
          });
        })
    });
    this.$validator.extend("rafflingDateClose", {
      getMessage: () => "raffling close date is not correct",
      validate: value =>
        new Promise(resolve => {
          resolve({
            valid: value && this.isRafflingDateCloseCorrect()
          });
        })
    });
  },
  watch: {
    rafflingDateOpen: function() {
      this.$validator.validate("rafflingDateClose");
    },
    rafflingDateClose: function() {
      this.$validator.validate("rafflingDateOpen");
    }
  },
  computed: {
    recommendedRafflingDateClose: function() {
      return moment
        .utc(this.arrangementDate)
        .subtract(2, "days")
        .format("YYYY-MM-DD");
    }
  },
  methods: {
    updateRafflingDateOpen: function(value) {
      this.$emit("update:intitialRafflingDateOpen", value);
    },
    updateRafflingDateClose: function(value) {
      this.$emit("update:intitialRafflingDateClose", value);
    },
    moment: function() {
      return moment;
    },
    isRafflingDateCloseCorrect: function() {
      let d = moment.utc(this.rafflingDateClose, "YYYY-MM-DD", true);
      return (
        d.isAfter(moment.utc(this.rafflingDateOpen, "YYYY-MM-DD")) &&
        d.isSameOrBefore(moment.utc(this.arrangementDate, "YYYY-MM-DD"))
      );
    },
    isValidDate: function(d) {
      return moment.utc(d, "YYYY-MM-DD", true).isValid();
    }
  }
};
</script>
