<template>
  <form class="form-horizontal" action="" method="post">
    <div class="md-layout">
      <div class="md-layout-item">
        <md-field
          :class="[
            { 'md-error': errors.has('title') },
            { 'md-valid': !errors.has('title') && touched.title }
          ]"
        >
          <label>Titel</label>
          <md-input
            :value="title"
            data-vv-name="title"
            type="text"
            v-validate="modelValidations.title"
            required
            @input="updateTitle"
            :disabled="!isAuthorizedToWrite"
          >
          </md-input>
          <slide-y-down-transition>
            <md-icon class="error" v-show="errors.has('title')">close</md-icon>
          </slide-y-down-transition>
          <slide-y-down-transition>
            <md-icon
              class="success"
              v-show="!errors.has('title') && touched.title"
              >done</md-icon
            >
          </slide-y-down-transition>
        </md-field>
      </div>
      <div class="md-layout-item">
        <md-field
          :class="[
            { 'md-error': errors.has('date') },
            { 'md-valid': !errors.has('date') && touched.date }
          ]"
        >
          <label>Datum</label>
          <md-input
            v-model="date"
            data-vv-name="date"
            type="text"
            v-validate="modelValidations.date"
            required
            @input="updateDate"
            :disabled="!isAuthorizedToWrite"
          >
          </md-input>
          <slide-y-down-transition>
            <md-icon class="error" v-show="errors.has('date')">close</md-icon>
          </slide-y-down-transition>
          <slide-y-down-transition>
            <md-icon
              class="success"
              v-show="!errors.has('date') && touched.date"
              >done</md-icon
            >
          </slide-y-down-transition>
        </md-field>
      </div>
      <div class="md-layout-item">
        <md-field
          :class="[
            { 'md-error': errors.has('time') },
            {
              'md-valid': !errors.has('time') && touched.time
            }
          ]"
        >
          <label>Tid</label>
          <md-input
            v-model="time"
            data-vv-name="time"
            type="text"
            v-validate="modelValidations.time"
            required
            @input="updateTime"
            :disabled="!isAuthorizedToWrite"
          >
          </md-input>
          <slide-y-down-transition>
            <md-icon class="error" v-show="errors.has('time')">close</md-icon>
          </slide-y-down-transition>
          <slide-y-down-transition>
            <md-icon
              class="success"
              v-show="!errors.has('time') && touched.time"
              >done</md-icon
            >
          </slide-y-down-transition>
        </md-field>
      </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item">
        <md-field
          :class="[
            { 'md-error': errors.has('location') },
            {
              'md-valid': !errors.has('location') && touched.location
            }
          ]"
        >
          <label>Plats</label>
          <md-input
            :value="location"
            data-vv-name="location"
            type="text"
            v-validate="modelValidations.location"
            required
            @input="updateLocation"
            :disabled="!isAuthorizedToWrite"
          >
          </md-input>
          <slide-y-down-transition>
            <md-icon class="error" v-show="errors.has('location')"
              >close</md-icon
            >
          </slide-y-down-transition>
          <slide-y-down-transition>
            <md-icon
              class="success"
              v-show="!errors.has('location') && touched.location"
              >done</md-icon
            >
          </slide-y-down-transition>
        </md-field>
      </div>
      <div class="md-layout-item">
        <md-field
          :class="[
            { 'md-error': errors.has('slots') },
            { 'md-valid': !errors.has('slots') && touched.slots }
          ]"
        >
          <label>Personer</label>
          <md-input
            v-model="slots"
            data-vv-name="slots"
            type="number"
            v-validate="modelValidations.slots"
            required
            @input="updateSlots"
            :disabled="!isAuthorizedToWrite"
          >
          </md-input>
          <slide-y-down-transition>
            <md-icon class="error" v-show="errors.has('slots')">close</md-icon>
          </slide-y-down-transition>
          <slide-y-down-transition>
            <md-icon
              class="success"
              v-show="!errors.has('slots') && touched.slots"
              >done</md-icon
            >
          </slide-y-down-transition>
        </md-field>
      </div>
      <div class="md-layout-item">
        <md-field
          :class="[
            { 'md-error': errors.has('fee') },
            { 'md-valid': !errors.has('fee') && touched.fee }
          ]"
        >
          <label>Avgift</label>
          <md-input
            v-model="fee"
            data-vv-name="fee"
            type="number"
            v-validate="modelValidations.fee"
            required
            @input="updateFee"
            :disabled="!isAuthorizedToWrite"
          >
          </md-input>
          <slide-y-down-transition>
            <md-icon class="error" v-show="errors.has('fee')">close</md-icon>
          </slide-y-down-transition>
          <slide-y-down-transition>
            <md-icon class="success" v-show="!errors.has('fee') && touched.fee"
              >done</md-icon
            >
          </slide-y-down-transition>
        </md-field>
      </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item md-size-33">
        <md-field>
          <label>Status</label>
          <md-select
            v-model="status"
            required
            :disabled="!isAuthorizedToWrite || !isAuthorizedToChangeStatus"
            @md-selected="updateStatus"
          >
            <md-option value="utkast">Utkast</md-option>
            <md-option value="planerad">Planerad</md-option>
            <md-option value="inställd">Inställd</md-option>
            <md-option value="avslutad">Avslutad</md-option>
          </md-select>
        </md-field>
      </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item">
        <p>
          Titeln bör vara beskrivande men kort och inte innehålla ord som t.ex.
          arrangemang, visning, eller dylikt. Platsen ska anges geografiskt
          ungefärlig och på en högre nivå, t.ex. stad eller landskap. För priset
          föreligger en ekonomisk kalkyl. Enbart styrelsen kan flytta
          arrangemang från status utkast.
        </p>
      </div>
    </div>
  </form>
</template>
<script>
import { SlideYDownTransition } from "vue2-transitions";
import moment from "moment";
export default {
  components: {
    SlideYDownTransition
  },
  props: {
    initialTitle: {
      type: String,
      required: true
    },
    initialDate: {
      type: String,
      required: true
    },
    initialTime: {
      type: String,
      required: true
    },
    initialLocation: {
      type: String,
      required: true
    },
    initialStatus: {
      type: String,
      required: true
    },
    initialSlots: {
      type: Number,
      required: true
    },
    initialFee: {
      type: Number,
      required: true
    },
    isAuthorizedToWrite: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      title: this.initialTitle,
      date: this.initialDate,
      time: this.initialTime,
      location: this.initialLocation,
      status: this.initialStatus,
      slots: this.initialSlots,
      fee: this.initialFee,
      touched: {
        title: false,
        date: false,
        time: false,
        location: false,
        slots: false,
        fee: false
      },
      modelValidations: {
        title: {
          required: true,
          alpha_spaces: true
        },
        date: {
          required: true,
          date: true
        },
        time: {
          required: true,
          regex: /^(\d\d:\d\d)\s-\s(\d\d:\d\d)$|^(\d\d:\d\d)\s-$|^(\d\d:\d\d)$/
        },
        location: {
          required: true,
          alpha: true
        },
        slots: {
          required: true,
          numeric: true
        },
        fee: {
          required: true,
          numeric: true
        }
      }
    };
  },
  created() {
    this.$validator.extend("date", {
      getMessage: field => `${field} is not a valid date.`,
      validate: value =>
        new Promise(resolve => {
          resolve({
            valid: value && this.isValidDate(value)
          });
        })
    });
  },
  computed: {
    isAuthorizedToChangeStatus: function() {
      return (
        this.status != "utkast" ||
        this.$store.getters.roles.includes("arrangement_write")
      );
    }
  },
  methods: {
    isValidDate: function(d) {
      return moment.utc(d, "YYYY-MM-DD", true).isValid();
    },
    moment: function() {
      return moment;
    },
    updateTitle: function(value) {
      this.title = this.capitalizeFirst(value);
      this.$emit("update:initialTitle", this.title);
    },
    updateDate: function() {
      this.$emit("update:initialDate", this.date);
    },
    updateTime: function() {
      this.$emit("update:initialTime", this.time);
    },
    updateLocation: function(value) {
      this.location = this.capitalize(value);
      this.$emit("update:initialLocation", this.location);
    },
    updateSlots: function() {
      this.$emit("update:initialSlots", Number(this.slots));
    },
    updateFee: function() {
      this.$emit("update:initialFee", Number(this.fee));
    },
    updateStatus: function() {
      this.$emit("update:initialStatus", this.status);
    },
    capitalizeFirst: function(value) {
      if (!value) return "";
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    capitalize: function(value) {
      if (!value) return "";
      let retVal = "";
      value
        .toString()
        .toLowerCase()
        .split(" ")
        .forEach(v => {
          if (retVal.length > 0) {
            retVal += " ";
          }
          retVal += v.charAt(0).toUpperCase() + v.slice(1);
        });
      return retVal;
    }
  }
};
</script>
