<template>
  <modal @close="modalHide">
    <template slot="header">
      <h4 class="modal-title">{{ firstname }} {{ lastname }}</h4>
      <md-button
        class="md-simple md-just-icon md-round modal-default-button"
        @click="modalHide"
      >
        <md-icon>clear</md-icon>
      </md-button>
    </template>

    <template slot="body">
      <div v-show="loadingModal">
        <p>
          <md-progress-spinner
            md-mode="indeterminate"
            :md-diameter="spinnerDiameter"
            :md-stroke="spinnerStroke"
          ></md-progress-spinner>
        </p>
      </div>
      <div v-show="!loadingModal">
        <div class="md-layout">
          <label class="md-layout-item md-size-15 md-form-label">
            Bedömning
          </label>
          <div class="md-layout-item">
            <div class="md-layout">
              <div class="md-layout-item">
                <md-switch
                  :disabled="emailValid == 'NOK'"
                  v-model="acceptedSwitch"
                  @change="inputSwitchChange('ACCEPTED')"
                  >Godkänd</md-switch
                >
              </div>
            </div>
            <div class="md-layout">
              <div class="md-layout-item">
                <md-switch
                  :disabled="emailValid == 'NOK'"
                  v-model="rejectedSwitch"
                  @change="inputSwitchChange('REJECTED')"
                  >Avvisad</md-switch
                >
              </div>
            </div>
          </div>
        </div>
        <div class="md-layout" v-show="!loadingModal && rejectedSwitch">
          <label class="md-layout-item md-size-15 md-form-label">
            Motivering
          </label>
          <div class="md-layout-item">
            <md-field>
              <md-textarea v-model="statusMotivation"></md-textarea>
            </md-field>
          </div>
        </div>
      </div>
    </template>

    <template slot="footer">
      <md-button
        class="md-success md-simple"
        v-show="!loadingModal"
        @click="judge"
        >Utför</md-button
      >
      <md-button class="md-danger md-simple" @click="modalHide"
        >Stäng</md-button
      >
    </template>
  </modal>
</template>
<script>
import { Modal } from "@/components";
export default {
  components: {
    Modal
  },
  props: {
    id: {
      type: Number,
      default: -1
    },
    email: {
      type: String,
      default: ""
    },
    firstname: {
      type: String,
      default: ""
    },
    lastname: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      loadingModal: true,
      spinnerDiameter: 60,
      spinnerStroke: 6,
      statusMotivation: "Din ansökan ...",
      acceptedSwitch: true,
      rejectedSwitch: false
    };
  },
  mounted: function() {
    //this.validateEmail();
    this.loadingModal = false;
    this.emailValid = "OK";
  },
  methods: {
    inputSwitchChange: function(sw) {
      if (sw == "ACCEPTED") {
        this.acceptedSwitch = true;
        this.rejectedSwitch = false;
      } else if (sw == "REJECTED") {
        this.acceptedSwitch = false;
        this.rejectedSwitch = true;
      }
    },
    modalHide: function() {
      this.$emit("hide");
    },
    filterList: function() {
      this.$emit("filterSelected");
    },
    validateEmail: function() {
      this.axios
        .post("https://dashboard.svbefs.se/services/mail/api/mail/validate", {
          email: this.email
        })
        .then(response => {
          if (response.data.result == "DELIVERABLE") {
            this.emailValid = "OK";
          } else {
            this.emailValid = "NOK";
            this.acceptedSwitch = false;
            this.rejectedSwitch = true;
            this.statusMotivation =
              "Felaktig e-post. Notifiering via mejl kommer ej skickas.";
          }
          this.loadingModal = false;
        })
        .catch(() => {
          this.setError("Något gick fel");
        });
    },
    judge: function() {
      this.loadingModal = true;
      if (this.acceptedSwitch) {
        this.axios
          .post("internal/subscribers/" + this.id + "/registration/accept")
          .then(() => {
            this.axios
              .post("https://dashboard.svbefs.se/services/mail/api/mail/user", {
                id: this.id,
                subject: "Ang. din ansökan om medlemskap",
                html:
                  'Hej,<br /><br />Vi har godkänt din ansökan om medlemskap!<br /><br />Du kan nu logga in på medlemsportalen (<a href="https://www.fortifikationvast.se/medlemsportal">https://www.fortifikationvast.se/medlemsportal/login.php</a>).<br /><br />Med vänliga hälsningar,<br /><br/>Organisationen Fortifikation-Väst<br /><br />Hemsida: <a href="https://www.fortifikationvast.se">www.fortifikationvast.se</a><br />Facebook: <a href="https://www.facebook.com/fortifikationvast">www.facebook.com/fortifikationvast</a><br />Instagram: <a href="https://www.instagram.com/fortifikationvast.se">www.instagram.com/fortifikationvast.se</a>',
                text:
                  "Hej,\r\n\r\nVi har godkänt din ansökan om medlemskap!\r\n\r\nDu kan nu logga in på medlemsportalen (https://www.fortifikationvast.se/medlemsportal).\r\n\r\nMed vänliga hälsningar,\r\n\r\nOrganisationen Fortifikation-Väst\r\n\r\nHemsida: www.fortifikationvast.se\r\nFacebook: www.facebook.com/fortifikationvast\r\nInstagram: www.instagram.com/fortifikationvast.se"
              })
              .then(() => {
                this.setSuccess(
                  this.firstname +
                    " " +
                    this.lastname +
                    " har godkänts som medlem"
                );
                this.filterList();
                this.modalHide();
                this.loadingModal = false;
              })
              .catch(() => {
                this.setError(
                  this.firstname +
                    " " +
                    this.lastname +
                    " har godkänts som medlem, men mejlet kunde inte skickas"
                );
                this.loadingModal = false;
              });
          })
          .catch(() => {
            this.setError("Något gick fel");
            this.loadingModal = false;
          });
      } else {
        this.axios
          .post("internal/subscribers/" + this.id + "/registration/reject", {
            rejectJustification: this.statusMotivation
          })
          .then(() => {
            if (this.emailValid == "OK") {
              this.axios
                .post(
                  "https://dashboard.svbefs.se/services/mail/api/mail/user",
                  {
                    id: this.id,
                    subject: "Ang. din ansökan om medlemskap",
                    html:
                      "Hej,<br /><br />Vi måste tyvärr meddela att vi nekat din ansökan om medlemskap.<br /><br />Motivering: " +
                      this.statusMotivation +
                      '<br /><br />Anser du att vi gjort fel i vår bedömning så är du välkommen att kontakta oss på medlem@fortifikationvast.se.<br /><br />Med vänliga hälsningar,<br /><br/>Organisationen Fortifikation-Väst<br /><br />Hemsida: <a href="https://www.fortifikationvast.se">www.fortifikationvast.se</a><br />Facebook: <a href="https://www.facebook.com/fortifikationvast">www.facebook.com/fortifikationvast</a><br />Instagram: <a href="https://www.instagram.com/fortifikationvast.se">www.instagram.com/fortifikationvast.se</a>',
                    text:
                      "Hej,\r\n\r\nVi måste tyvärr meddela att vi nekat din ansökan om medlemskap.\r\n\r\nMotivering: " +
                      this.statusMotivation +
                      "\r\n\r\nAnser du att vi gjort fel i vår bedömning så är du välkommen att kontakta oss på medlem@fortifikationvast.se.\r\n\r\nMed vänliga hälsningar,\r\n\r\nOrganisationen Fortifikation-Väst\r\n\r\nHemsida: www.fortifikationvast.se\r\nFacebook: www.facebook.com/fortifikationvast\r\nInstagram: www.instagram.com/fortifikationvast.se"
                  }
                )
                .then(() => {
                  this.setSuccess(
                    this.firstname +
                      " " +
                      this.lastname +
                      " har avvisats som medlem"
                  );
                  this.filterList();
                  this.modalHide();
                  this.loadingModal = false;
                })
                .catch(() => {
                  this.setError(
                    this.firstname +
                      " " +
                      this.lastname +
                      " har avvisats som medlem, men mejlet kunde inte skickas"
                  );
                  this.loadingModal = false;
                });
            } else {
              this.setSuccess(
                this.firstname +
                  " " +
                  this.lastname +
                  " har avvisats som medlem"
              );
              this.filterList();
              this.modalHide();
              this.loadingModal = false;
            }
          })
          .catch(() => {
            this.setError("Något gick fel");
            this.loadingModal = false;
          });
      }
    },
    setError: function(text) {
      this.$notify({
        message: text,
        icon: "error",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "danger",
        timeout: 5000
      });
    },
    setSuccess: function(text) {
      this.$notify({
        message: text,
        icon: "check_circle",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "success",
        timeout: 5000
      });
    }
  }
};
</script>
