<template>
  <div>
    <div :hidden="!loading">
      <div class="md-layout">
        <div class="md-layout-item text-center">
          <md-progress-spinner
            md-mode="indeterminate"
            :md-diameter="spinnerDiameter"
            :md-stroke="spinnerStroke"
          ></md-progress-spinner>
        </div>
      </div>
    </div>
    <div :hidden="loading">
      <md-table v-model="data" :hidden="data.length < 1">
        <md-table-row slot="md-table-row" slot-scope="{ item }">
          <md-table-cell md-label="Datum">
            {{ item.createdOn | formatDate }}
          </md-table-cell>
          <md-table-cell md-label="Ämne">{{ item.subject }}</md-table-cell>
          <md-table-cell md-label="" class="text-right">
            <md-button
              class="md-just-icon md-success"
              @click="mailSelected(item.id)"
              ><md-icon>search</md-icon></md-button
            >
          </md-table-cell>
        </md-table-row>
      </md-table>
      <p :hidden="data.length > 0">Kunde inte hitta några brev.</p>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  data() {
    return {
      data: [],
      spinnerDiameter: 60,
      spinnerStroke: 6,
      loading: true
    };
  },
  mounted: function() {
    this.loadData();
  },
  methods: {
    loadData: function() {
      this.loading = true;
      this.axios
        .get("https://dashboard.svbefs.se/services/mail/api/mail/batch/global")
        .then(response => {
          let parsedData = [];
          response.data.mail.forEach(function(item) {
            parsedData.push({
              id: item.id,
              createdOn: item.createdOn,
              subject: item.subject,
              contentHtml: item.contentHtml
            });
          });
          this.data = parsedData;
          this.loading = false;
        })
        .catch(() => {
          this.setError("Något gick fel");
          this.loading = false;
        });
    },
    moment: function() {
      return moment;
    },
    setError: function(text) {
      this.$notify({
        message: text,
        icon: "error",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "danger",
        timeout: 5000
      });
    },
    mailSelected: function(id) {
      this.$emit("mailSelected", this.data.filter(item => item.id == id)[0]);
    }
  },
  filters: {
    formatDate: function(value) {
      return moment.utc(value).format("YYYY-MM-DD HH:MM");
    }
  }
};
</script>
