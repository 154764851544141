<template>
  <md-card class="md-card-profile">
    <div class="md-card-avatar">
      <img class="img" :src="cardUserImage" />
    </div>

    <md-card-content>
      <h6 class="category text-gray">{{ title }}</h6>
      <h4 class="card-title">{{ firstName }} {{ lastName }}</h4>
      <p class="card-description">
        {{ email }}
        <br />
        {{ phone | toPhoneNumber }}
      </p>
    </md-card-content>
  </md-card>
</template>
<script>
import { parsePhoneNumber } from 'awesome-phonenumber'
export default {
  name: "user-card",
  props: {
    cardUserImage: {
      type: String,
      default: "./img/faces/marc.jpg"
    },
    buttonColor: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      title: "",
      phone: "",
      email: ""
    };
  },
  mounted: function() {
    this.loadData();
  },
  methods: {
    getColorButton: function(buttonColor) {
      return "md-" + buttonColor + "";
    },
    loadData: function() {
      this.$store.getters.keyCloak.loadUserProfile().then(
        function(profile) {
          let attr = profile.attributes;
          let title = attr["title"];
          let phone = attr["phone"];
          this.title = !title || title.length < 1
            ? ""
            : title[0];
          this.phone = !phone || phone.length < 1
            ? ""
            : phone[0];
          this.email = profile.email;
        }.bind(this)
      );
    }
  },
  computed: {
    firstName() {
      return this.$store.getters.firstName;
    },
    lastName() {
      return this.$store.getters.lastName;
    }
  },
  filters: {
    toPhoneNumber: function(value) {
      if (!value) return "";
      const pn = parsePhoneNumber( value, { regionCode: 'SE' } );
      return pn.number.national;
    }
  }
};
</script>
<style></style>
