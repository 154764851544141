<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-text md-card-header-green">
          <div class="card-icon">
            <md-icon>search</md-icon>
          </div>
        </md-card-header>

        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item">
              <md-field>
                <label>Ange sökord</label>
                <md-input
                  v-model="freeTextSearch"
                  type="text"
                  v-on:keyup="inputSearchKeyUp"
                  v-on:keydown="inputSearchKeyDown"
                ></md-input>
                <div :hidden="!searching">
                  <md-progress-spinner
                    md-mode="indeterminate"
                    :md-diameter="spinnerDiameter"
                    :md-stroke="spinnerStroke"
                  ></md-progress-spinner>
                </div>
                <span class="md-helper-text" :hidden="freeTextSearch.length > 2"
                  >Ange minst 3 tecken</span
                >
              </md-field>
            </div>
          </div>
          <div class="md-layout">
            <div class="md-layout-item md-size-85">
              <div class="md-layout">
                <div class="md-layout-item md-xsmall-size-100">
                  <md-switch
                    v-model="acceptedSwitch"
                    @change="inputSwitchChange('ACCEPTED')"
                    >Godkända</md-switch
                  >
                </div>
                <div class="md-layout-item md-xsmall-size-100">
                  <md-switch
                    v-model="rejectedSwitch"
                    @change="inputSwitchChange('REJECTED')"
                    >Avvisade</md-switch
                  >
                </div>
                <div class="md-layout-item md-xsmall-size-100">
                  <md-switch
                    v-model="waitingSwitch"
                    @change="inputSwitchChange('WAITING')"
                    >Väntande</md-switch
                  >
                </div>
              </div>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-text md-card-header-green">
          <div class="card-icon">
            <md-icon>people</md-icon>
          </div>
        </md-card-header>
        <md-card-content :hidden="searchData.length < 1">
          <md-table>
            <md-table-row>
              <md-table-head md-numeric>Id</md-table-head>
              <md-table-head>Förnamn</md-table-head>
              <md-table-head>Efternamn</md-table-head>
              <md-table-head class="md-small-hide">E-post</md-table-head>
              <md-table-head class="md-xsmall-hide">Status</md-table-head>
              <md-table-head class="text-right"></md-table-head>
            </md-table-row>
            <md-table-row v-for="item in searchData" v-bind:key="item.id">
              <md-table-cell md-numeric>{{ item.id }}</md-table-cell>
              <md-table-cell>{{ item.firstname }}</md-table-cell>
              <md-table-cell>{{ item.lastname }}</md-table-cell>
              <md-table-cell class="md-small-hide">{{
                item.email
              }}</md-table-cell>
              <md-table-cell class="md-xsmall-hide">
                <md-icon
                  class="member-status-icon-accepted"
                  v-if="item.accepted == 1"
                  >check</md-icon
                >
                <md-icon
                  class="member-status-icon-accepted"
                  v-else-if="item.accepted == 0"
                  >access_time</md-icon
                >
                <md-icon
                  class="member-status-icon-accepted"
                  v-else-if="item.accepted == -1"
                  >close</md-icon
                >
              </md-table-cell>
              <md-table-cell class="text-right">
                <md-button
                  class="md-just-icon md-success"
                  :to="{ path: '/member/' + item.id }"
                  ><md-icon>person</md-icon></md-button
                >
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
        <md-card-content :hidden="searchData.length > 0">
          <p>Inga sökresultat</p>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      inputSearchTimer: null,
      freeTextSearch: "",
      searchData: [],
      searching: false,
      spinnerDiameter: 20,
      spinnerStroke: 3,
      acceptedSwitch: true,
      rejectedSwitch: false,
      waitingSwitch: false
    };
  },
  computed: {
    isAuthorizedToRead: function() {
      return this.$store.getters.roles.includes("user_read");
    }
  },
  methods: {
    inputSwitchChange: function(sw) {
      if (!this.acceptedSwitch && !this.rejectedSwitch && !this.waitingSwitch) {
        switch (sw) {
          case "ACCEPTED":
            this.acceptedSwitch = true;
            break;
          case "REJECTED":
            this.rejectedSwitch = true;
            break;
          case "WAITING":
            this.waitingSwitch = true;
            break;
          default:
            break;
        }
        this.setError("Du måste ange minst ett filter!");
      } else {
        this.inputSearch();
      }
    },
    inputSearch: function() {
      if (!this.isAuthorizedToRead) {
        this.setError("Du har inte behörighet för denna sida!");
        return;
      }

      if (this.freeTextSearch.length < 3) {
        return;
      }

      this.searching = true;
      this.axios
        .post("internal/subscribers",
        {
          accepted: this.acceptedSwitch,
          rejected: this.rejectedSwitch,
          awaiting: this.waitingSwitch,
          searchString: this.freeTextSearch
        })
        .then(response => {
          let parsedData = [];
          response.data.forEach(function(item) {
            parsedData.push({
              id: item.id,
              firstname: item.firstName,
              lastname: item.lastName,
              email: item.email,
              accepted: item.status
            });
          });
          this.searchData = parsedData;
          this.searching = false;
        })
        .catch(() => {
          this.setError("Något gick fel!");
          this.searching = false;
        });
    },
    inputSearchKeyUp: function() {
      clearTimeout(this.inputSearchTimer);
      this.inputSearchTimer = setTimeout(() => {
        this.inputSearch();
      }, 1000);
    },
    inputSearchKeyDown: function() {
      clearTimeout(this.inputSearchTimer);
    },
    setError: function(text) {
      this.$notify({
        message: text,
        icon: "error",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "danger",
        timeout: 5000
      });
    }
  }
};
</script>
