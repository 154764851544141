<template>
  <div>
    <div class="md-layout">
      <div class="md-layout-item md-size-100">
        <md-checkbox v-model="noCosts">
          Inga kostnader
        </md-checkbox>
      </div>
      <div class="md-layout-item md-size-60 md-small-size-100" v-show="noCosts">
        <text-field
          :initialTextval.sync="fixedCost"
          label="Fast individuell kostnad"
          icon="attach_money"
          :validationConditions="fixedCostValidation"
        />
      </div>
      <div class="md-layout-item md-size-40 md-small-size-100" v-show="noCosts">
        <p>Kronor per prenumerant.</p>
      </div>
      <div
        class="md-layout-item md-size-60 md-small-size-100"
        v-show="!noCosts"
      >
        <text-field
          :initialTextval.sync="margin"
          label="Marginal"
          icon="tune"
          :validationConditions="marginValidation"
        />
      </div>
      <div
        class="md-layout-item md-size-40 md-small-size-100"
        v-show="!noCosts"
      >
        <p>Anges i procent.</p>
      </div>
      <div
        class="md-layout-item md-size-60 md-small-size-100"
        v-blur="!isCostsAdded"
      >
        <date-field
          :initialDate.sync="dateCostsSet"
          label="Datum då utpriset fastställdes"
          :datepicker="true"
          :disabled="!isCostsAdded || !isAuthorizedToApprove"
        />
      </div>
      <div class="md-layout-item md-size-40 md-small-size-100">
        <p>Fylls i av arrangörsansvarig eller annan styrelsemedlem.</p>
      </div>
    </div>
    <div class="md-layout" v-show="costs.length > 0 && !noCosts">
      <div class="md-layout-item md-size-100">
        <p>
          Individuell-kostnad är en kostnad som betalas per prenumerant.
          Prenumerant-kostnad är en kostnad som delas på samtliga prenumeranter.
          Gemensam-kostnad är en kostnad som delas för samtliga deltagare
          (inklusive medföljande medlemmar i Svenska Befästningssällskapet).
        </p>
      </div>
    </div>
    <div class="md-layout" v-show="costs.length > 0 && !noCosts">
      <div class="md-layout-item md-size-33 md-small-size-100">
        <h4>Deltagare</h4>
        <md-table>
          <md-table-row>
            <md-table-cell>Totalt</md-table-cell>
            <md-table-cell>{{ totalPeople }} st</md-table-cell>
          </md-table-row>
          <md-table-row>
            <md-table-cell>Prenumeranter</md-table-cell>
            <md-table-cell>{{ subscribers }} st</md-table-cell>
          </md-table-row>
          <md-table-row>
            <md-table-cell>Arrangörer</md-table-cell>
            <md-table-cell>{{ sumOrganizers }} st</md-table-cell>
          </md-table-row>
          <md-table-row>
            <md-table-cell>Medföjande</md-table-cell>
            <md-table-cell>{{ accompanying }} st</md-table-cell>
          </md-table-row>
        </md-table>
      </div>
      <div class="md-layout-item md-size-33 md-small-size-100">
        <h4>Kostnader</h4>
        <md-table>
          <md-table-row>
            <md-table-cell>Gemensamma</md-table-cell>
            <md-table-cell>{{ allCosts }} kr</md-table-cell>
          </md-table-row>
          <md-table-row>
            <md-table-cell>Prenumeranter</md-table-cell>
            <md-table-cell>{{ subscriberCosts }} kr</md-table-cell>
          </md-table-row>
          <md-table-row>
            <md-table-cell>Individuella</md-table-cell>
            <md-table-cell>{{ individualCosts }} kr</md-table-cell>
          </md-table-row>
        </md-table>
      </div>
      <div class="md-layout-item md-size-33 md-small-size-100">
        <h4>Priser</h4>
        <md-table>
          <md-table-row>
            <md-table-cell>Prenumerant</md-table-cell>
            <md-table-cell>
              {{ costPerSubscriber | formatDecimal }} kr</md-table-cell
            >
          </md-table-row>
          <md-table-row>
            <md-table-cell>Utpris prenumerant</md-table-cell>
            <md-table-cell>
              {{ finalCostPerSubscriber | formatDecimal }} kr</md-table-cell
            >
          </md-table-row>
          <md-table-row>
            <md-table-cell>Utpris medföljande (interna)</md-table-cell>
            <md-table-cell>
              {{ finalCostPerAccompanying | formatDecimal }} kr</md-table-cell
            >
          </md-table-row>
        </md-table>
      </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item md-size-100">
        <md-table id="costs" v-show="costs.length > 0 && !noCosts">
          <md-table-row>
            <md-table-head>Titel</md-table-head>
            <md-table-head>Kommentar</md-table-head>
            <md-table-head>Kostnad (kr)</md-table-head>
            <md-table-head>Typ</md-table-head>
            <md-table-head></md-table-head>
          </md-table-row>
          <md-table-row
            v-for="(item, index) in costs"
            v-bind:item="item"
            v-bind:index="index"
            v-bind:key="index"
          >
            <md-table-cell>
              <text-field
                :initialTextval.sync="item.title"
                label="Titel"
                icon="title"
                :validationConditions="titleValidation"
              />
            </md-table-cell>
            <md-table-cell>
              <text-field
                :initialTextval.sync="item.comment"
                label="Kommentar"
                icon="comment"
                :validationConditions="commentValidation"
              />
            </md-table-cell>
            <md-table-cell>
              <text-field
                :initialTextval.sync="item.cost"
                label="Kostnad"
                icon="attach_money"
                :validationConditions="costValidation"
              />
            </md-table-cell>
            <md-table-cell>
              <md-field>
                <md-select v-model="item.type">
                  <md-option value="INDIVIDUAL">Individuell</md-option>
                  <md-option value="SUBSCRIBER">Prenumerant</md-option>
                  <md-option value="ALL">Gemensam</md-option>
                </md-select>
              </md-field>
            </md-table-cell>
            <md-table-cell>
              <md-button
                class="md-just-icon md-danger"
                @click="deleteCost(index)"
              >
                <md-icon>delete</md-icon>
              </md-button>
            </md-table-cell>
          </md-table-row>
        </md-table>
        <md-button
          class="md-success"
          @click.native="addCost()"
          v-show="!noCosts"
        >
          Lägg till kostnad
        </md-button>
      </div>
      <div class="md-layout-item md-size-100">
        <md-progress-bar
          :class="completionClass"
          :md-value="completionScore"
        ></md-progress-bar>
      </div>
    </div>
  </div>
</template>
<script>
import TextField from "@/pages/Dashboard/Components/TextField.vue";
import DateField from "@/pages/Dashboard/Components/DateField.vue";
export default {
  components: {
    TextField,
    DateField
  },
  props: {
    initialFixedCost: {
      type: String,
      required: true
    },
    initialMargin: {
      type: String,
      required: true
    },
    initialDateCostsSet: {
      required: true
    },
    initialCosts: {
      type: Array,
      required: true
    },
    sumPeople: {
      type: String,
      required: true
    },
    sumOrganizers: {
      type: Number,
      required: true
    },
    accompanyingSumPeople: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      costs: this.initialCosts,
      margin: this.initialMargin,
      fixedCost: this.initialFixedCost,
      noCosts: this.initialFixedCost && this.initialFixedCost.length > 0,
      fixedCostValidation: {
        required: false,
        numeric: true,
        min_value: 1,
        max_value: 100000
      },
      marginValidation: {
        required: true,
        numeric: true,
        min_value: 0,
        max_value: 100000
      },
      dateCostsSet: this.initialDateCostsSet,
      titleValidation: {
        required: true,
        min: 4
      },
      commentValidation: {
        required: false,
        min: 4
      },
      costValidation: {
        required: false,
        numeric: true,
        min: 0
      }
    };
  },
  methods: {
    addCost() {
      this.costs.push({
        title: "",
        cost: "0",
        type: "INDIVIDUAL",
        comment: ""
      });
    },
    deleteCost(selectedIndex) {
      this.costs = this.costs.filter((item, index) => index != selectedIndex);
    },
    updateMargin: function() {
      this.$emit("update:initialMargin", this.margin);
    },
    updateDateCostsSet: function() {
      this.$emit("update:initialDateCostsSet", this.dateCostsSet);
    },
    updateCosts: function() {
      this.$emit("update:initialCosts", this.costs);
    },
    updateFixedCost: function() {
      this.$emit("update:initialFixedCost", this.fixedCost);
    }
  },
  watch: {
    margin() {
      this.updateMargin();
    },
    fixedCost() {
      this.updateFixedCost();
    },
    dateCostsSet() {
      this.updateDateCostsSet();
    },
    costs() {
      this.updateCosts();
    }
  },
  computed: {
    isAuthorizedToApprove: function() {
      return this.$store.getters.roles.includes("arrangement_write");
    },
    totalPeople: function() {
      return Number(this.sumPeople);
    },
    subscribers: function() {
      return (
        Number(this.sumPeople) -
        this.sumOrganizers -
        Number(this.accompanyingSumPeople)
      );
    },
    accompanying: function() {
      if (this.accompanyingSumPeople.length < 1) {
        return 0;
      }
      return Number(this.accompanyingSumPeople);
    },
    subscriberCosts: function() {
      let totalCost = 0;
      this.costs
        .filter(item => item.type == "SUBSCRIBER")
        .forEach(item => (totalCost += Number(item.cost)));
      return totalCost;
    },
    allCosts: function() {
      let totalCost = 0;
      this.costs
        .filter(item => item.type == "ALL")
        .forEach(item => (totalCost += Number(item.cost)));
      return totalCost;
    },
    individualCosts: function() {
      let totalCost = 0;
      this.costs
        .filter(item => item.type == "INDIVIDUAL")
        .forEach(item => (totalCost += Number(item.cost)));
      return totalCost;
    },
    costPerSubscriber: function() {
      if (isNaN(this.subscribers)) {
        return "-";
      }
      let totalCost = 0;
      totalCost += this.subscriberCosts / Number(this.subscribers);
      totalCost +=
        this.allCosts / (Number(this.subscribers) + this.accompanying);
      totalCost += this.individualCosts;
      return totalCost;
    },
    finalCostPerSubscriber: function() {
      let sum = 0;
      let cps = this.costPerSubscriber;
      if (isNaN(cps)) {
        return "-";
      }
      // (margin * (cost + 3,25) * 1,034) = totalcost
      sum += (this.margin / 100 + 1) * (cps + 3.25) * 1.034;
      return sum;
    },
    costPerAccompanying: function() {
      if (isNaN(this.subscribers)) {
        return "-";
      }
      let totalCost = 0;
      totalCost +=
        this.allCosts / (Number(this.subscribers) + this.accompanying);
      totalCost += this.individualCosts;
      return totalCost;
    },
    finalCostPerAccompanying: function() {
      let sum = 0;
      let cps = this.costPerAccompanying;
      if (isNaN(cps)) {
        return "-";
      }
      // ((cost + 3,25) * 1,034) = totalcost
      sum += (cps + 3.25) * 1.034;
      return sum;
    },
    completionScore: function() {
      let score = 0;
      if (this.noCosts) {
        if (this.fixedCost && this.fixedCost.length > 0) {
          score += 100 / 2;
        }
        if (this.dateCostsSet) {
          score += 100 / 2;
        }
      } else {
        if (this.costs.length > 0) {
          score += 100 / 3;
        }
        if (this.margin) {
          score += 100 / 3;
        }
        if (this.dateCostsSet) {
          score += 100 / 3;
        }
      }
      this.$emit("score", score);
      return score;
    },
    completionClass: function() {
      if (this.completionScore < 50) {
        return "md-danger";
      }
      if (this.completionScore < 100) {
        return "md-warning";
      }
      return "md-success";
    },
    isCostsAdded: function() {
      return this.costs.length > 0 || (this.noCosts && this.fixedCost > 0);
    }
  },
  filters: {
    formatDecimal: function(value) {
      if (isNaN(value)) {
        return "-";
      }
      return value.toFixed(2);
    }
  }
};
</script>
<style>
#costs .md-field:not(.md-disabled)::after {
  background-color: transparent !important;
}
</style>
