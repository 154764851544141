<template>
  <div>
    <div class="md-layout" id="plantourlistheader">
      <div class="md-layout-item">
        <md-button class="md-success" :to="{ path: '/tour/plan/create' }">
          Skapa Planering
        </md-button>
      </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item md-size-100">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>event</md-icon>
            </div>
          </md-card-header>
          <md-card-content :hidden="!loading">
            <div class="md-layout">
              <div class="md-layout-item text-center">
                <md-progress-spinner
                  md-mode="indeterminate"
                  :md-diameter="spinnerDiameter"
                  :md-stroke="spinnerStroke"
                ></md-progress-spinner>
              </div>
            </div>
          </md-card-content>
          <md-card-content :hidden="loading || paginatedData.length < 1">
            <md-table>
              <md-table-row>
                <md-table-head class="md-medium-hide">Datum</md-table-head>
                <md-table-head>Titel</md-table-head>
                <md-table-head class="text-right"></md-table-head>
              </md-table-row>
              <md-table-row v-for="item in paginatedData" v-bind:key="item.id">
                <md-table-cell class="md-medium-hide">{{
                  item.date
                }}</md-table-cell>
                <md-table-cell>{{ item.title }}</md-table-cell>
                <md-table-cell class="text-right">
                  <md-button
                    class="md-just-icon md-warning"
                    :to="{ path: '/tour/plan/' + item.id }"
                    ><md-icon>edit</md-icon></md-button
                  >
                </md-table-cell>
              </md-table-row>
            </md-table>
            <div class="md-layout text-center pagination-button-container">
              <div class="md-layout-item">
                <div class="md-layout">
                  <div class="md-layout-item">
                    <md-button
                      class="pagination-button md-just-icon md-success"
                      :disabled="currentPage == 1"
                      @click="firstPage"
                      ><md-icon>first_page</md-icon></md-button
                    >
                    &nbsp;
                    <md-button
                      class="pagination-button md-just-icon md-success"
                      :disabled="currentPage == 1"
                      @click="previousPage"
                      ><md-icon>skip_previous</md-icon></md-button
                    >
                    &nbsp;
                    <md-button
                      class="pagination-button md-just-icon md-success"
                      :disabled="currentPage == totalPages"
                      @click="nextPage"
                      ><md-icon>skip_next</md-icon></md-button
                    >
                    &nbsp;
                    <md-button
                      class="pagination-button md-just-icon md-success"
                      :disabled="currentPage == totalPages"
                      @click="lastPage"
                      ><md-icon>last_page</md-icon></md-button
                    >
                  </div>
                </div>
                <div class="md-layout">
                  <div class="md-layout-item">
                    <p>Sida {{ currentPage }} av {{ totalPages }}</p>
                  </div>
                </div>
              </div>
            </div>
          </md-card-content>
          <md-card-content :hidden="loading || paginatedData.length > 0">
            <p>Inga sökresultat</p>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  data() {
    return {
      data: [],
      spinnerDiameter: 60,
      spinnerStroke: 6,
      loading: true,
      offset: 0,
      limit: 10
    };
  },
  mounted: function() {
    this.loadData();
  },
  computed: {
    isAuthorizedToReadArrangementPlans: function() {
      return this.$store.getters.arrangementPlannerRoles.includes("read_plan");
    },
    paginatedData: function() {
      return this.data.slice(this.offset).slice(0, this.limit);
    },
    currentPage: function() {
      return Math.ceil(this.offset / this.limit) + 1;
    },
    totalPages: function() {
      return Math.ceil(this.data.length / this.limit);
    }
  },
  methods: {
    moment: function() {
      return moment;
    },
    nextPage: function() {
      this.offset += this.limit;
    },
    previousPage: function() {
      this.offset -= this.limit;
    },
    firstPage: function() {
      this.offset = 0;
    },
    lastPage: function() {
      this.offset = Math.floor(this.data.length / this.limit) * this.limit;
    },
    setError: function(text) {
      this.$notify({
        message: text,
        icon: "error",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "danger",
        timeout: 5000
      });
    },
    loadData: function() {
      if (!this.isAuthorizedToReadArrangementPlans) {
        this.setError("Du har inte behörighet för denna sida!");
        return;
      }
      this.loading = true;
      this.axios
        .get(
          "https://dashboard.svbefs.se/services/planner/api/planner/arrangement"
        )
        .then(response => {
          let parsedData = [];
          response.data.items.forEach(function(item) {
            parsedData.push({
              id: item.id,
              title: item.title,
              date: item.date,
              slots: item.slots
            });
          });
          this.data = parsedData.sort((x, y) =>
            moment.utc(y.date).diff(moment.utc(x.date))
          );
          this.loading = false;
        })
        .catch(() => {
          this.setError("Något gick fel");
        });
    }
  }
};
</script>
<style lang="scss" scoped>
//.text-right /deep/ .md-table-cell-container {
.text-right .md-table-cell-container {
  display: flex;
  justify-content: flex-end;
}
//.md-table /deep/ .md-table-head:last-child {
.md-table .md-table-head:last-child {
  text-align: right;
}
.pagination-button-container {
  padding-top: 20px;
}
.pagination-button {
  margin: 0;
  padding: 5px;
  height: 27px;
  min-width: 27px;
  width: 27px;
  line-height: 27px;
}
</style>
<style scoped>
#plantourlistheader .md-button {
  float: right;
}
</style>
