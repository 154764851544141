<template>
  <div>
    <div class="md-layout">
      <div class="md-layout-item">
        <md-field>
          <div class="editor-wrapper">
            <vue-editor
              v-model="editorData"
              :editorToolbar="customToolbar"
              @text-change="updateDescription"
              :disabled="!isAuthorizedToWrite"
            ></vue-editor>
          </div>
        </md-field>
      </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item">
        <p>
          Beskrivningen kommer att synas på portalen, samt i det genererade
          dokumentet som skickas ut till alla deltagare. Innehållet i
          beskrivningen bör ge en översikt av arrangemanget samt relevant
          information om respektive visningsobjekt samt övriga instruktioner.
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { VueEditor } from "vue2-editor";
export default {
  components: {
    VueEditor
  },
  props: {
    description: {
      type: String,
      required: true
    },
    isAuthorizedToWrite: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      customToolbar: [
        ["bold", "italic", "underline", "link"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ color: [] }],
        ["clean"]
      ],
      editorData: ""
    };
  },
  methods: {
    updateDescription: function() {
      this.$emit("update:description", this.editorData);
    }
  },
  mounted() {
    this.editorData = this.description;
  }
};
</script>
<style lang="scss" scoped>
div.editor-wrapper {
  width: 100%;
}
</style>
