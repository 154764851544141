<template>
  <div>
    <div class="md-layout" v-show="loading">
      <div class="md-layout-item text-center">
        <md-progress-spinner
          md-mode="indeterminate"
          :md-diameter="spinnerDiameter"
          :md-stroke="spinnerStroke"
        ></md-progress-spinner>
      </div>
    </div>
    <form class="form-horizontal" action="" method="post" v-show="!loading">
      <div class="md-layout pb">
        <div class="md-layout-item">
          <md-field
            :class="[
              { 'md-error': errors.has('headOrganizer') },
              {
                'md-valid':
                  !errors.has('headOrganizer') && touched.headOrganizer
              }
            ]"
          >
            <label for="additionalOrganizers">Huvudarrangör</label>
            <md-select
              :value="headOrganizer"
              name="additionalOrganizers"
              id="additionalOrganizers"
              @md-selected="updatedHeadOrganizer"
              :disabled="
                !isAuthorizedToWrite || !isAuthorizedToChangeHeadOrganizer
              "
              data-vv-name="headOrganizer"
              v-validate="modelValidations.headOrganizer"
              required
            >
              <md-option
                v-for="organizer in allOrganizers"
                v-bind:key="organizer"
                :value="organizer"
                >{{ organizer }}</md-option
              >
            </md-select>
            <slide-y-down-transition>
              <md-icon class="error" v-show="errors.has('headOrganizer')"
                >close</md-icon
              >
            </slide-y-down-transition>
            <slide-y-down-transition>
              <md-icon
                class="success"
                v-show="!errors.has('headOrganizer') && touched.headOrganizer"
                >done</md-icon
              >
            </slide-y-down-transition>
          </md-field>
        </div>
      </div>
      <div class="md-layout pb">
        <div class="md-layout-item">
          <md-field>
            <label for="additionalOrganizers">Ytterligare arrangörer</label>
            <md-select
              :value="organizers"
              name="additionalOrganizers"
              id="additionalOrganizers"
              @md-selected="updateAdditionalOrganizer"
              multiple
              :disabled="!isAuthorizedToWrite || !isAuthorizedToChangeOrganizer"
            >
              <md-option
                v-for="organizer in availableAdditionalOrganizers"
                v-bind:key="organizer"
                :value="organizer"
                >{{ organizer }}</md-option
              >
            </md-select>
          </md-field>
        </div>
      </div>
    </form>
    <div class="md-layout">
      <div class="md-layout-item">
        <p>
          Huvudarrangören bär det huvudsakliga ansvaret för arrangemanget. Det
          är bara huvudarrangören som kan redigera arrangörer. Information om
          huvudarrangören och ev. övriga arrangörer anges dokumentet till alla
          deltagare.
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { SlideYDownTransition } from "vue2-transitions";
export default {
  components: {
    SlideYDownTransition
  },
  props: {
    headOrganizer: {
      type: String,
      required: true
    },
    organizers: {
      type: Array,
      required: true
    },
    isAuthorizedToWrite: {
      type: Boolean,
      required: true
    },
    isAuthorizedToChangeOrganizer: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      spinnerDiameter: 60,
      spinnerStroke: 6,
      allOrganizers: [],
      touched: {
        headOrganizer: false
      },
      modelValidations: {
        headOrganizer: {
          required: true
        }
      }
    };
  },
  mounted: function() {
    this.loadOrganizers();
  },
  computed: {
    isAuthorizedToReadOrganizers: function() {
      return this.$store.getters.roles.includes("arrangement_organizers_read");
    },
    isAuthorizedToChangeHeadOrganizer: function() {
      return this.$store.getters.roles.includes("arrangement_write");
    },
    availableAdditionalOrganizers: function() {
      return this.allOrganizers.filter(item => item !== this.headOrganizer);
    }
  },
  methods: {
    updatedHeadOrganizer: function(value) {
      this.$emit("update:headOrganizer", value);
      this.$emit(
        "update:organizers",
        this.organizers.filter(item => item !== value)
      );
    },
    updateAdditionalOrganizer: function(value) {
      this.$emit("update:organizers", value);
    },
    loadOrganizers: function() {
      this.loading = true;

      if (this.isAuthorizedToReadOrganizers) {
        this.axios
          .get("internal/arrangement/organizers")
          .then(response => {
            let organizersList = [];
            response.data.forEach(function(item) {
              organizersList.push(item.name);
            });
            this.allOrganizers = organizersList;
            this.loading = false;
          })
          .catch(() => {
            this.setError("Kunde inte hämta data");
            this.loading = false;
          });
      } else {
        this.allOrganizers = [this.organizers];
        this.loading = false;
      }
    },
    setError: function(text) {
      this.$notify({
        message: text,
        icon: "error",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "danger",
        timeout: 5000
      });
    }
  }
};
</script>
