<template>
  <div>
    <div class="md-layout" v-if="isAuthorizedToRead">
      <div class="md-layout-item md-size-100">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>mail</md-icon>
            </div>
          </md-card-header>
          <md-card-content>
            <tour-mail-list
              :arrangementId="arrangementId"
              :isAuthorizedToWrite="isAuthorizedToWrite"
              ref="tourMailList"
              @mailSelected="mailSelected"
              @mailValidateSelected="mailValidateSelected"
            ></tour-mail-list>
          </md-card-content>
        </md-card>
      </div>
    </div>
    <div class="md-layout" v-if="isAuthorizedToWrite">
      <div class="md-layout-item md-size-100">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>create</md-icon>
            </div>
          </md-card-header>
          <md-card-content>
            <tour-mail-send
              :arrangementId="arrangementId"
              :arrangementTitle="arrangementTitle"
              ref="tourMailSend"
              @mailSent="mailSent"
            ></tour-mail-send>
          </md-card-content>
        </md-card>
      </div>
    </div>
    <tour-mail-modal ref="tourMailModal" v-if="isAuthorizedToRead" />
    <tour-mail-validate-modal
      ref="tourMailValidateModal"
      @mailSynced="mailSynced"
      v-if="isAuthorizedToWrite"
    />
  </div>
</template>
<script>
import TourMailModal from "./TourMail/TourMailModal";
import TourMailSend from "./TourMail/TourMailSend";
import TourMailList from "./TourMail/TourMailList";
import TourMailValidateModal from "./TourMail/TourMailValidateModal";
export default {
  components: {
    TourMailModal,
    TourMailSend,
    TourMailList,
    TourMailValidateModal
  },
  data() {
    return {
      arrangement: {}
    };
  },
  mounted: function() {
    this.loadData();
  },
  computed: {
    isAuthorizedToRead: function() {
      return (
        this.$store.getters.mailRoles.includes("administrator") ||
        (this.$store.getters.mailRoles.includes("read_arrangement") &&
          this.arrangement.organizers &&
          this.arrangement.organizers
            .map(o => o.organizer)
            .includes(this.$store.getters.userName))
      );
    },
    isAuthorizedToWrite: function() {
      return (
        this.$store.getters.mailRoles.includes("administrator") ||
        (this.$store.getters.mailRoles.includes("send_arrangement") &&
          this.arrangement.organizers &&
          this.arrangement.organizers
            .map(o => o.organizer)
            .includes(this.$store.getters.userName))
      );
    },
    arrangementTitle: function() {
      return this.arrangement.title ? this.arrangement.title : "";
    },
    arrangementId: function() {
      return this.arrangement.id ? this.arrangement.id : -1;
    }
  },
  methods: {
    loadData: function() {
      let accessPromise = new Promise((resolve, reject) => {
        if (
          !this.$store.getters.mailRoles.includes("administrator") &&
          !this.$store.getters.mailRoles.includes("read_arrangement")
        ) {
          reject();
        } else {
          this.axios
            .get("internal/arrangement/" + this.$route.params.id)
            .then(response => {
              this.arrangement = response.data;
              if (this.isAuthorizedToRead) {
                resolve();
              } else {
                reject();
              }
            })
            .catch(() => {
              reject();
            });
        }
      });

      accessPromise
        .then(() => {
          this.$refs.tourMailSend.show();
          this.$refs.tourMailList.show();
        })
        .catch(() => {
          this.setError("Du har inte behörighet för denna sida!");
        });
    },
    mailSent: function() {
      this.$refs.tourMailList.show();
    },
    mailSelected: function(mail) {
      this.$refs.tourMailModal.showDialog(mail.subject, mail.contentHtml);
    },
    mailValidateSelected: function(mail) {
      this.$refs.tourMailValidateModal.showDialog(this.arrangement, mail);
    },
    mailSynced: function(mailId) {
      this.$refs.tourMailList.loadStatuses(mailId, this.arrangement.id);
    },
    setError: function(text) {
      this.$notify({
        message: text,
        icon: "error",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "danger",
        timeout: 5000
      });
    }
  }
};
</script>
