<template>
  <span v-if="confirmed">
    <md-icon :class="iconClasses">{{ icon }}</md-icon>
  </span>
  <span v-else-if="triggered" @click="confirm">
    <md-icon :class="iconClasses">{{ icon }}</md-icon>
  </span>
  <span
    v-else
    @mouseover="hovered = true"
    @mouseout="hovered = false"
    @click="trigger"
  >
    <md-icon :class="iconClasses">{{ icon }}</md-icon>
  </span>
</template>
<script>
export default {
  data() {
    return {
      countDown: this.seconds,
      triggered: false,
      confirmed: false,
      hovered: false
    };
  },
  props: {
    seconds: {
      type: Number,
      default: 5,
      description: "Seconds for timer"
    },
    icon: {
      type: String,
      default: "delete"
    },
    defaultClass: {
      type: Array,
      default: () => ["delete-red", "pointer"]
    },
    hoverClass: {
      type: Array,
      default: () => ["deleted-red"]
    }
  },
  methods: {
    trigger() {
      this.triggered = true;
      this.countDownTimer();
      this.$emit("triggered");
    },
    confirm() {
      this.confirmed = true;
      this.$emit("confirmed");
    },
    countDownTimer() {
      if (this.triggered) {
        if (!this.confirmed && this.countDown > 0) {
          setTimeout(() => {
            this.countDown -= 1;
            this.countDownTimer();
          }, 1000);
        } else if (!this.confirmed) {
          this.triggered = false;
          this.countDown = this.seconds;
          this.hovered = false;
        }
      }
    }
  },
  computed: {
    iconClasses() {
      if (this.hovered && !this.confirmed && !this.disabled) {
        return this.defaultClass.concat(this.hoverClass);
      }
      return this.defaultClass;
    }
  }
};
</script>
<style lang="scss" scoped>
.pointer {
  cursor: pointer;
}
.delete-red {
  color: #f44336 !important;
}
.deleted-red {
  color: #b71c1c !important;
}
</style>
