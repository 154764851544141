<template>
  <div>
    <div class="md-layout" v-if="isAuthorizedToWrite">
      <div class="md-layout-item">
        <md-button
          class="md-success"
          @click.native="editTour"
          :disabled="loading || errors.any()"
        >
          Spara
        </md-button>
        <a target="_blank" :href="pdfUrl">
          <md-button class="md-info">
            Skapa PDF
          </md-button>
        </a>
      </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>event</md-icon>
            </div>
            <h4 class="title">Allmänt</h4>
          </md-card-header>

          <md-card-content v-if="loading">
            <div class="md-layout">
              <div class="md-layout-item text-center">
                <md-progress-spinner
                  md-mode="indeterminate"
                  :md-diameter="spinnerDiameter"
                  :md-stroke="spinnerStroke"
                ></md-progress-spinner>
              </div>
            </div>
          </md-card-content>
          <md-card-content v-else>
            <edit-tour-general
              :initialTitle.sync="title"
              :initialDate.sync="date"
              :initialTime.sync="time"
              :initialLocation.sync="location"
              :initialStatus.sync="status"
              :initialSlots.sync="slots"
              :initialFee.sync="fee"
              :isAuthorizedToWrite="isAuthorizedToWrite"
            />
          </md-card-content>
        </md-card>
      </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>emoji_people</md-icon>
            </div>
            <h4 class="title">Arrangör</h4>
          </md-card-header>
          <md-card-content v-if="loading">
            <div class="md-layout">
              <div class="md-layout-item text-center">
                <md-progress-spinner
                  md-mode="indeterminate"
                  :md-diameter="spinnerDiameter"
                  :md-stroke="spinnerStroke"
                ></md-progress-spinner>
              </div>
            </div>
          </md-card-content>
          <md-card-content v-else>
            <edit-tour-organizer
              :headOrganizer.sync="headOrganizer"
              :organizers.sync="organizers"
              :isAuthorizedToWrite="isAuthorizedToWrite"
              :isAuthorizedToChangeOrganizer="isAuthorizedToChangeOrganizer"
            />
          </md-card-content>
        </md-card>
      </div>
      <div class="md-layout-item">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>table_chart</md-icon>
            </div>
            <h4 class="title">Utlottning</h4>
          </md-card-header>

          <md-card-content v-if="loading">
            <div class="md-layout">
              <div class="md-layout-item text-center">
                <md-progress-spinner
                  md-mode="indeterminate"
                  :md-diameter="spinnerDiameter"
                  :md-stroke="spinnerStroke"
                ></md-progress-spinner>
              </div>
            </div>
          </md-card-content>
          <md-card-content v-else>
            <edit-tour-raffling
              :arrangementDate="date"
              :intitialRafflingDateOpen.sync="rafflingDateOpen"
              :intitialRafflingDateClose.sync="rafflingDateClose"
              :isAuthorizedToWrite="isAuthorizedToWrite"
            />
          </md-card-content>
        </md-card>
      </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>business_center</md-icon>
            </div>
            <h4 class="title">Klädsel</h4>
          </md-card-header>
          <md-card-content v-if="loading">
            <div class="md-layout">
              <div class="md-layout-item text-center">
                <md-progress-spinner
                  md-mode="indeterminate"
                  :md-diameter="spinnerDiameter"
                  :md-stroke="spinnerStroke"
                ></md-progress-spinner>
              </div>
            </div>
          </md-card-content>
          <md-card-content v-else>
            <edit-tour-recommendation-clothing
              :recommendation.sync="clothing"
              :isAuthorizedToWrite="isAuthorizedToWrite"
            />
          </md-card-content>
        </md-card>
      </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>build</md-icon>
            </div>
            <h4 class="title">Utrustning</h4>
          </md-card-header>
          <md-card-content v-if="loading">
            <div class="md-layout">
              <div class="md-layout-item text-center">
                <md-progress-spinner
                  md-mode="indeterminate"
                  :md-diameter="spinnerDiameter"
                  :md-stroke="spinnerStroke"
                ></md-progress-spinner>
              </div>
            </div>
          </md-card-content>
          <md-card-content v-else>
            <edit-tour-recommendation-gear
              :recommendation.sync="gear"
              :isAuthorizedToWrite="isAuthorizedToWrite"
            />
          </md-card-content>
        </md-card>
      </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>description</md-icon>
            </div>
            <h4 class="title">Beskrivning</h4>
          </md-card-header>
          <md-card-content v-if="loading">
            <div class="md-layout">
              <div class="md-layout-item text-center">
                <md-progress-spinner
                  md-mode="indeterminate"
                  :md-diameter="spinnerDiameter"
                  :md-stroke="spinnerStroke"
                ></md-progress-spinner>
              </div>
            </div>
          </md-card-content>
          <md-card-content v-else>
            <edit-tour-description
              :description.sync="description"
              :isAuthorizedToWrite="isAuthorizedToWrite"
            />
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>
<script>
import EditTourGeneral from "./EditTour/EditTourGeneral";
import EditTourOrganizer from "./EditTour/EditTourOrganizer";
import EditTourRaffling from "./EditTour/EditTourRaffling";
import EditTourRecommendationClothing from "./EditTour/EditTourRecommendationClothing";
import EditTourRecommendationGear from "./EditTour/EditTourRecommendationGear";
import EditTourDescription from "./EditTour/EditTourDescription";
import moment from "moment";
export default {
  components: {
    EditTourGeneral,
    EditTourOrganizer,
    EditTourRaffling,
    EditTourRecommendationClothing,
    EditTourRecommendationGear,
    EditTourDescription
  },
  mounted: function() {
    if (!this.isAuthorizedToRead) {
      this.setError("Du har inte behörighet för denna sida!");
    } else {
      this.loadTour();
    }
  },
  data() {
    return {
      loading: true,
      spinnerDiameter: 60,
      spinnerStroke: 6,
      id: -1,
      title: "",
      location: "",
      status: "utkast",
      date: moment
        .utc()
        .add(20, "days")
        .format("YYYY-MM-DD"),
      time:
        moment.utc().format("HH") +
        ":00 - " +
        moment
          .utc()
          .add(1, "hours")
          .format("HH") +
        ":00",
      fee: 100,
      clothing:
        "<p>Inget utöver det vanliga (se <a href='https://fortifikationvast.se/faq/' rel='noopener noreferrer' target='_blank'>https://fortifikationvast.se/faq/</a>)</p>",
      gear:
        "<p>Inget utöver det vanliga (se <a href='https://fortifikationvast.se/faq/' rel='noopener noreferrer' target='_blank'>https://fortifikationvast.se/faq/</a>)</p>",
      description: "Vi kommer att inleda dagen med  ...",
      slots: 20,
      registrationOpen: true,
      groupRegistrationOpen: true,
      nonMemberRegistrationOpen: true,
      rafflingDateOpen: moment.utc().format("YYYY-MM-DD"),
      rafflingDateClose: moment
        .utc()
        .add(18, "days")
        .format("YYYY-MM-DD"),
      savedHeadOrganizer: this.$store.getters.userName,
      headOrganizer: this.$store.getters.userName,
      savedOrganizers: [this.$store.getters.userName],
      organizers: []
    };
  },
  computed: {
    isAuthorizedToRead: function() {
      return this.$store.getters.roles.includes("arrangement_read");
    },
    isAuthorizedToWrite: function() {
      return (
        this.$store.getters.roles.includes("arrangement_write") ||
        this.$store.getters.userName == this.savedHeadOrganizer ||
        this.savedOrganizers.includes(this.$store.getters.userName)
      );
    },
    isAuthorizedToChangeOrganizer: function() {
      return (
        this.$store.getters.roles.includes("arrangement_write") ||
        this.$store.getters.userName == this.savedHeadOrganizer
      );
    },
    pdfUrl: function() {
      return "https://pdf.svbefs.se/?arrangement=" + this.$route.params.id;
    }
  },
  methods: {
    updateTourData: function(response) {
      if (response.data) {
        let data = response.data;
        this.id = data.id;
        this.title = data.title;
        this.location = data.location;
        this.status = data.status;
        this.date = data.date;
        this.time = data.time;
        this.fee = data.fee;
        this.clothing = data.clothing;
        this.gear = data.gear;
        this.description = data.description;
        this.slots = data.slots;
        this.registrationOpen = data.registrationOpen;
        this.groupRegistrationOpen = data.groupRegistrationOpen;
        this.nonMemberRegistrationOpen = data.nonSubscriberRegistrationOpen;
        this.rafflingDateOpen = data.rafflingDate;
        this.rafflingDateClose = data.lastRafflingDate;
        this.headOrganizer = data.organizers.filter(
          item => item.headOrganizer
        )[0].organizer;
        this.savedHeadOrganizer = this.headOrganizer; // Used for permissions
        let tempOrganizers = [];
        data.organizers
          .filter(item => !item.headOrganizer)
          .forEach(function(item) {
            tempOrganizers.push(item.organizer);
          });
        this.organizers = tempOrganizers;
        this.savedOrganizers = this.organizers; // Used for permissions
      }
    },
    loadTour: function() {
      this.loading = true;
      let organizersPromise;
      if (this.isAuthorizedToReadOrganizers) {
        organizersPromise = this.axios.get("internal/arrangement/organizers");
      } else {
        organizersPromise = Promise.resolve(false);
      }

      let arrangementPromise = this.axios.get(
        "internal/arrangement/" + this.$route.params.id
      );
      Promise.all([organizersPromise, arrangementPromise])
        .then(values => {
          this.updateTourData(values[1]);
          if (values[0]) {
            let organizersList = [];
            values[0].data.forEach(function(item) {
              organizersList.push(item.name);
            });
            this.allOrganizers = organizersList;
          } else {
            this.allOrganizers = this.organizers;
          }
          this.loading = false;
        })
        .catch(() => {
          this.setError("Kunde inte hämta arrangemang");
          this.loading = false;
        });
    },
    editTour: function() {
      this.loading = true;

      let convertedOrganizers = [
        {
          organizer: this.headOrganizer,
          headOrganizer: true
        }
      ];
      this.organizers.forEach(function(item) {
        convertedOrganizers.push({
          organizer: item,
          headOrganizer: false
        });
      });

      this.axios
        .post("internal/arrangement/" + this.$route.params.id, {
          title: this.title,
          location: this.location,
          status: this.status,
          date: this.date,
          time: this.time,
          fee: this.fee,
          clothing: this.clothing,
          gear: this.gear,
          description: this.description,
          slots: this.slots,
          registrationOpen: this.registrationOpen,
          groupRegistrationOpen: this.groupRegistrationOpen,
          nonSubscriberRegistrationOpen: this.nonMemberRegistrationOpen,
          rafflingDate: this.rafflingDateOpen,
          lastRafflingDate: this.rafflingDateClose,
          organizers: convertedOrganizers
        })
        .then(response => {
          this.updateTourData(response);
          this.loading = false;
        })
        .catch(error => {
          this.setError("Något gick fel");
          this.loading = false;
        });
    },
    setError: function(text) {
      this.$notify({
        message: text,
        icon: "error",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "danger",
        timeout: 5000
      });
    }
  }
};
</script>
<style scoped>
button.md-button {
  float: right;
}
</style>
