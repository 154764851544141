var NoticePlugin = {
  install(Vue) {
    Vue.mixin({
      methods: {
        errorNotice: function(text) {
          this.$notify({
            message: text,
            icon: "error",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "danger",
            timeout: 5000
          });
        },
        successNotice: function(text) {
          this.$notify({
            message: text,
            icon: "check_circle",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "success",
            timeout: 5000
          });
        },
        infoNotice: function(text) {
          this.$notify({
            message: text,
            icon: "info",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "primary",
            timeout: 5000
          });
        }
      }
    });
    Object.defineProperty(Vue.prototype, "$errorNotice", {
      get() {
        return this.$root.errorNotice;
      }
    });
    Object.defineProperty(Vue.prototype, "$successNotice", {
      get() {
        return this.$root.successNotice;
      }
    });
    Object.defineProperty(Vue.prototype, "$infoNotice", {
      get() {
        return this.$root.infoNotice;
      }
    });
  }
};

export default NoticePlugin;
