// =========================================================
// * Vue Material Dashboard PRO - v1.5.3
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vue-material-dashboard-pro
// * Copyright 2024 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Keycloak from 'keycloak-js';
import Vue from "vue";
import VueRouter from "vue-router";
import axios from "axios";
import VueAxios from "vue-axios";
import DashboardPlugin from "./material-dashboard";
import { store } from "./store/store";
import vBlur from "v-blur";
import VueGeolocation from "vue-browser-geolocation";
import VueAuthImage from "vue-auth-image";
import VueClipboard from "vue-clipboard2";
import * as VueGoogleMaps from "vue2-google-maps";

// Plugins
import App from "./App.vue";
import Chartist from "chartist";

// router setup
import routes from "./routes/routes";

function camelText(text) {
  return text
    .toLowerCase()
    .split(" ")
    .map(s => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ");
}

let initOptions = {
  url: "https://dashboard.svbefs.se/auth/",
  realm: "FV-LINK",
  clientId: "svbefs-mail-vue",
  onLoad: "login-required",
  credentials: {
    secret: "71e7a154-9f42-45ad-b841-7e5b9439c825"
  }
};

const keycloak = new Keycloak(initOptions);

keycloak.onTokenExpired = () => {
  //console.log("Token expired");
  keycloak
    .updateToken(30)
    .then(() => {
      axios.defaults.headers.common = {
        Authorization: "Bearer " + keycloak.token
      };
      //console.log("Token refreshed");
    })
    .catch(() => {
      //console.log("Failed to refresh token");
      window.location.reload();
    });
};

try {
  const auth = await keycloak.init({
    onLoad: initOptions.onLoad,
    checkLoginIframe: false
  });
  if (!auth) {
    window.location.reload();
  } else {
    /*console.log(keycloak);
    keycloak.loadUserInfo().success(function (userInfo) {
      console.log(userInfo)
    });*/
  }

  let roles = [];
  let mailRoles = [];
  let arrangementPlannerRoles = [];
  let mapRoles = [];
  if ("fv-user-api" in keycloak.tokenParsed.resource_access) {
    roles = keycloak.tokenParsed.resource_access["fv-user-api"].roles;
  }
  if ("svbefs-mailapp" in keycloak.tokenParsed.resource_access) {
    mailRoles = keycloak.tokenParsed.resource_access["svbefs-mailapp"].roles;
  }
  if ("svbefs-plannerapp" in keycloak.tokenParsed.resource_access) {
    arrangementPlannerRoles =
      keycloak.tokenParsed.resource_access["svbefs-plannerapp"].roles;
  }
  if ("svbefs-mapbe" in keycloak.tokenParsed.resource_access) {
    mapRoles = keycloak.tokenParsed.resource_access["svbefs-mapbe"].roles;
  }
  store.commit("setKeyCloak", keycloak);
  store.commit("setUserName", keycloak.idTokenParsed.user_name);
  store.commit("setFirstName", camelText(keycloak.idTokenParsed.given_name));
  store.commit("setLastName", camelText(keycloak.idTokenParsed.family_name));
  store.commit("setRoles", roles);
  store.commit("setMailRoles", mailRoles);
  store.commit("setArrangementPlannerRoles", arrangementPlannerRoles);
  store.commit("setMapRoles", mapRoles);
  
  // plugin setup
  Vue.use(VueRouter);
  Vue.use(VueAuthImage);

  axios.defaults.baseURL = "https://dashboard.svbefs.se/services/portal-api";
  axios.defaults.headers.common = {
    Authorization: "Bearer " + keycloak.token
  };

  Vue.use(VueAxios, axios);
  Vue.use(DashboardPlugin);
  Vue.use(vBlur);
  Vue.use(VueGeolocation);
  Vue.use(VueClipboard);

  Vue.use(VueGoogleMaps, {
    load: {
      key: "AIzaSyB4PdivLwdAKAv3Png0l1AJVdo6LTUhSuo",
      libraries: "places"
    }
  });

  // configure router
  const router = new VueRouter({
    routes, // short for routes: routes
    scrollBehavior: (to) => {
      if (to.hash) {
        return { selector: to.hash, behavior: "smooth" };
      } else {
        return { x: 0, y: 0 };
      }
    },
    linkExactActiveClass: "nav-item active",
  });

  // global library setup
  Vue.prototype.$Chartist = Chartist;

  /* eslint-disable no-new */
  new Vue({
    el: "#app",
    render: (h) => h(App),
    store,
    router,
    data: {
      Chartist: Chartist
    }
  });
} catch (error) {

}
