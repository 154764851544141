<template>
  <div class="plan-tour">
    <div :hidden="!loading">
      <div class="md-layout">
        <div class="md-layout-item text-center">
          <md-progress-spinner
            md-mode="indeterminate"
            :md-diameter="spinnerDiameter"
            :md-stroke="spinnerStroke"
          ></md-progress-spinner>
        </div>
      </div>
    </div>
    <div :hidden="loading">
      <div class="md-layout">
        <div class="md-layout-item">
          <md-button
            class="md-success"
            @click.native="savePlan"
            :disabled="loading || errors.any() || firstStepScore < 100"
          >
            Spara
          </md-button>
        </div>
      </div>
      <div class="md-layout">
        <div class="md-layout-item">
          <md-card>
            <md-card-header class="md-card-header-icon md-card-header-green">
              <div class="card-icon">
                <md-icon>edit</md-icon>
              </div>
              <h4 class="title">Beskrivning</h4>
            </md-card-header>

            <md-card-content v-if="loading">
              <div class="md-layout">
                <div class="md-layout-item text-center">
                  <md-progress-spinner
                    md-mode="indeterminate"
                    :md-diameter="spinnerDiameter"
                    :md-stroke="spinnerStroke"
                  ></md-progress-spinner>
                </div>
              </div>
            </md-card-content>
            <md-card-content v-else>
              <first-step
                :initialTitle.sync="title"
                :initialDate.sync="date"
                :initialSumPeople.sync="sumPeople"
                @score="firstStepScoreUpdate"
              />
            </md-card-content>
          </md-card>
        </div>
      </div>
      <div class="md-layout">
        <div class="md-layout-item">
          <md-card>
            <md-card-header class="md-card-header-icon md-card-header-green">
              <div class="card-icon">
                <md-icon>event</md-icon>
              </div>
              <h4 class="title">Visningar</h4>
            </md-card-header>

            <md-card-content v-if="loading">
              <div class="md-layout">
                <div class="md-layout-item text-center">
                  <md-progress-spinner
                    md-mode="indeterminate"
                    :md-diameter="spinnerDiameter"
                    :md-stroke="spinnerStroke"
                  ></md-progress-spinner>
                </div>
              </div>
            </md-card-content>
            <md-card-content v-else>
              <second-step
                :initialAgeRestrictionsChecked.sync="ageRestrictionsChecked"
                :initialTours.sync="tours"
                @score="secondStepScoreUpdate"
              />
            </md-card-content>
          </md-card>
        </div>
      </div>
      <div class="md-layout">
        <div class="md-layout-item">
          <md-card>
            <md-card-header class="md-card-header-icon md-card-header-green">
              <div class="card-icon">
                <md-icon>emoji_people</md-icon>
              </div>
              <h4 class="title">Arrangörer</h4>
            </md-card-header>

            <md-card-content v-if="loading">
              <div class="md-layout">
                <div class="md-layout-item text-center">
                  <md-progress-spinner
                    md-mode="indeterminate"
                    :md-diameter="spinnerDiameter"
                    :md-stroke="spinnerStroke"
                  ></md-progress-spinner>
                </div>
              </div>
            </md-card-content>
            <md-card-content v-else>
              <third-step
                :headOrganizer.sync="headOrganizer"
                :organizers.sync="organizers"
                :initialDateSet.sync="dateOrganizersSet"
                @score="thirdStepScoreUpdate"
              />
            </md-card-content>
          </md-card>
        </div>
      </div>
      <div class="md-layout">
        <div class="md-layout-item">
          <md-card>
            <md-card-header class="md-card-header-icon md-card-header-green">
              <div class="card-icon">
                <md-icon>people</md-icon>
              </div>
              <h4 class="title">Medföljande</h4>
            </md-card-header>

            <md-card-content v-if="loading">
              <div class="md-layout">
                <div class="md-layout-item text-center">
                  <md-progress-spinner
                    md-mode="indeterminate"
                    :md-diameter="spinnerDiameter"
                    :md-stroke="spinnerStroke"
                  ></md-progress-spinner>
                </div>
              </div>
            </md-card-content>
            <md-card-content v-else>
              <fourth-step
                :disableStep="disableStepFour"
                :initialDate.sync="accompanyingDate"
                :initialSumPeople.sync="accompanyingSumPeople"
                @score="fourthStepScoreUpdate"
              />
            </md-card-content>
          </md-card>
        </div>
      </div>
      <div class="md-layout">
        <div class="md-layout-item">
          <md-card>
            <md-card-header class="md-card-header-icon md-card-header-green">
              <div class="card-icon">
                <md-icon>account_balance</md-icon>
              </div>
              <h4 class="title">Kostnader</h4>
            </md-card-header>

            <md-card-content v-if="loading">
              <div class="md-layout">
                <div class="md-layout-item text-center">
                  <md-progress-spinner
                    md-mode="indeterminate"
                    :md-diameter="spinnerDiameter"
                    :md-stroke="spinnerStroke"
                  ></md-progress-spinner>
                </div>
              </div>
            </md-card-content>
            <md-card-content v-else>
              <fifth-step
                :initialMargin.sync="costMargin"
                :initialFixedCost.sync="fixedCost"
                :initialDateCostsSet.sync="dateCostsSet"
                :initialCosts.sync="costs"
                :sumPeople="sumPeople"
                :sumOrganizers="sumOrganizers"
                :accompanyingSumPeople="accompanyingSumPeople"
                @score="fifthStepScoreUpdate"
              />
            </md-card-content>
          </md-card>
        </div>
      </div>
      <div class="md-layout">
        <div class="md-layout-item">
          <md-card>
            <md-card-header class="md-card-header-icon md-card-header-green">
              <div class="card-icon">
                <md-icon>event</md-icon>
              </div>
              <h4 class="title">Arrangemang</h4>
            </md-card-header>

            <md-card-content v-if="loading">
              <div class="md-layout">
                <div class="md-layout-item text-center">
                  <md-progress-spinner
                    md-mode="indeterminate"
                    :md-diameter="spinnerDiameter"
                    :md-stroke="spinnerStroke"
                  ></md-progress-spinner>
                </div>
              </div>
            </md-card-content>
            <md-card-content v-else>
              <sixth-step
                :arrangementDate="date"
                :initialDateCreated.sync="arrangementDateCreated"
                :initialDatePublished.sync="arrangementDatePublished"
                :initialDateNewsletter.sync="arrangementDateNewsletter"
                @score="sixthStepScoreUpdate"
              />
            </md-card-content>
          </md-card>
        </div>
      </div>
      <div class="md-layout">
        <div class="md-layout-item">
          <md-card>
            <md-card-header class="md-card-header-icon md-card-header-green">
              <div class="card-icon">
                <md-icon>attach_file</md-icon>
              </div>
              <h4 class="title">PDF</h4>
            </md-card-header>

            <md-card-content v-if="loading">
              <div class="md-layout">
                <div class="md-layout-item text-center">
                  <md-progress-spinner
                    md-mode="indeterminate"
                    :md-diameter="spinnerDiameter"
                    :md-stroke="spinnerStroke"
                  ></md-progress-spinner>
                </div>
              </div>
            </md-card-content>
            <md-card-content v-else>
              <seventh-step
                :disableStep="disableStepSeven"
                :initialDateSentForApproval.sync="datePdfSentForApproval"
                :initialDateApprovalChairman.sync="datePdfApprovalChairman"
                :initialDateArrangementResponsible.sync="
                  datePdfApprovalArrangementResponsible
                "
                :initialLink.sync="pdfLink"
                :initialDateLinkCreated.sync="datePdfLinkCreated"
                :initialDateLinkSent.sync="datePdfLinkSent"
                @score="seventhStepScoreUpdate"
              />
            </md-card-content>
          </md-card>
        </div>
      </div>
      <div class="md-layout">
        <div class="md-layout-item">
          <md-card>
            <md-card-header class="md-card-header-icon md-card-header-green">
              <div class="card-icon">
                <md-icon>timer</md-icon>
              </div>
              <h4 class="title">Avslutningsvis</h4>
            </md-card-header>

            <md-card-content v-if="loading">
              <div class="md-layout">
                <div class="md-layout-item text-center">
                  <md-progress-spinner
                    md-mode="indeterminate"
                    :md-diameter="spinnerDiameter"
                    :md-stroke="spinnerStroke"
                  ></md-progress-spinner>
                </div>
              </div>
            </md-card-content>
            <md-card-content v-else>
              <eigth-step
                :disableStep="disableStepEigth"
                :initialDateTransfer.sync="dateBankTransfer"
                :initialPrintCheckList.sync="printCheckList"
                @score="eigthStepScoreUpdate"
              />
            </md-card-content>
          </md-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FirstStep from "./PlanTour/FirstStep";
import SecondStep from "./PlanTour/SecondStep";
import ThirdStep from "./PlanTour/ThirdStep";
import FourthStep from "./PlanTour/FourthStep";
import FifthStep from "./PlanTour/FifthStep";
import SixthStep from "./PlanTour/SixthStep";
import SeventhStep from "./PlanTour/SeventhStep";
import EigthStep from "./PlanTour/EigthStep";
export default {
  data() {
    return {
      spinnerDiameter: 60,
      spinnerStroke: 6,
      loading: true,
      id: null,
      title: "",
      date: null,
      sumPeople: "",
      ageRestrictionsChecked: false,
      tours: [],
      headOrganizer: "",
      organizers: [],
      dateOrganizersSet: null,
      accompanyingDate: null,
      accompanyingSumPeople: "",
      costMargin: "60",
      fixedCost: "",
      dateCostsSet: null,
      costs: [],
      arrangementDateCreated: null,
      arrangementDatePublished: null,
      arrangementDateNewsletter: null,
      datePdfSentForApproval: null,
      datePdfApprovalChairman: null,
      datePdfApprovalArrangementResponsible: null,
      pdfLink: "",
      datePdfLinkCreated: null,
      datePdfLinkSent: null,
      dateBankTransfer: null,
      printCheckList: false,
      firstStepScore: 0,
      secondStepScore: 0,
      thirdStepScore: 0,
      fourthStepScore: 0,
      fifthStepScore: 0,
      sixthStepScore: 0,
      seventhStepScore: 0,
      eigthStepScore: 0
    };
  },
  components: {
    FirstStep,
    SecondStep,
    ThirdStep,
    FourthStep,
    FifthStep,
    SixthStep,
    SeventhStep,
    EigthStep
  },
  created: function() {
    if (this.$route.params.id) {
      this.axios
        .get(
          "https://dashboard.svbefs.se/services/planner/api/planner/arrangement/" +
            this.$route.params.id
        )
        .then(response => {
          this.updateData(response);
          this.loading = false;
        })
        .catch(() => {
          this.setError("Något gick fel");
          this.loading = false;
        });
    } else {
      this.loading = false;
    }
  },
  computed: {
    sumOrganizers: function() {
      let sum = this.organizers.length;
      if (this.headOrganizer.length > 0) {
        sum += 1;
      }
      return sum;
    },
    disableStepFour: function() {
      return (
        this.firstStepScore < 100 ||
        this.secondStepScore < 100 ||
        this.thirdStepScore < 100
      );
    },
    disableStepSeven: function() {
      return (
        this.firstStepScore < 100 ||
        this.secondStepScore < 100 ||
        this.thirdStepScore < 100 ||
        this.fourthStepScore < 100 ||
        this.fifthStepScore < 100 ||
        this.sixthStepScore < 100
      );
    },
    disableStepEigth: function() {
      return (
        this.firstStepScore < 100 ||
        this.secondStepScore < 100 ||
        this.thirdStepScore < 100 ||
        this.fourthStepScore < 100 ||
        this.fifthStepScore < 100 ||
        this.sixthStepScore < 100 ||
        this.seventhStepScore < 100
      );
    }
  },
  methods: {
    mapCostTypeToRequest: function(cost) {
      switch (cost) {
        case "INDIVIDUAL":
          return "INDIVIDUAL";
        case "SUBSCRIBER":
          return "SUBSCRIBER";
        case "ALL":
          return "SHARED";
        default:
          return "SHARED";
      }
    },
    mapCostTypeFromResponse: function(cost) {
      switch (cost) {
        case "INDIVIDUAL":
          return "INDIVIDUAL";
        case "SUBSCRIBER":
          return "SUBSCRIBER";
        case "SHARED":
          return "ALL";
        default:
          return "ALL";
      }
    },
    updateData: function(response) {
      this.id = response.data.id;
      this.title = response.data.title ? response.data.title : "";
      this.date = response.data.date ? response.data.date : null;
      this.sumPeople = response.data.slots ? String(response.data.slots) : "0";
      this.ageRestrictionsChecked = response.data.tourAgeRestrictionsConfirmed
        ? response.data.tourAgeRestrictionsConfirmed
        : false;
      this.tours = [];
      if (response.data.tours) {
        response.data.tours.forEach(tour => {
          this.tours.push({
            title: tour.title,
            comment: tour.comment,
            done: tour.done
          });
        });
      }
      this.headOrganizer = response.data.headOrganizer
        ? response.data.headOrganizer
        : "";
      this.organizers = response.data.organizers
        ? response.data.organizers
        : [];
      this.dateOrganizersSet = response.data.dateOrganizersSet
        ? response.data.dateOrganizersSet
        : null;
      this.accompanyingDate = response.data.dateAccompanyingSet
        ? response.data.dateAccompanyingSet
        : null;
      this.accompanyingSumPeople = !isNaN(response.data.accompanying)
        ? String(response.data.accompanying)
        : "";
      this.costMargin = response.data.costMargin
        ? String(response.data.costMargin)
        : "60";
      this.fixedCost = response.data.fixedCost
        ? String(response.data.fixedCost)
        : "";
      this.dateCostsSet = response.data.dateCostOutSet
        ? response.data.dateCostOutSet
        : null;
      this.costs = [];
      if (response.data.costs) {
        response.data.costs.forEach(cost => {
          this.costs.push({
            title: cost.title,
            comment: cost.comment,
            cost: String(cost.sum),
            type: this.mapCostTypeFromResponse(cost.type)
          });
        });
      }
      this.arrangementDateCreated = response.data.dateArrangementCreated
        ? response.data.dateArrangementCreated
        : null;
      this.arrangementDatePublished = response.data.dateArrangementPublished
        ? response.data.dateArrangementPublished
        : null;
      this.arrangementDateNewsletter = response.data
        .dateArrangementPublishMailSent
        ? response.data.dateArrangementPublishMailSent
        : null;
      this.datePdfSentForApproval = response.data.datePdfSentForApproval
        ? response.data.datePdfSentForApproval
        : null;
      this.datePdfApprovalChairman = response.data.datePdfApprovedByChairman
        ? response.data.datePdfApprovedByChairman
        : null;
      this.datePdfApprovalArrangementResponsible = response.data
        .datePdfApprovedByArrangementResponsible
        ? response.data.datePdfApprovedByArrangementResponsible
        : null;
      this.pdfLink = response.data.pdfLink ? response.data.pdfLink : "";
      this.datePdfLinkCreated = response.data.datePdfLinkCreated
        ? response.data.datePdfLinkCreated
        : null;
      this.datePdfLinkSent = response.data.datePdfMailSent
        ? response.data.datePdfMailSent
        : null;
      this.dateBankTransfer = response.data.dateIncomeTransferred
        ? response.data.dateIncomeTransferred
        : null;
      this.printCheckList = response.data.checklistPrinted
        ? response.data.checklistPrinted
        : "";
    },
    savePlan: function() {
      this.loading = true;

      let url =
        "https://dashboard.svbefs.se/services/planner/api/planner/arrangement";
      if (this.id) {
        url += "/" + this.id;
      }

      let toursToSave = [];
      this.tours.forEach(tour => {
        toursToSave.push({
          title: tour.title,
          comment: tour.comment,
          done: tour.done
        });
      });

      let costsToSave = [];
      this.costs.forEach(cost => {
        costsToSave.push({
          title: cost.title,
          comment: cost.comment,
          sum: Number(cost.cost),
          type: this.mapCostTypeToRequest(cost.type)
        });
      });

      this.axios
        .post(url, {
          title: this.title,
          date: this.date,
          slots: Number(this.sumPeople),
          tourAgeRestrictionsConfirmed: this.ageRestrictionsChecked,
          tours: toursToSave,
          headOrganizer: this.headOrganizer,
          organizers: this.organizers,
          dateOrganizersSet: this.dateOrganizersSet,
          dateAccompanyingSet: this.accompanyingDate,
          accompanying: Number(this.accompanyingSumPeople),
          costMargin: Number(this.costMargin),
          fixedCost: Number(this.fixedCost),
          dateCostOutSet: this.dateCostsSet,
          costs: costsToSave,
          dateArrangementCreated: this.arrangementDateCreated,
          dateArrangementPublished: this.arrangementDatePublished,
          dateArrangementPublishMailSent: this.arrangementDateNewsletter,
          datePdfSentForApproval: this.datePdfSentForApproval,
          datePdfApprovedByChairman: this.datePdfApprovalChairman,
          datePdfApprovedByArrangementResponsible: this
            .datePdfApprovalArrangementResponsible,
          pdfLink: this.pdfLink,
          datePdfLinkCreated: this.datePdfLinkCreated,
          datePdfMailSent: this.datePdfLinkSent,
          dateIncomeTransferred: this.dateBankTransfer,
          checklistPrinted: this.printCheckList
        })
        .then(response => {
          this.updateData(response);
          if (!this.id) {
            this.$router.push({ path: "/tour/plan/" + this.id });
          }
          this.setSuccess("Planeringen har sparats!");
          this.loading = false;
        })
        .catch(() => {
          this.setError("Något gick fel");
          this.loading = false;
        });
    },
    firstStepScoreUpdate: function(val) {
      this.firstStepScore = val;
    },
    secondStepScoreUpdate: function(val) {
      this.secondStepScore = val;
    },
    thirdStepScoreUpdate: function(val) {
      this.thirdStepScore = val;
    },
    fourthStepScoreUpdate: function(val) {
      this.fourthStepScore = val;
    },
    fifthStepScoreUpdate: function(val) {
      this.fifthStepScore = val;
    },
    sixthStepScoreUpdate: function(val) {
      this.sixthStepScore = val;
    },
    seventhStepScoreUpdate: function(val) {
      this.seventhStepScore = val;
    },
    eigthStepScoreUpdate: function(val) {
      this.eigthStepScore = val;
    },
    setError: function(text) {
      this.$notify({
        message: text,
        icon: "error",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "danger",
        timeout: 5000
      });
    },
    setSuccess: function(text) {
      this.$notify({
        message: text,
        icon: "check_circle",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "success",
        timeout: 5000
      });
    }
  }
};
</script>
<style scoped>
button.md-button {
  float: right;
}
</style>
<style lang="scss">
.plan-tour .md-progress-bar {
  margin-top: 20px;
  margin-bottom: 0;
}
</style>
