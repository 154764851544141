<template>
  <div>
    <div class="md-layout">
      <div class="md-layout-item md-small-size-100">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>event</md-icon>
            </div>
            <h4 class="title">Arrangemang</h4>
          </md-card-header>
          <md-card-content :hidden="!loading">
            <div class="md-layout">
              <div class="md-layout-item text-center">
                <md-progress-spinner
                  md-mode="indeterminate"
                  :md-diameter="spinnerDiameter"
                  :md-stroke="spinnerStroke"
                ></md-progress-spinner>
              </div>
            </div>
          </md-card-content>
          <md-card-content :hidden="loading">
            <tour-application-arrangement-info
              v-if="!loading"
              :title="arrangement.title"
              :slots="arrangement.slots"
              :fee="arrangement.fee"
            />
          </md-card-content>
        </md-card>
      </div>
      <div class="md-layout-item md-small-size-100">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>table_chart</md-icon>
            </div>
            <h4 class="title">Ansökningar</h4>
          </md-card-header>
          <md-card-content :hidden="!loading">
            <div class="md-layout">
              <div class="md-layout-item text-center">
                <md-progress-spinner
                  md-mode="indeterminate"
                  :md-diameter="spinnerDiameter"
                  :md-stroke="spinnerStroke"
                ></md-progress-spinner>
              </div>
            </div>
          </md-card-content>
          <md-card-content :hidden="loading">
            <tour-application-info
              v-if="!loading"
              :requestedSlots="requestedSlots"
              :reservedSlots="reservedSlots"
              :paidSlots="paidSlots"
            />
          </md-card-content>
        </md-card>
      </div>
      <div class="md-layout-item md-medium-size-100">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>view_list</md-icon>
            </div>
            <h4 class="title">Checklista</h4>
          </md-card-header>
          <md-card-content>
            <tour-application-checklist />
          </md-card-content>
        </md-card>
      </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item md-size-100">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>table_chart</md-icon>
            </div>
          </md-card-header>
          <md-card-content :hidden="!loading">
            <div class="md-layout">
              <div class="md-layout-item text-center">
                <md-progress-spinner
                  md-mode="indeterminate"
                  :md-diameter="spinnerDiameter"
                  :md-stroke="spinnerStroke"
                ></md-progress-spinner>
              </div>
            </div>
          </md-card-content>
          <md-card-content :hidden="loading">
            <tour-application-list
              v-if="!loading"
              :data="data"
              :fee="arrangement.fee"
              @editApplication="editApplication"
            />
          </md-card-content>
        </md-card>
      </div>
    </div>
    <application-modal
      v-if="applicationModal"
      :application="selectedApplication"
      @hide="hideApplicationModal"
      @error="setError"
      @success="setSuccess"
    />
  </div>
</template>
<script>
import ApplicationModal from "./TourApplications/ApplicationModal";
import TourApplicationArrangementInfo from "./TourApplications/TourApplicationArrangementInfo";
import TourApplicationInfo from "./TourApplications/TourApplicationInfo";
import TourApplicationChecklist from "./TourApplications/TourApplicationChecklist";
import TourApplicationList from "./TourApplications/TourApplicationList";
export default {
  components: {
    ApplicationModal,
    TourApplicationArrangementInfo,
    TourApplicationInfo,
    TourApplicationChecklist,
    TourApplicationList
  },
  data() {
    return {
      applicationModal: false,
      selectedApplication: {},
      data: [],
      spinnerDiameter: 60,
      spinnerStroke: 6,
      loading: true,
      arrangement: {}
    };
  },
  mounted: function() {
    this.loadData();
  },
  computed: {
    isAuthorizedToRead: function() {
      return (
        this.$store.getters.roles.includes("arrangement_read") &&
        (this.$store.getters.roles.includes("applications_read") ||
          this.arrangement.organizers
            .map(o => o.organizer)
            .includes(this.$store.getters.userName))
      );
    },
    requestedSlots: function() {
      let slots = 0;
      this.data.forEach(item => {
        slots += item.notMembers;
      });
      return slots + this.data.length;
    },
    reservedSlots: function() {
      let slots = 0;
      this.data.forEach(item => {
        slots += item.reservedSlots;
      });
      return slots;
    },
    paidSlots: function() {
      let slots = 0;
      this.data.forEach(item => {
        slots += item.total / this.arrangement.fee;
      });
      return slots;
    }
  },
  methods: {
    loadData: function() {
      this.loading = true;

      let accessPromise = new Promise((resolve, reject) => {
        if (!this.$store.getters.roles.includes("arrangement_read")) {
          reject();
        } else {
          this.axios
            .get("internal/arrangement/" + this.$route.params.id)
            .then(response => {
              this.arrangement = response.data;
              if (this.isAuthorizedToRead) {
                resolve();
              } else {
                reject();
              }
            })
            .catch(() => {
              reject();
            });
        }
      });

      accessPromise
        .then(() => {
          this.axios
            .get("internal/arrangement/" + this.$route.params.id + "/application")
            .then(response => {
              let parsedData = [];
              response.data.forEach(function(item) {
                parsedData.push({
                  id: item.id,
                  group: item.groupId,
                  groupOwner: item.owner,
                  subscriberId: item.subscriber.id,
                  firstname: item.subscriber.firstName,
                  lastname: item.subscriber.lastName,
                  notMembers: item.nonSubscribers,
                  confirmed: item.confirmed,
                  reservedSlots:
                    item.reserved + item.reservedNonSubscriberSlots,
                  total: item.total,
                  created: item.created
                });
              });
              this.data = parsedData;
              this.loading = false;
            })
            .catch(() => {
              this.setErrorMsg("Något gick fel");
            });
        })
        .catch(() => {
          this.setErrorMsg("Något gick fel");
        });
    },
    editApplication: function(application) {
      this.selectedApplication = application;
      this.applicationModal = true;
    },
    hideApplicationModal: function() {
      this.applicationModal = false;
    },
    setError: function(type) {
      if ("RESERVE" === type) {
        this.setErrorMsg(
          "Kunde inte reservera ansökan för " +
            this.selectedApplication.firstname +
            " " +
            this.selectedApplication.lastname +
            "!"
        );
      } else if ("PAY" === type) {
        this.setErrorMsg(
          "Kunde inte betala ansökan för " +
            this.selectedApplication.firstname +
            " " +
            this.selectedApplication.lastname +
            "!"
        );
      } else if ("DELETE" === type) {
        this.setErrorMsg(
          "Kunde inte ta bort ansökan för " +
            this.selectedApplication.firstname +
            " " +
            this.selectedApplication.lastname +
            "!"
        );
      }
      this.hideApplicationModal();
    },
    setSuccess: function(type) {
      if ("RESERVE" === type) {
        this.setSuccessMsg(
          "Ansökan för " +
            this.selectedApplication.firstname +
            " " +
            this.selectedApplication.lastname +
            " reserverad, inklusive eventuella utomstående!"
        );
        this.selectedApplication.reservedSlots =
          1 + this.selectedApplication.notMembers;
      } else if ("PAY" === type) {
        this.setSuccessMsg(
          "Ansökan för " +
            this.selectedApplication.firstname +
            " " +
            this.selectedApplication.lastname +
            " betald, inklusive eventuella utomstående!"
        );
        this.selectedApplication.total =
          this.arrangement.fee * (1 + this.selectedApplication.notMembers);
      } else if ("DELETE" === type) {
        if (
          this.selectedApplication.groupOwner == this.selectedApplication.id
        ) {
          this.setSuccessMsg(
            "Ansökan för " +
              this.selectedApplication.firstname +
              " " +
              this.selectedApplication.lastname +
              " borttagen, inklusive eventuella medlemmar!"
          );
          this.data = this.data.filter(
            row => row.group != this.selectedApplication.group
          );
        } else {
          this.setSuccessMsg(
            "Ansökan för " +
              this.selectedApplication.firstname +
              " " +
              this.selectedApplication.lastname +
              " borttagen!"
          );
          this.data = this.data.filter(
            row => row.id != this.selectedApplication.id
          );
        }
      }
      this.hideApplicationModal();
    },
    setErrorMsg: function(text) {
      this.$notify({
        message: text,
        icon: "error",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "danger",
        timeout: 5000
      });
    },
    setSuccessMsg: function(text) {
      this.$notify({
        message: text,
        icon: "check_circle",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "success",
        timeout: 5000
      });
    }
  }
};
</script>
