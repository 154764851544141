<template>
  <div>
    <div class="md-layout">
      <div
        class="md-layout-item md-size-60 md-small-size-100"
        v-blur="!isToursAdded"
      >
        <md-checkbox v-model="ageRestrictionsChecked" :disabled="!isToursAdded">
          Åldersrestriktioner är kontrollerade för samtliga visningar
        </md-checkbox>
      </div>
      <div class="md-layout-item md-size-100">
        <md-table id="tours" v-show="tours.length > 0">
          <md-table-row>
            <md-table-head>Titel</md-table-head>
            <md-table-head>Kommentar</md-table-head>
            <md-table-head>Klar</md-table-head>
            <md-table-head></md-table-head>
          </md-table-row>
          <md-table-row
            v-for="(item, index) in tours"
            v-bind:item="item"
            v-bind:index="index"
            v-bind:key="index"
          >
            <md-table-cell>
              <text-field
                :initialTextval.sync="item.title"
                label="Titel på visning"
                icon="title"
                :validationConditions="titleValidation"
              />
            </md-table-cell>
            <md-table-cell>
              <text-field
                :initialTextval.sync="item.comment"
                label="Kommentar"
                icon="comment"
                :validationConditions="commentValidation"
              />
            </md-table-cell>
            <md-table-cell>
              <md-checkbox v-model="item.done"></md-checkbox>
            </md-table-cell>
            <md-table-cell>
              <md-button
                class="md-just-icon md-danger"
                @click="deleteTour(index)"
              >
                <md-icon>delete</md-icon>
              </md-button>
            </md-table-cell>
          </md-table-row>
        </md-table>
        <md-button class="md-success" @click.native="addTour()">
          Lägg till visning
        </md-button>
        <div class="md-layout-item md-size-100">
          <md-progress-bar
            :class="completionClass"
            :md-value="completionScore"
          ></md-progress-bar>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TextField from "@/pages/Dashboard/Components/TextField.vue";
export default {
  components: {
    TextField
  },
  props: {
    initialTours: {
      type: Array,
      required: true
    },
    initialAgeRestrictionsChecked: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      tours: this.initialTours,
      ageRestrictionsChecked: this.initialAgeRestrictionsChecked,
      titleValidation: {
        required: true,
        min: 4
      },
      commentValidation: {
        required: false,
        min: 4
      }
    };
  },
  methods: {
    addTour() {
      this.tours.push({
        title: "Titel på visning",
        done: false,
        comment: ""
      });
    },
    deleteTour(selectedIndex) {
      this.tours = this.tours.filter((item, index) => index != selectedIndex);
    },
    updateAgeRestrictionsChecked: function() {
      this.$emit(
        "update:initialAgeRestrictionsChecked",
        this.ageRestrictionsChecked
      );
    },
    updateTours: function() {
      this.$emit("update:initialTours", this.tours);
    }
  },
  watch: {
    tours() {
      this.updateTours();
    },
    ageRestrictionsChecked() {
      this.updateAgeRestrictionsChecked();
    }
  },
  computed: {
    completionScore: function() {
      let score = 0;
      let parts = 1 + this.tours.length;
      if (parts < 2) {
        parts = 2;
      }
      if (this.tours.length > 0) {
        score += (100 / parts) * this.tours.filter(tour => tour.done).length;
      }
      if (this.ageRestrictionsChecked) {
        score += 100 / parts;
      }
      this.$emit("score", score);
      return score;
    },
    completionClass: function() {
      if (this.completionScore < 50) {
        return "md-danger";
      }
      if (this.completionScore < 100) {
        return "md-warning";
      }
      return "md-success";
    },
    isToursAdded: function() {
      return this.tours.length > 0;
    }
  }
};
</script>
<style>
#tours .md-field:not(.md-disabled)::after {
  background-color: transparent !important;
}
</style>
