<template>
  <form class="form-horizontal" action="" method="post">
    <div class="md-layout">
      <div class="md-layout-item">
        <md-field
          :class="[
            { 'md-error': errors.has('pages') },
            { 'md-valid': !errors.has('pages') && touched.pages }
          ]"
        >
          <label>Delmoment</label>
          <md-input
            v-model="pages"
            data-vv-name="pages"
            type="number"
            v-validate="modelValidations.pages"
            required
          >
          </md-input>
          <slide-y-down-transition>
            <md-icon class="error" v-show="errors.has('pages')">close</md-icon>
          </slide-y-down-transition>
          <slide-y-down-transition>
            <md-icon
              class="success"
              v-show="!errors.has('pages') && touched.pages"
              >done</md-icon
            >
          </slide-y-down-transition>
        </md-field>
      </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item">
        <a target="_blank" :href="checklistUrl">
          <md-button class="md-success">
            Skapa Checklista
          </md-button>
        </a>
      </div>
    </div>
  </form>
</template>
<script>
import { SlideYDownTransition } from "vue2-transitions";
export default {
  components: {
    SlideYDownTransition
  },
  data() {
    return {
      pages: 5,
      touched: {
        pages: false
      },
      modelValidations: {
        pages: {
          required: true,
          numeric: true,
          min_value: 1,
          max_value: 100
        }
      }
    };
  },
  computed: {
    checklistUrl: function() {
      return (
        "https://checklist.svbefs.se/?arrangement=" +
        this.$route.params.id +
        "&" +
        "pages=" +
        this.pages
      );
    }
  }
};
</script>
