<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <global-sales-card header-color="green">
        <template slot="header">
          <div class="card-icon">
            <md-icon>equalizer</md-icon>
          </div>
          <h4 class="title">Medlemsstatistik</h4>
        </template>

        <template slot="content">
          <div class="md-layout" v-if="mapLoading">
            <div class="md-layout-item text-center">
              <md-progress-spinner md-mode="indeterminate">
              </md-progress-spinner>
            </div>
          </div>
          <div class="md-layout" v-if="!mapLoading">
            <div class="md-layout-item md-size-50 md-small-size-100">
              <user-statistics-table :tableData="tableData"></user-statistics-table>
            </div>
            <div class="md-layout-item md-size-50 md-small-hide">
              <async-world-map class="map" :data="mapData"></async-world-map>
            </div>
          </div>
        </template>
      </global-sales-card>
    </div>
  </div>
</template>

<script>
import AsyncWorldMap from "@/components/WorldMap/AsyncWorldMap.vue";
import { GlobalSalesCard, UserStatisticsTable } from "@/components";

export default {
  components: {
    GlobalSalesCard,
    UserStatisticsTable,
    AsyncWorldMap
  },
  mounted: function() {
    this.loadMapData();
  },
  methods: {
    loadMapData: function() {
      this.axios.get("internal/subscribers/statistics/county").then(countyresponse => {
        let stateCodes = {};
        let stateNames = {};
        const counties = {
          K: "Blekinge län",
          W: "Dalarnas län",
          I: "Gotlands län",
          X: "Gävleborgs län",
          N: "Hallands län",
          Z: "Jämtlands län",
          F: "Jönköpings län",
          H: "Kalmar län",
          G: "Kronobergs län",
          BD: "Norrbottens län",
          M: "Skåne län",
          AB: "Stockholms län",
          D: "Södermanlands län",
          C: "Uppsala län",
          S: "Värmlands län",
          AC: "Västerbottens län",
          Y: "Västernorrlands län",
          U: "Västmanlands län",
          O: "Västra Götalands län",
          T: "Örebro län",
          E: "Östergötlands län"
        };

        countyresponse.data.forEach(function(county) {
          stateCodes["SE-" + county.county] = stateCodes["SE-" + county.county]
            ? stateCodes["SE-" + county.county] + county.count
            : county.count;
          stateNames["SE-" + county.county] = counties[county.county];
        });

        this.mapData = stateCodes;
        let total = 0;
        Object.values(stateCodes).forEach(val => (total += val));
        let statisticsTable = [];
        for (const [key, value] of Object.entries(stateCodes)) {
          statisticsTable.push({
            flag: "./img/flags/" + key + ".png",
            county: stateNames[key],
            amount: value,
            percent: Math.round((value / total) * 100)
          });
        }
        this.tableData = statisticsTable
          .sort((a, b) => b.amount - a.amount)
          .slice(0, Math.min(statisticsTable.length, 6));
        this.mapLoading = false;
      });
    }
  },
  data() {
    return {
      mapLoading: true,
      tableData: [],
      mapData: {
        "SE-AB": 0,
        "SE-AC": 0,
        "SE-BD": 0,
        "SE-C": 0,
        "SE-D": 0,
        "SE-E": 0,
        "SE-F": 0,
        "SE-G": 0,
        "SE-H": 0,
        "SE-I": 0,
        "SE-K": 0,
        "SE-M": 0,
        "SE-N": 0,
        "SE-O": 0,
        "SE-S": 0,
        "SE-T": 0,
        "SE-U": 0,
        "SE-W": 0,
        "SE-X": 0,
        "SE-Y": 0,
        "SE-Z": 0
      }
    };
  }
};
</script>
