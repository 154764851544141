<template>
  <div>
    <div class="md-layout">
      <div class="md-layout-item">
        <md-button
          class="md-success"
          @click.native="createTour"
          :disabled="loading || errors.any()"
        >
          Skapa
        </md-button>
      </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>event</md-icon>
            </div>
            <h4 class="title">Allmänt</h4>
          </md-card-header>

          <md-card-content :hidden="!loading">
            <div class="md-layout">
              <div class="md-layout-item text-center">
                <md-progress-spinner
                  md-mode="indeterminate"
                  :md-diameter="spinnerDiameter"
                  :md-stroke="spinnerStroke"
                ></md-progress-spinner>
              </div>
            </div>
          </md-card-content>
          <md-card-content :hidden="loading">
            <create-tour-general
              :initialTitle.sync="title"
              :initialDate.sync="date"
              :initialTime.sync="time"
              :initialLocation.sync="location"
              :initialStatus.sync="status"
              :initialSlots.sync="slots"
              :initialFee.sync="fee"
            />
          </md-card-content>
        </md-card>
      </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>emoji_people</md-icon>
            </div>
            <h4 class="title">Arrangör</h4>
          </md-card-header>
          <md-card-content :hidden="!loading">
            <div class="md-layout">
              <div class="md-layout-item text-center">
                <md-progress-spinner
                  md-mode="indeterminate"
                  :md-diameter="spinnerDiameter"
                  :md-stroke="spinnerStroke"
                ></md-progress-spinner>
              </div>
            </div>
          </md-card-content>
          <md-card-content :hidden="loading">
            <create-tour-organizer
              :headOrganizer.sync="headOrganizer"
              :organizers.sync="organizers"
            />
          </md-card-content>
        </md-card>
      </div>
      <div class="md-layout-item">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>table_chart</md-icon>
            </div>
            <h4 class="title">Utlottning</h4>
          </md-card-header>

          <md-card-content :hidden="!loading">
            <div class="md-layout">
              <div class="md-layout-item text-center">
                <md-progress-spinner
                  md-mode="indeterminate"
                  :md-diameter="spinnerDiameter"
                  :md-stroke="spinnerStroke"
                ></md-progress-spinner>
              </div>
            </div>
          </md-card-content>
          <md-card-content :hidden="loading">
            <create-tour-raffling
              :arrangementDate="date"
              :intitialRafflingDateOpen.sync="rafflingDateOpen"
              :intitialRafflingDateClose.sync="rafflingDateClose"
            />
          </md-card-content>
        </md-card>
      </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>business_center</md-icon>
            </div>
            <h4 class="title">Klädsel</h4>
          </md-card-header>
          <md-card-content :hidden="!loading">
            <div class="md-layout">
              <div class="md-layout-item text-center">
                <md-progress-spinner
                  md-mode="indeterminate"
                  :md-diameter="spinnerDiameter"
                  :md-stroke="spinnerStroke"
                ></md-progress-spinner>
              </div>
            </div>
          </md-card-content>
          <md-card-content :hidden="loading">
            <create-tour-recommendation-clothing
              :recommendation.sync="clothing"
            />
          </md-card-content>
        </md-card>
      </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>build</md-icon>
            </div>
            <h4 class="title">Utrustning</h4>
          </md-card-header>
          <md-card-content :hidden="!loading">
            <div class="md-layout">
              <div class="md-layout-item text-center">
                <md-progress-spinner
                  md-mode="indeterminate"
                  :md-diameter="spinnerDiameter"
                  :md-stroke="spinnerStroke"
                ></md-progress-spinner>
              </div>
            </div>
          </md-card-content>
          <md-card-content :hidden="loading">
            <create-tour-recommendation-gear :recommendation.sync="gear" />
          </md-card-content>
        </md-card>
      </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>description</md-icon>
            </div>
            <h4 class="title">Beskrivning</h4>
          </md-card-header>
          <md-card-content :hidden="!loading">
            <div class="md-layout">
              <div class="md-layout-item text-center">
                <md-progress-spinner
                  md-mode="indeterminate"
                  :md-diameter="spinnerDiameter"
                  :md-stroke="spinnerStroke"
                ></md-progress-spinner>
              </div>
            </div>
          </md-card-content>
          <md-card-content :hidden="loading">
            <create-tour-description :description.sync="description" />
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>
<script>
import CreateTourGeneral from "./CreateTour/CreateTourGeneral";
import CreateTourOrganizer from "./CreateTour/CreateTourOrganizer";
import CreateTourRaffling from "./CreateTour/CreateTourRaffling";
import CreateTourRecommendationClothing from "./CreateTour/CreateTourRecommendationClothing";
import CreateTourRecommendationGear from "./CreateTour/CreateTourRecommendationGear";
import CreateTourDescription from "./CreateTour/CreateTourDescription";
import moment from "moment";
export default {
  components: {
    CreateTourGeneral,
    CreateTourOrganizer,
    CreateTourRaffling,
    CreateTourRecommendationClothing,
    CreateTourRecommendationGear,
    CreateTourDescription
  },
  mounted: function() {
    if (!this.isAuthorizedToCreate) {
      this.$router.push({ path: "/" });
    }
  },
  data() {
    return {
      loading: false,
      spinnerDiameter: 60,
      spinnerStroke: 6,
      id: -1,
      title: "",
      location: "",
      status: "utkast",
      date: moment
        .utc()
        .add(20, "days")
        .format("YYYY-MM-DD"),
      time:
        moment.utc().format("HH") +
        ":00 - " +
        moment
          .utc()
          .add(1, "hours")
          .format("HH") +
        ":00",
      fee: 100,
      clothing:
        "<p>Inget utöver det vanliga (se <a href='https://fortifikationvast.se/faq/' rel='noopener noreferrer' target='_blank'>https://fortifikationvast.se/faq/</a>)</p>",
      gear:
        "<p>Inget utöver det vanliga (se <a href='https://fortifikationvast.se/faq/' rel='noopener noreferrer' target='_blank'>https://fortifikationvast.se/faq/</a>)</p>",
      description: "Vi kommer att inleda dagen med  ...",
      slots: 20,
      registrationOpen: true,
      groupRegistrationOpen: true,
      nonMemberRegistrationOpen: true,
      rafflingDateOpen: moment.utc().format("YYYY-MM-DD"),
      rafflingDateClose: moment
        .utc()
        .add(18, "days")
        .format("YYYY-MM-DD"),
      headOrganizer: this.$store.getters.userName,
      organizers: []
    };
  },
  computed: {
    isAuthorizedToCreate: function() {
      return this.$store.getters.roles.includes("arrangement_create");
    }
  },
  methods: {
    createTour: function() {
      this.loading = true;

      let convertedOrganizers = [
        {
          organizer: this.headOrganizer,
          headOrganizer: true
        }
      ];
      this.organizers.forEach(function(item) {
        convertedOrganizers.push({
          organizer: item,
          headOrganizer: false
        });
      });

      this.axios
        .post("internal/arrangement", {
          title: this.title,
          location: this.location,
          status: this.status,
          date: this.date,
          time: this.time,
          fee: this.fee,
          feeDescription: "för hela arrangemanget",
          clothing: this.clothing,
          gear: this.gear,
          description: this.description,
          slots: this.slots,
          registrationOpen: this.registrationOpen,
          groupRegistrationOpen: this.groupRegistrationOpen,
          nonSubscriberRegistrationOpen: this.nonMemberRegistrationOpen,
          rafflingDate: this.rafflingDateOpen,
          lastRafflingDate: this.rafflingDateClose,
          organizers: convertedOrganizers
        })
        .then(() => {
          this.$router.push({ path: "/tour/list" });
        })
        .catch(error => {
          if (error.response.data.title) {
            this.setError("Felaktig titel");
          } else if (error.response.data.location) {
            this.setError("Felaktig plats");
          } else if (error.response.data.status) {
            this.setError("Felaktig status");
          } else if (error.response.data.date) {
            this.setError("Felaktigt datum");
          } else if (error.response.data.time) {
            this.setError("Felaktig tid");
          } else if (error.response.data.fee) {
            this.setError("Felaktig avgift");
          } else if (error.response.data.clothing) {
            this.setError("Felaktig klädsel");
          } else if (error.response.data.gear) {
            this.setError("Felaktig utrustning");
          } else if (error.response.data.description) {
            this.setError("Felaktig beskrivning");
          } else if (error.response.data.slots) {
            this.setError("Felaktigt antal platser");
          } else if (error.response.data.registrationOpen) {
            this.setError("Felaktig status för registrering");
          } else if (error.response.data.groupRegistrationOpen) {
            this.setError("Felaktig status för gruppregistrering");
          } else if (error.response.data.nonMemberRegistrationOpen) {
            this.setError("Felaktig status för registrering av utomstående");
          } else if (error.response.data.rafflingDateOpen) {
            this.setError("Felaktigt datum för start av utlottning");
          } else if (error.response.data.rafflingDateClose) {
            this.setError("Felaktigt datum för slut av utlottning");
          } else if (error.response.data.organizer) {
            this.setError("Felaktig arrangör");
          } else if (error.response.data.organizers) {
            this.setError("Felaktig arrangör");
          } else {
            this.setError("Något gick fel");
          }
          this.loading = false;
        });
    },
    setError: function(text) {
      this.$notify({
        message: text,
        icon: "error",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "danger",
        timeout: 5000
      });
    }
  }
};
</script>
<style scoped>
button.md-button {
  float: right;
}
</style>
