<template>
  <div>
    <div class="md-layout" v-show="loading">
      <div class="md-layout-item text-center">
        <md-progress-spinner
          md-mode="indeterminate"
          :md-diameter="spinnerDiameter"
          :md-stroke="spinnerStroke"
        ></md-progress-spinner>
      </div>
    </div>
    <form class="form-horizontal" action="" method="post" v-show="!loading">
      <div class="md-layout">
        <div class="md-layout-item md-size-60 md-small-size-100">
          <md-field
            :class="[
              { 'md-error': errors.has('headOrganizer') },
              {
                'md-valid':
                  !errors.has('headOrganizer') && touched.headOrganizer
              }
            ]"
          >
            <label for="additionalOrganizers">Huvudarrangör</label>
            <md-select
              :value="headOrganizer"
              name="additionalOrganizers"
              id="additionalOrganizers"
              @md-selected="updatedHeadOrganizer"
              data-vv-name="headOrganizer"
              v-validate="modelValidations.headOrganizer"
              required
            >
              <md-option
                v-for="organizer in allOrganizers"
                v-bind:key="organizer"
                :value="organizer"
                >{{ organizer }}</md-option
              >
            </md-select>
            <slide-y-down-transition>
              <md-icon class="error" v-show="errors.has('headOrganizer')"
                >close</md-icon
              >
            </slide-y-down-transition>
            <slide-y-down-transition>
              <md-icon
                class="success"
                v-show="!errors.has('headOrganizer') && touched.headOrganizer"
                >done</md-icon
              >
            </slide-y-down-transition>
          </md-field>
        </div>
      </div>
      <div class="md-layout">
        <div class="md-layout-item md-size-60 md-small-size-100">
          <md-field>
            <label for="additionalOrganizers">Ytterligare arrangörer</label>
            <md-select
              :value="organizers"
              name="additionalOrganizers"
              id="additionalOrganizers"
              @md-selected="updateAdditionalOrganizer"
              multiple
            >
              <md-option
                v-for="organizer in availableAdditionalOrganizers"
                v-bind:key="organizer"
                :value="organizer"
                >{{ organizer }}</md-option
              >
            </md-select>
          </md-field>
        </div>
        <div
          class="md-layout-item md-size-40 md-small-size-100"
          v-if="isDateSet"
        >
          <p>Arrangörer angavs {{ dateSet }}</p>
        </div>
      </div>
      <div class="md-layout-item md-size-100">
        <md-progress-bar
          :class="completionClass"
          :md-value="completionScore"
        ></md-progress-bar>
      </div>
    </form>
  </div>
</template>
<script>
import { SlideYDownTransition } from "vue2-transitions";
import moment from "moment";
export default {
  components: {
    SlideYDownTransition
  },
  props: {
    headOrganizer: {
      type: String,
      required: true
    },
    organizers: {
      type: Array,
      required: true
    },
    initialDateSet: {
      required: true
    }
  },
  mounted: function() {
    this.loadOrganizers();
  },
  data() {
    return {
      loading: true,
      spinnerDiameter: 60,
      spinnerStroke: 6,
      allOrganizers: [],
      dateSet: this.initialDateSet,
      touched: {
        headOrganizer: false
      },
      modelValidations: {
        headOrganizer: {
          required: true
        }
      }
    };
  },
  computed: {
    isAuthorizedToReadOrganizers: function() {
      return this.$store.getters.roles.includes("arrangement_organizers_read");
    },
    availableAdditionalOrganizers: function() {
      return this.allOrganizers.filter(item => item !== this.headOrganizer);
    },
    completionScore: function() {
      let score = 0;
      if (this.headOrganizer) {
        score += 100 / 2;
      }
      if (this.dateSet) {
        score += 100 / 2;
      }
      this.$emit("score", score);
      return score;
    },
    completionClass: function() {
      if (this.completionScore < 50) {
        return "md-danger";
      }
      if (this.completionScore < 100) {
        return "md-warning";
      }
      return "md-success";
    },
    isDateSet: function() {
      return this.dateSet != null && this.dateSet.length > 0;
    }
  },
  methods: {
    addTour() {
      this.tours.push({
        title: "",
        done: false,
        comment: ""
      });
    },
    updatedHeadOrganizer: function(value) {
      this.dateSet = moment.utc().format("YYYY-MM-DD");
      this.$emit("update:headOrganizer", value);
      this.$emit(
        "update:organizers",
        this.organizers.filter(item => item !== value)
      );
      this.$emit("update:initialDateSet", this.dateSet);
    },
    updateAdditionalOrganizer: function(value) {
      this.dateSet = moment.utc().format("YYYY-MM-DD");
      this.$emit("update:organizers", value);
      this.$emit("update:initialDateSet", this.dateSet);
    },
    loadOrganizers: function() {
      this.loading = true;

      if (this.isAuthorizedToReadOrganizers) {
        this.axios
          .get("internal/arrangement/organizers")
          .then(response => {
            let organizersList = [];
            response.data.forEach(function(item) {
              organizersList.push(item.name);
            });
            this.allOrganizers = organizersList;
            this.loading = false;
          })
          .catch(() => {
            this.setError("Kunde inte hämta data");
            this.loading = false;
          });
      } else {
        this.allOrganizers = [this.organizers];
        this.loading = false;
      }
    },
    setError: function(text) {
      this.$notify({
        message: text,
        icon: "error",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "danger",
        timeout: 5000
      });
    }
  }
};
</script>
<style></style>
