<template>
  <modal @close="modalHide">
    <template slot="header">
      <h4 class="modal-title">
        {{ application.firstname | capitalize }}
        {{ application.lastname | capitalize }} ({{ application.id }})
      </h4>
      <md-button
        class="md-simple md-just-icon md-round modal-default-button"
        @click="modalHide"
      >
        <md-icon>clear</md-icon>
      </md-button>
    </template>

    <template slot="body">
      <div class="md-layout text-center">
        <div class="md-layout-item">
          <md-checkbox v-model="unlocked">Aktivera knappar</md-checkbox>
        </div>
      </div>
      <div class="md-layout text-center">
        <div class="md-layout-item">
          <md-button
            class="md-success"
            :disabled="!unlocked"
            @click="markReserved"
            >Reservera platser</md-button
          >
        </div>
      </div>
      <div class="md-layout text-center">
        <div class="md-layout-item">
          <md-button class="md-success" :disabled="!unlocked" @click="markPaid"
            >Markera betald</md-button
          >
        </div>
      </div>
      <div class="md-layout text-center">
        <div class="md-layout-item">
          <md-button
            class="md-danger"
            :disabled="!unlocked"
            @click="removeApplication"
            >Radera Ansökan</md-button
          >
        </div>
      </div>
    </template>

    <template slot="footer">
      <md-button class="md-danger md-simple" @click="modalHide"
        >Stäng</md-button
      >
    </template>
  </modal>
</template>
<script>
import { Modal } from "@/components";
export default {
  components: {
    Modal
  },
  props: {
    application: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      unlocked: false
    };
  },
  methods: {
    modalHide: function() {
      this.$emit("hide");
    },
    markReserved: function() {
      this.axios
        .post(
          "internal/application/" +
            this.application.id +
            "/reserve"
        )
        .then(() => {
          this.$emit("success", "RESERVE");
        })
        .catch(() => {
          this.$emit("error", "RESERVE");
        });
    },
    markPaid: function() {
      this.axios
        .post(
          "internal/application/" +
            this.application.id +
            "/pay"
        )
        .then(() => {
          this.$emit("success", "PAY");
        })
        .catch(() => {
          this.$emit("error", "PAY");
        });
    },
    removeApplication: function() {
      this.axios
        .delete(
          "internal/application/" + this.application.id
        )
        .then(() => {
          this.$emit("success", "DELETE");
        })
        .catch(() => {
          this.$emit("error", "DELETE");
        });
    }
  },
  filters: {
    capitalize: function(value) {
      if (!value) return "";
      let retVal = "";
      value
        .toString()
        .toLowerCase()
        .split(" ")
        .forEach(v => {
          if (retVal.length > 0) {
            retVal += " ";
          }
          retVal += v.charAt(0).toUpperCase() + v.slice(1);
        });
      return retVal;
    }
  }
};
</script>
