<template>
  <md-dialog
    :md-active="show"
    :md-close-on-esc="true"
    :md-click-outside-to-close="true"
  >
    <md-dialog-title>{{ subject }}</md-dialog-title>
    <md-dialog-content>
      <div v-html="contentHtml"></div>
    </md-dialog-content>
    <md-dialog-actions>
      <md-button class="md-danger md-simple" @click="hideDialog()"
        >Stäng</md-button
      >
    </md-dialog-actions>
  </md-dialog>
</template>
<script>
export default {
  data: function() {
    return {
      show: false,
      subject: "",
      contentHtml: ""
    };
  },
  methods: {
    showDialog: function(subject, contentHtml) {
      this.subject = subject;
      this.contentHtml = contentHtml;
      this.show = true;
    },
    hideDialog: function() {
      this.show = false;
    }
  }
};
</script>
