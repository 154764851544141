<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-header class="md-card-header-text md-card-header-green">
          <div class="card-icon">
            <md-icon>assignment</md-icon>
          </div>
        </md-card-header>

        <md-card-content :hidden="!loading">
          <div class="md-layout">
            <div class="md-layout-item text-center">
              <md-progress-spinner
                md-mode="indeterminate"
                :md-diameter="spinnerDiameter"
                :md-stroke="spinnerStroke"
              ></md-progress-spinner>
            </div>
          </div>
        </md-card-content>
        <md-card-content :hidden="loading">
          <md-table>
            <md-table-row>
              <md-table-head md-numeric class="md-xsmall-hide"
                >Id</md-table-head
              >
              <md-table-head>Förnamn</md-table-head>
              <md-table-head>Efternamn</md-table-head>
              <md-table-head class="md-small-hide">E-post</md-table-head>
              <md-table-head class="md-medium-hide">Skapad</md-table-head>
              <md-table-head class="text-right"></md-table-head>
            </md-table-row>
            <md-table-row v-for="item in listData" v-bind:key="item.id">
              <md-table-cell md-numeric class="md-xsmall-hide">{{
                item.id
              }}</md-table-cell>
              <md-table-cell>{{ item.firstname }}</md-table-cell>
              <md-table-cell>{{ item.lastname }}</md-table-cell>
              <md-table-cell class="md-small-hide">{{
                item.email
              }}</md-table-cell>
              <md-table-cell class="md-medium-hide">{{
                item.created | createdTimestamp
              }}</md-table-cell>
              <md-table-cell class="text-right">
                <md-button
                  class="md-just-icon md-success"
                  @click="showMemberInfo(item)"
                  ><md-icon>search</md-icon></md-button
                >
                <md-button
                  class="md-just-icon md-warning pl-3"
                  @click="showJudgement(item)"
                  ><md-icon>gavel</md-icon></md-button
                >
              </md-table-cell>
            </md-table-row>
          </md-table>
          <member-info-modal
            v-if="memberInfoModal"
            :id="selectedMember.id"
            @hide="hideMemberInfo"
          />
          <judgement-modal
            v-if="judgmentModal"
            :id="selectedMember.id"
            :email="selectedMember.email"
            :firstname="selectedMember.firstname"
            :lastname="selectedMember.lastname"
            @hide="hideJudgement"
            @filterSelected="filterSelected"
          />
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>
<script>
import JudgementModal from "./MemberAwaitingList/JudgementModal";
import MemberInfoModal from "./MemberAwaitingList/MemberInfoModal";
import moment from "moment";
export default {
  components: {
    JudgementModal,
    MemberInfoModal
  },
  mounted: function() {
    if (this.isAuthorizedToRead && this.isAuthorizedToWrite) {
      this.loadData();
    } else {
      this.setError("Du har inte behörighet för denna sida!");
    }
  },
  computed: {
    isAuthorizedToRead: function() {
      return this.$store.getters.roles.includes("user_read");
    },
    isAuthorizedToWrite: function() {
      return this.$store.getters.roles.includes("user_write");
    }
  },
  data() {
    return {
      memberInfoModal: false,
      judgmentModal: false,
      loading: true,
      judgment: false,
      spinnerDiameter: 60,
      spinnerStroke: 6,
      listData: [],
      selectedMember: {}
    };
  },
  methods: {
    moment: function() {
      return moment;
    },
    loadData: function() {
      this.loading = true;
      this.axios
        .post("internal/subscribers",
        {
          accepted: false,
          rejected: false,
          awaiting: true,
          searchString: ""
        })
        .then(response => {
          let parsedData = [];
          response.data.forEach(function(item) {
            parsedData.push({
              id: item.id,
              firstname: item.firstName,
              lastname: item.lastName,
              email: item.email,
              created: item.created
            });
          });
          this.listData = parsedData;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    showMemberInfo: function(member) {
      this.selectedMember = member;
      this.memberInfoModal = true;
    },
    hideMemberInfo: function() {
      this.memberInfoModal = false;
    },
    showJudgement: function(member) {
      this.selectedMember = member;
      this.judgmentModal = true;
    },
    hideJudgement: function() {
      this.judgmentModal = false;
    },
    filterSelected: function() {
      this.listData = this.listData.filter(
        row => row.id != this.selectedMember.id
      );
    },
    setError: function(text) {
      this.$notify({
        message: text,
        icon: "error",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "danger",
        timeout: 5000
      });
    }
  },
  filters: {
    createdTimestamp: function(value) {
      let baseTimestamp = moment.utc("2019-11-13 20:00", "YYYY-MM-DD HH:mm");
      let parsed = moment.utc(value, "YYYY-MM-DD HH:mm");
      if (parsed.isBefore(baseTimestamp)) {
        return "";
      } else {
        return parsed.format("D/M HH:mm");
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.md-just-icon {
  margin-left: 5px;
}
//.text-right /deep/ .md-table-cell-container {
.text-right .md-table-cell-container {
  display: flex;
  justify-content: flex-end;
}
//.md-table /deep/ .md-table-head:last-child {
.md-table .md-table-head:last-child {
  text-align: right;
}
</style>
